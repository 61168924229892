import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';
import { employeeDocuments } from '../models/employeeDocuments';

@Injectable({
  providedIn: 'root'
})
export class Approvalinboxservice {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  // public getTasks(): Observable<any> {
  //   this.url = '/v1/documentsApproval';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   // console.log("Let's see the query parameter :",queryParameters)
  //   return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  // }

  // public getAllDocData(): Observable<any> {
  //   this.url = '/v1/viewAlldocuments';
  //   // this.url = '/v1/documentsApproval';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  // }

  public approveDocument(employeeDocs: any,  approved: any): Observable<any> {
    this.url = '/v1/approveDocument';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('approved', approved);
    return this.commonService.postWithHttpInfo(employeeDocs, queryParameters, path);
  }

  public downloadFile(filePath: string): Observable<any> {
    this.url = '/v1/downloadEmpDoc';
    const path = this.basePath + this.url;
    console.log("File Path ", filePath);
    const param = new HttpParams().set('filePath', filePath);
    const options = {
      params: param
    };
    return this.httpclient.get(path, { ...options, responseType: 'blob' });
  }

  public docFilterList(empid: any): Observable<any> {
    this.url = '/v1/docFilterList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueempid', empid)
    console.log("docFilterList endpoint called , Uniqueempid : ", empid)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public viewFile(filePath: string,imagename:string): Observable<any> {
    this.url = '/v1/viewImage';
    const path = this.basePath + this.url;
    console.log("File Path is xxx", filePath);
    const param = new HttpParams().set('filePath', filePath)
    .set('imageName', imagename);
   
    console.log(imagename)
    const options = {
      params: param
     
    };
    return this.httpclient.get(path, { ...options, responseType: 'blob' });
  }
}