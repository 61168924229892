import { Component, OnInit, ViewChild } from '@angular/core';
import { EmpsalaryslipService } from '../services/empsalaryslip.service';
import { ITSlabDetails, subDetailsList } from '../models/ITSlabDetails';
import { NgForm } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-it-slab-master',
  templateUrl: './it-slab-master.component.html',
  styleUrls: ['./it-slab-master.component.css']
})
export class ITSlabMasterComponent implements OnInit {
gender :any;
employeeRuleDetail: any[] = [];
ITSlabMasterData:ITSlabDetails[];
insertITSlabDetails;
subDetailsInfo;
DeleteITSlabMaster:ITSlabDetails;
add_view_toggle: string = 'viewMaster';
addOrUpdate: boolean = true;


@ViewChild('subDetailsForm') subDetailsForm: NgForm;


  constructor(private empService: EmpsalaryslipService) { }

  ngOnInit(): void {
    this.insertITSlabDetails = new ITSlabDetails();
    this.subDetailsInfo = new subDetailsList();
    this.getITSlabMasterData();
  }

  clear()
  {
    this.insertITSlabDetails.lowerlimit ='';
    this.insertITSlabDetails.upperlimit = '';
    this.insertITSlabDetails.eduCessPercent = '';
    this.insertITSlabDetails.maleApplicable = '';
    this.insertITSlabDetails.femaleApplicable = '';
    this.insertITSlabDetails.selectRegimeId = '';
    this.employeeRuleDetail= [];
    this.clearSubDeatails()
  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
  
  clearSubDeatails() {
    this.subDetailsInfo.sublowerlimit = '';
    this.subDetailsInfo.subupperlimit= '';
    this.subDetailsInfo.subeducesspercent= '';
  }

  getITSlabMasterData() {
    console.log("IT slab master data1:" );
    this.empService.getItSlabMaster().subscribe((data) => {
      console.log(data);
      this.ITSlabMasterData = data.itSlabsMaster;  // edit and delete here 
    
      console.log("IT slab master data2:",  this.ITSlabMasterData);
     
    }, (error) => {
      console.log(error);
    });
  }
  viewMaster() {
    this.getITSlabMasterData();
    console.log("Clicked on view button")
    this.add_view_toggle = 'viewMaster';
    this.addOrUpdate = true;
    this.clear();
  }
  addMaster() {
    console.log("Clicked on add button")
    this.add_view_toggle = 'addMaster';
    this.addOrUpdate = true;
    // this.clear();
  }

  updateMaster(insertITSlabDetails:any){
    console.log("insertITSlabDetails",insertITSlabDetails);
    console.log(" lowerlimit ", this.insertITSlabDetails.lowerlimit);
    console.log(" upperlimit ", this.insertITSlabDetails.upperlimit);
    console.log(" eduCessPercent ", this.insertITSlabDetails.eduCessPercent);
    console.log(" selectRegimeId ", this.insertITSlabDetails.selectRegimeId);
    console.log(" maleApplicable ", this.insertITSlabDetails.maleApplicable);
    console.log(" femaleApplicable ", this.insertITSlabDetails.femaleApplicable);
    if(this.validateLimit()){
      if(this.insertITSlabDetails.lowerlimit === undefined || this.insertITSlabDetails.lowerlimit === ""  || this.insertITSlabDetails.upperlimit  === undefined||this.insertITSlabDetails.upperlimit  === ""  || 
        this.insertITSlabDetails.eduCessPercent === undefined || this.insertITSlabDetails.eduCessPercent === "" ||this.insertITSlabDetails.selectRegimeId === undefined || this.insertITSlabDetails.selectRegimeId ===""
        || ((this.insertITSlabDetails.maleApplicable === undefined && this.insertITSlabDetails.femaleApplicable === undefined)
        || (this.insertITSlabDetails.maleApplicable===false && this.insertITSlabDetails.femaleApplicable===false))
      ){
        this.alertmessages("Please Enter all the required field", "danger");
            return;
      }
    console.log('combined list ', this.employeeRuleDetail);
    const obj1 = {
      sublowerlimit: this.subDetailsInfo.sublowerlimit === undefined ? 0 : this.subDetailsInfo.sublowerlimit,
      subupperlimit: this.subDetailsInfo.subupperlimit === undefined ? 0 : this.subDetailsInfo.subupperlimit,
      subeducesspercent: this.subDetailsInfo.subeducesspercent === undefined ? 0 : this.subDetailsInfo.subeducesspercent,
      userId: 'Add'
    }
    if(obj1.sublowerlimit!=0 && obj1.subupperlimit!=0 && obj1.subeducesspercent!=0 && obj1.sublowerlimit!=null && obj1.subupperlimit!=null && obj1.subeducesspercent!=null){
      this.employeeRuleDetail.push({ ...obj1 });
      this.clearSubDeatails();
      this.subDetailsForm.form.reset();
    }
    if(this.insertITSlabDetails.femaleApplicable){
      this.insertITSlabDetails.femaleApplicable = true;
    }else{
      this.insertITSlabDetails.femaleApplicable = false;
    }if(this.insertITSlabDetails.maleApplicable){
      this.insertITSlabDetails.maleApplicable = true;
    }else{
      this.insertITSlabDetails.maleApplicable = false;
    }
    
    this.insertITSlabDetails.subDetails= this.employeeRuleDetail;
    console.log("Updated insertITSlabDetails ", this.insertITSlabDetails);
    this.empService.updateItSlabMaster(insertITSlabDetails).subscribe(
      (data) => {
        console.log(data);
        
        this.getITSlabMasterData();
        this.clear();
       
        this.addOrUpdate = true;
        this.add_view_toggle = 'viewMaster';
        this.alertmessages("Successfully Updated", "success");
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });

    }
  }

  editmaster(editId: any)
  {
    console.log("edited function ", editId);
    this.clear();
    this.empService.getEditItSlabMaster(editId).subscribe(
      (data) => {
        console.log(data);
        this.insertITSlabDetails = data.itSlabsMaster[0];
        console.log("this.insertITSlabDetails",this.insertITSlabDetails);
        console.log("data.itSlabsMaster[0].subDetails.id",data.itSlabsMaster[0].subDetails[0].id);
        if(data.itSlabsMaster[0].subDetails[0].id!=0){
        this.employeeRuleDetail = data.itSlabsMaster[0].subDetails
        }
        console.log("this.insertITSlabDetails",this.insertITSlabDetails);
        console.log("this.subDetailsInfoList",this.employeeRuleDetail);
        // this.getITSlabMasterData();
       
        this.addOrUpdate = false;  //for button change
        this.add_view_toggle = 'addMaster';
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });

    
    console.log("addOrUpdate ", this.addOrUpdate);
  }

  addSubDetails()
  {
if(this.validateSubLimit()){
    console.log("add employer comp ");
    console.log("this.subDetailsInfo",this.subDetailsInfo);
    const obj1 = {
      sublowerlimit: this.subDetailsInfo.sublowerlimit === undefined||null ? 0 : this.subDetailsInfo.sublowerlimit,
      subupperlimit: this.subDetailsInfo.subupperlimit === undefined||null ? 0 : this.subDetailsInfo.subupperlimit,
      subeducesspercent: this.subDetailsInfo.subeducesspercent === undefined||null ? 0 : this.subDetailsInfo.subeducesspercent,
      userId: 'Add'
    }
    console.log("this.subDetailsInfo",this.subDetailsInfo);

    const valuesToCheck = [
      obj1.sublowerlimit,
      obj1.subupperlimit,
      obj1.subeducesspercent,
    ];

    for (const value of valuesToCheck) {
      if (isNaN(value) || value < 0) {
        this.alertmessages("Please enter a valid number", "danger");
        this.subDetailsForm.form.reset();
        return;
      }
    }

    if (!obj1.sublowerlimit || !obj1.subupperlimit ||  !obj1.subeducesspercent ) {
      this.alertmessages("Please enter value for Sub Details", "danger");
      this.subDetailsForm.form.reset();
      return;
    }

    this.employeeRuleDetail.push({ ...obj1 });
    this.clearSubDeatails();
    console.log("addEmployeeRule Test", this.employeeRuleDetail);
    this.subDetailsForm.form.reset();
  }
}

 
  deleteSubDetails(index) {
    console.log(" slice obj from deleteEmployeeRule ", index);
    if (index !== null && index !== undefined) {

      console.log("this indez ", this.employeeRuleDetail[index]);
      this.employeeRuleDetail[index].userId = 'Del';
      this.employeeRuleDetail.splice(index, 1);
      console.log("List existing ", this.employeeRuleDetail);

    } else {
      console.error("Select Object to Delete!");
    }
  }

  deletemaster()
  {
    
    const deleteId =  this.DeleteITSlabMaster.id;
    console.log("deleteId", deleteId);
    this.empService.deleteItSlabMaster(deleteId).subscribe(
      (data) => {
        this.getITSlabMasterData();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")
       
       
      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Please Try Again", "danger");
        
      }
    );


  }

  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteITSlabMaster=info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');
    
    }
    validateLimit() : boolean{
      if(this.insertITSlabDetails.lowerlimit!=undefined && this.insertITSlabDetails.upperlimit!=undefined){
        let diff = this.insertITSlabDetails.upperlimit - this.insertITSlabDetails.lowerlimit;
        if(diff<0){
          this.alertmessages("Upper Limit cannot be less than Lower Limit", "danger");
          return false
        }
      }
      return true;
    }
    validateSubLimit() : boolean{
      
      if((this.subDetailsInfo.sublowerlimit!=undefined && this.subDetailsInfo.subupperlimit!=undefined)){
        let diff = this.subDetailsInfo.subupperlimit - this.subDetailsInfo.sublowerlimit;
       
        if(diff<0){
          this.alertmessages("Upper Limit cannot be less than Lower Limit", "danger");
          return false
        }
      }
      return true;
    }

  submit() {
    console.log(" save ", this.insertITSlabDetails);
    console.log(" lowerlimit ", this.insertITSlabDetails.lowerlimit);
    console.log(" upperlimit ", this.insertITSlabDetails.upperlimit);
    console.log(" eduCessPercent ", this.insertITSlabDetails.eduCessPercent);
    console.log(" selectRegimeId ", this.insertITSlabDetails.selectRegimeId);
    console.log(" maleApplicable ", this.insertITSlabDetails.maleApplicable);
    console.log(" femaleApplicable ", this.insertITSlabDetails.femaleApplicable);
if(this.validateLimit()){
  if(this.insertITSlabDetails.lowerlimit === undefined || this.insertITSlabDetails.lowerlimit === ""  || this.insertITSlabDetails.upperlimit  === undefined||this.insertITSlabDetails.upperlimit  === ""  || 
    this.insertITSlabDetails.eduCessPercent === undefined || this.insertITSlabDetails.eduCessPercent === "" ||this.insertITSlabDetails.selectRegimeId === undefined || this.insertITSlabDetails.selectRegimeId ===""
    || ((this.insertITSlabDetails.maleApplicable === undefined && this.insertITSlabDetails.femaleApplicable === undefined)
    || (this.insertITSlabDetails.maleApplicable===false && this.insertITSlabDetails.femaleApplicable===false))
  ){
  this.alertmessages("Please Enter all the required field", "danger");
      return;
}

//     if (this.insertITSlabDetails.lowerlimit == undefined || this.insertITSlabDetails.lowerlimit.trim().length == 0) {
//       this.alertmessages("Lower Limit is Required", "danger");
//       return;
//     }

//     if (this.insertITSlabDetails.upperlimit == undefined || this.insertITSlabDetails.upperlimit.trim().length == 0) {
//       this.alertmessages("Upper Limit is Required", "danger");
//       return;
//     }

//     if (this.insertITSlabDetails.eduCessPercent == undefined || this.insertITSlabDetails.eduCessPercent.trim().length == 0) {
//       this.alertmessages("Edu Cess % is Required", "danger");
//       return;
//     }

//     if (this.insertITSlabDetails.selectRegimeId == undefined || this.insertITSlabDetails.selectRegimeId.trim().length == 0) {
//       this.alertmessages("Regime is Required", "danger");
//       return;
//     }
// console.log("maleApplicable", this.insertITSlabDetails.maleApplicable);
// console.log("femaleApplicable", this.insertITSlabDetails.femaleApplicable);
//     if ((this.insertITSlabDetails.maleApplicable == undefined && this.insertITSlabDetails.femaleApplicablemaleApplicable == undefined)
//       || (this.insertITSlabDetails.maleApplicable && this.insertITSlabDetails.femaleApplicable)
//     || (this.insertITSlabDetails.maleApplicable==false && this.insertITSlabDetails.femaleApplicable==false)
//     ) {
//       this.alertmessages("Applicable for is Required", "danger");
//       return;
//     }

    
    console.log('combined list ', this.employeeRuleDetail);
    const obj1 = {
      sublowerlimit: this.subDetailsInfo.sublowerlimit === undefined ? 0 : this.subDetailsInfo.sublowerlimit,
      subupperlimit: this.subDetailsInfo.subupperlimit === undefined ? 0 : this.subDetailsInfo.subupperlimit,
      subeducesspercent: this.subDetailsInfo.subeducesspercent === undefined ? 0 : this.subDetailsInfo.subeducesspercent,
      userId: 'Add'
    }
    if(obj1.sublowerlimit!=0 && obj1.subupperlimit!=0 && obj1.subeducesspercent!=0 && obj1.sublowerlimit!=null && obj1.subupperlimit!=null && obj1.subeducesspercent!=null){
      this.employeeRuleDetail.push({ ...obj1 });
      this.clearSubDeatails();
      this.subDetailsForm.form.reset();
    }
    if(this.insertITSlabDetails.femaleApplicable){
      this.insertITSlabDetails.femaleApplicable = true;
    }else{
      this.insertITSlabDetails.femaleApplicable = false;
    }if(this.insertITSlabDetails.maleApplicable){
      this.insertITSlabDetails.maleApplicable = true;
    }else{
      this.insertITSlabDetails.maleApplicable = false;
    }
    

    this.insertITSlabDetails.subDetails= this.employeeRuleDetail;
    console.log("insertITSlabDetails ", this.insertITSlabDetails);

    this.empService.saveItSlabMaster(this.insertITSlabDetails).subscribe(
      (data) => {
        console.log(data);
        this.getITSlabMasterData();
        this.clear();
        this.add_view_toggle = 'viewMaster';
        this.addOrUpdate = true;
        this.alertmessages("Successfully saved IT Slab Master", "success");
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });
    }
  }
}
