import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BooleanService {

  constructor() { }



   // Define a Subject that emits boolean values
   //private booleanSubject = new Subject<boolean>();
   private booleanSubject = new BehaviorSubject<boolean>(false); // Initialized with a default value


   // Observable for other components to subscribe to
   booleanObservable$ = this.booleanSubject.asObservable();
 
   // Method to emit the boolean value
   emitBooleanValue(value: boolean) {
     this.booleanSubject.next(value);
   }

   
   }

