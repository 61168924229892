import { Component, OnInit } from '@angular/core';
import { EmpsalaryslipService } from '../services/empsalaryslip.service';

@Component({
  selector: 'app-statutory-report-download',
  templateUrl: './statutory-report-download.component.html',
  styleUrls: ['./statutory-report-download.component.css']
})
export class StatutoryReportDownloadComponent implements OnInit {

  constructor(private statutoryservice: EmpsalaryslipService) { }

  ngOnInit(): void {
    const currentMonthValue = new Date().getMonth() + 1; // getMonth() returns 0 for January, so +1
    this.selectedMonth = this.months.find(month => month.value === currentMonthValue);

    const currentYear = new Date().getFullYear();
    // this.years.push(''); // Add empty option
    for (let year = currentYear; year >= 2018; year--) {
      this.years.push(year.toString());
    }
    this.year = currentYear.toString();
  }
  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  selectedMonth: { name: string, value: number } | undefined;
  years: string[] = [];
  directoryPath: string = '';
  year: any;
  selecteduniqueeName: any;
  selecteduniqueeEmpId:any;
  employeeList: any[] = [];
  query: any;
  queryName: any;

  downloadFile() {

    console.log('year',this.year);
    console.log('month',this.selectedMonth);
    console.log('month',this.query);
   // console.log('month',this.selecteduniqueeEmpId);

    // if(this.query!=undefined || this.query != null){
    // const [name, id] = this.query.split(' / ');

    // this.selecteduniqueeName =name;
    // this.selecteduniqueeEmpId =id;
    // }
    if(!this.year){
      console.log('year',this.year);
      return;
    }
    // if(this.selectedMonth.value.i)
    if (!this.selectedMonth || !this.selectedMonth.name || !this.selectedMonth.value) {
      console.log('month',this.selectedMonth);
      return;
    }
    // if(this.selecteduniqueeEmpId == 'Select Employee'){

    //   this.selecteduniqueeEmpId == '';

    // }

    this.statutoryservice.downloadStatutoryReport  (this.year,this.selectedMonth.value.toString(),this.selectedMonth.name.toString()).subscribe(
      (data) => {
        if (!data) {
          this.alertmessages('No data available for the selected year and month.', 'danger');
          return;
        }
      console.log('download name',this.statutoryservice);
      console.log('data',data);
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = this.selectedMonth.name.toString()+this.year+"STATUTORY_REPORT.xls";
      anchor.href = fileURL;
      anchor.click();
      
      },
      (error) => {
        if (error.status === 204) {
          this.alertmessages('No data available for the selected year and month.', 'danger');
      } else {
          this.alertmessages('Error occurred while downloading the report.', 'danger');
      }
        console.log("Error Occured ", error);
      }
    );
   }
   alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

}
