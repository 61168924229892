import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { configSetting } from "../models/configSetting";
import { LeaveMaster } from "../models/leaveMaster";
import { mandatory } from "../models/mandatory";
import { Configkey } from "../models/Configkey";
import { AdminConfig } from "../models/AdminConfig";
import { ExpenseSubmission } from "../models/ExpenseSubmission";
import { Level } from "../models/Level";
import { LeaveAuthority } from "../models/LeaveAuthority";
import { AttendanceAuthority } from "../models/AttendanceAuthority";

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  public setConfigType(configList: configSetting): Observable<any> {
    // console.log("post method ,..... data = ",configList)
    this.url = '/v1/configList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(configList, queryParameters, path);
  }

  

  public getConfigType(): Observable<any> {
    const path = this.basePath + '/v1/configList'
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public saveLeaveMaster(obj: any): Observable<any> {
    console.log("value sent Leave master", obj)
    this.url = '/v1/leaveMasterPost';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  public setMadatoryFields(mandatoryFields: AdminConfig[]): Observable<any> {
    console.log("update Mandatory Fields : ", mandatoryFields)
    this.url = '/v1/updateMandatoryFields';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(mandatoryFields, queryParameters, path);
  }

  public getDesignationType(): Observable<any> {
    this.url = '/v1/designationDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getRoleType(): Observable<any> {
    this.url = '/v1/roleDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getDepartmentType(): Observable<any> {
    this.url = '/v1/departmentDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getExpenseAuthority(): Observable<any> {
    this.url = '/v1/fetchExpenseAuthority';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getPolicyAuthority(): Observable<any> {
    this.url = '/v1/fetchPolicyAuthority';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getAttendanceLeave(): Observable<any> {
    this.url = '/v1/fetchAttendanceLeaveAuthority';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public saveExpenseAuthority(levellist: Level[]): Observable<any> {
    console.log("update Mandatory Fields : ", levellist)
    this.url = '/v1/saveExpenseAuthority';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(levellist, queryParameters, path);
  }
  public savePolicyAuthority(levellist: Level[]): Observable<any> {
    console.log("update Mandatory Fields : ", levellist)
    this.url = '/v1/savePolicyAuthority';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(levellist, queryParameters, path);
  }
  public saveAttendanceAuthority(info: any): Observable<any> {
    console.log("save attendance authority............ ", info)
    this.url = '/v1/saveAttendanceAuthority';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }

  public saveLeaveEncashment(info: any): Observable<any>{
    console.log("save save Leave Encashment............ ", info)
    // this.url =
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }
  public(): Observable<any> {
    this.url = '/v1/policyAuthoritydetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public saveEncashment(fromdate: string, todate: string): Observable<any> {
 
    this.url = '/v1/saveEncashmentSetting';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromdate', fromdate);
    queryParameters = queryParameters.set('todate', todate);
    return this.commonService.postWithHttpInfo("",queryParameters, path);
  }

  public getDebitAccNum(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getDebitAccNum';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getSalaryAdvanceSettings(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getSalaryAdvanceSettings';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getSalaryLoanSettings(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getSalaryLoanSettings';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getSalaryDetails(): Observable<any> {
    this.url = '/v1/salaryDetailMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public updateSalaryAdvanceSettings(interestRate: string,salaryHeadId:any , salaryDetailId:any,maxNoOfTimesForAdvance): Observable<any> {
    // console.log("post method ,..... data = ",configList)
    this.url = '/v1/updateSalaryAdvanceSettings';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('interestRateForAdvance', interestRate);
    queryParameters = queryParameters.set('salaryHeadIdForAdvance', salaryHeadId);
    queryParameters = queryParameters.set('salaryDetailIdForAdvance', salaryDetailId);
    queryParameters = queryParameters.set('MaxAmtForAdvance', maxNoOfTimesForAdvance);

    return this.commonService.postWithHttpInfo("", queryParameters, path);
  }

  public updateSalaryLoanSettings(interestRate: string,salaryHeadIdForloan:any , salaryDetailIdForloan:any,maxNoOfTimesForLoan): Observable<any> {
    // console.log("post method ,..... data = ",configList)
    this.url = '/v1/updateSalaryLoanSettings';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('interestRateForLoan', interestRate);
    queryParameters = queryParameters.set('salaryHeadIdForloan', salaryHeadIdForloan);
    queryParameters = queryParameters.set('salaryDetailIdForloan', salaryDetailIdForloan);
    queryParameters = queryParameters.set('MaxAmtForLoan', maxNoOfTimesForLoan);
    return this.commonService.postWithHttpInfo("", queryParameters, path);
  }

  public UpdateOrInsertDebitAccNum(debAccNum: string): Observable<any> {
    // console.log("post method ,..... data = ",configList)
    this.url = '/v1/updateDebitNum';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('debAccNum', debAccNum);
    
    return this.commonService.postWithHttpInfo("", queryParameters, path);
  }
}
