export class savingsmaster {
    id:number;
    name: any;
    saving: number;
    maxamountmale:number;
    maxamountfemale:number;
    ishracalc:false;
    isapplyforall:false;
   
	
}