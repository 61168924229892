import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormTreportService {

  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }


  public downloadformtReport(year:any,monthValue:any,monthName: any): Observable<any> {
    this.url = '/v1/downloadformtReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('year', year);
        queryParameters = queryParameters.set('monthValue', monthValue);
        queryParameters = queryParameters.set('monthName', monthName);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }
}
