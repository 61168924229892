import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LeaveEncashmentInfo } from '../models/employee-leave-encash-info.model';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeLeaveEncashService {
  getLeaveForApprovalToday() {
    throw new Error('Method not implemented.');
  }

  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url!: string;
  public colorList = ['#33c5ff', '#f7284a', '#01c353', '#fe7700', '#c3c001', '#33e0ff', '#6574cd', '#ef4eb8', '#ecb403', '#17a2b8'];
  public empLeave: string[] = []
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

public getAllLeaveTypes(): Observable<any> { 
    this.url = '/v1/getEncashableLeave'; 
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  getleavebalance(uempid: number, type: string, AppliedDate: string) {
    this.url = '/v1/getOpeningNEncashmentLeave';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uempid', uempid);
    queryParameters = queryParameters.set('type', type);
    queryParameters = queryParameters.set('fromdate', AppliedDate);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public setLeaveNames(leaveNames) {
    this.empLeave = leaveNames;
  }

  public getLeaveNames() {
    return this.empLeave;
  }

    getconfigDateRange(fromdate: string) {
      this.url = '/v1/checkVisibility';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      queryParameters = queryParameters.set('fromdate', fromdate);
      return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    }
    
    public saveApplyLeaveEncashment(unqiueEmpid:number,applieddate:string,noofdays:number,
      leavetype:string
    ): Observable<any> {
      this.url = '/v1/saveEncashment';
      const path = this.basePath + this.url;
       let queryParameters = new HttpParams();
      queryParameters = queryParameters.set('unqiueEmpid', unqiueEmpid)
      queryParameters = queryParameters.set('applieddate', applieddate)
      queryParameters = queryParameters.set('noofdays', noofdays)
      queryParameters = queryParameters.set('leavetype', leavetype)
      return this.commonService.postWithHttpInfo("",queryParameters, path)
     // return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
     }

     public getEncashmentLeaveApplied(unqiueEmpid: number): Observable<any> {
      this.url = '/v1/getEncashableLeaveDetail';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      if (unqiueEmpid !== null || unqiueEmpid !== undefined) {
        queryParameters = queryParameters.set('unqiueEmpid', unqiueEmpid);
      }
      return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    }

    public getLeaveEncashRepot(fromDate: any,toDate: any, uempid: any,limitPara: any, offsetPara: any): Observable<any> {
      this.url = '/v1/searchEncashReport';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
     
          queryParameters = queryParameters.set('fromDate', fromDate);
          queryParameters = queryParameters.set('toDate', toDate);
          if (uempid !== null) {
            queryParameters = queryParameters.set('uempid', uempid);
          }
          queryParameters = queryParameters.set('limitPara', limitPara);
          queryParameters = queryParameters.set('offsetPara', offsetPara);
     
      return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  
    }

    public downloadEncashmentReport(): Observable<any> {
      this.url = '/v1/downloadEncashmentReport';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      // if (employeeid !== null) {
      //     queryParameters = queryParameters.set('employeeId', employeeid);
      // }
      // if (employeeName !== null) {
      //     queryParameters = queryParameters.set('employeeName', employeeName);
      // }
      // queryParameters = queryParameters.set('limitPara', limitPara);
      // queryParameters = queryParameters.set('offsetPara', offsetPara);
      return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

  getleavetypeByName(type: string) {
    this.url = '/v1/getleavetypeByName';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('type', type);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

 
}