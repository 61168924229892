import { Component, OnInit, ViewChild } from '@angular/core';

// import { NgModule } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';

import { FormControl } from "@angular/forms";

import { Observable } from 'rxjs';

import { Subscription } from 'rxjs';
import { ContractclientDocument } from '../models/ContractClientDocument';
import { ContractclientService } from '../services/contractclient.service';


declare var $: any;

@Component({
  selector: 'app-contract-project-des-act',
  templateUrl: './contract-project-des-act.component.html',
  styleUrls: ['./contract-project-des-act.component.css']
})

export class ContractProjectDesActComponent implements OnInit {
  contractClientDesInfo: ContractclientDocument;
  client_id: ContractclientDocument[];
  project_data: ContractclientDocument;
  contractClientProInfoData: ContractclientDocument[] = [];
  contractClientData: ContractclientDocument[] = [];
  contractClientProDesEstDataList: ContractclientDocument[] = [];
  contractClientProDesActDataList: ContractclientDocument[] = [];
  contractClientProLocList: any[] = [];
  contractClientEmpList: any[] = [];
  contractClientRPTManList: any[] = [];
  empDesList: any[] = [];
  filterContractClientProLocList: any[] = [];
  cllientBusName: any;
  empDesignation: any;
  clientProId: any = undefined;
  contractClientProDesActData: ContractclientDocument;
  empforRPT: ContractclientDocument[];
  empIDinForm: any;
  deleteProActInfo: ContractclientDocument;
  contractstream$!: Subscription;
  unFilteredLocation: Array<any> = [];
  filteredLocation: Array<any> = [];
  filteredBillingRate: Array<any> = [];
  serachEmployeeBy: string = 'name';

  //Validation
  clickedEdit: boolean = false;
  clickedEditEmpName: string;
  isdisable = true;
  // isdisableDate = true;
  projectStartDate: any;
  projectEndDate: any;
  projectFreezeDate: string;
  submitted: boolean;
  alloNumberOnly: boolean = false;
  alloOnly100: boolean = false;
  billingRateNumberOnly: boolean = false;
  notInBudgeting: boolean = false;
  startDate: boolean = false;
  endDate: boolean = false;
  startDateSmall: boolean = false;
  endDateGreat: boolean = false;
  freezeDateCheckStart: boolean = false;
  freezeDateCheckEnd: boolean = false;
  showBudgetingList: boolean = false;
  myControl = new FormControl();
  filterdEmpList: Observable<any[]>;
  filterConst: boolean = false;
  
  @ViewChild('contractClientDesActForm') contractClientDesActForm: NgForm;
  constructor(private contractclientService: ContractclientService, private _snackBar: MatSnackBar,) { }

  ngOnInit(): void {
    this.contractClientDesInfo = new ContractclientDocument();
    this.contractClientProDesActData = new ContractclientDocument();
    // this.filterValueEmpList()
    this.getClientData();
    this.getEmpDesData();
  }

  // private _filter(value: string): any[] {
  //   console.log("Reached _filter part >> vlue is", value)
  //   const filterValue = value.toLowerCase();
  //   return this.contractClientEmpList.filter(option => option.name.toLowerCase().includes(filterValue));
  // }

  // filterValueEmpList() {
  //   console.log("called filter")
  //   this.filterdEmpList = this.myControl.valueChanges.pipe(
  //     startWith(''),
  //     map(value => {
  //       console.log("Pipe Called");
  //       return this.contractClientEmpList.filter(option =>
  //         option.name.toLowerCase().includes(value.toLowerCase())
  //       );
  //     })
  //     // map(value => this._filter(value))
  //   );
  // }

  // filterValueEmpListCheck(event: any){
  //   if (event.target.value === '' && this.filterConst) {
  //     this.filterdEmpList = this.myControl.valueChanges.pipe(
  //       startWith(''),
  //       map(value => {
  //         console.log("Pipe Called");
  //         return this.contractClientEmpList.filter(option =>
  //           option.name.toLowerCase().includes(value.toLowerCase())
  //         );
  //       })
  //     );

  //     this.filterConst = false;
  //   }
  // }
  // API calls

  getClientData() {
    this.contractstream$ = this.contractclientService.getContractClient().subscribe(
      data => {
        // console.log(data)
        this.contractClientData = data.client;
        // console.log("List of all contract client in client projects >>>> ", this.contractClientData);
      },
      error => {
        // console.log(error);
      }
    );
  }

  getEmpDesData() {

    this.contractstream$ = this.contractclientService.getDesignationList().subscribe(
      data => {
        // console.log("Employee designation >> >> >>", data)
        this.empDesList = data;
      },
      error => {
        // console.log(error);
      }
    );
  }

  getClientName(v: any) {
    // console.log("Anny >> >> >>", v)
    this.cllientBusName = v;
  }

  getClientProData() {
    this.contractClientDesActForm.reset();
    this.client_id = [];

    this.client_id = this.contractClientData.filter((dName) => {
      return dName.business_name.toLowerCase() === this.cllientBusName.toLowerCase();
    });

    this.contractstream$ = this.contractclientService.getContractClientProjectInfo(this.client_id[0].id).subscribe(
      data => {
        // console.log("Respons from contract client Project List >>> ", data)
        this.contractClientProInfoData = data.contractprojectdeta;
        this.contractClientProLocList = [];

        if ($("#searchProjectBaseClient").hasClass("d-none") && $("#btnProjectBaseClient").hasClass("d-none")) {
          $('#searchProjectBaseClient').removeClass('d-none')
          $('#btnProjectBaseClient').addClass('d-flex')
          $('#btnProjectBaseClient').removeClass('d-none')
          $('#searchProjectBaseClient').addClass('d-flex')
          $('#serachClient').removeClass('col-md-11')
          $('#serachClient').addClass('col-md-6')
          // console.log("Yes it has")
        }
      },
      error => {
        // console.log(error);
      }
    );
  }

  getContractClientProLocSerarch() {
    console.log("Project Id : Client Project Id", this.contractClientProDesActData.project_id)

    this.project_data = this.contractClientProInfoData.find((dName) => {
      return dName.id == this.contractClientProDesActData.project_id;
    });

    // console.log("Project data >> ", this.project_data)
    $('#add-des-act').removeClass('disable-div')

    this.getContractClientProLoc(this.project_data)
    this.checkLocAvailableInBudgeting()
  }

  getContractClientProLoc(clientProject: any) {
    this.submitted = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.startDateSmall = false;
    this.endDateGreat = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    // console.log("Project Loc : ", clientProject)
    this.contractClientProDesActData.start_date = clientProject.start_date;
    this.projectStartDate = clientProject.start_date;
    this.contractClientProDesActData.end_date = clientProject.end_date;
    this.projectEndDate = clientProject.end_date;
    this.clientProId = clientProject.id;
    this.projectFreezeDate = clientProject.freeze_date;

    // console.log("freeze from response", clientProject.freeze_date)
    // console.log("Freeze date >>", this.projectFreezeDate);
    this.checkfreezedate();

    // console.log("Project Id : ", this.clientProId, clientProject)

    this.getProDesEstData();
    this.getProDesActData();

    this.contractstream$ = this.contractclientService.getContractClientProjectLoc(this.clientProId).subscribe(
      data => {
        // console.log("Respons from contract client Project Locations List >>> >>> >>> ", data)

        // console.log("One step >> >> >>", this.contractClientProLocList)
        this.contractClientProLocList = data.projectlocationdeta;
        // console.log("Srver locations >> ", this.contractClientProLocList);

        // this.contractClientProDesActDataList = [];
        // this.filterContractClientProLocList = [];

        // for (var i = 0; i < this.contractClientProLocList.length; i++) {
        //   this.filterContractClientProLocList.push(this.contractClientProLocList[i].location_name)
        // }

        // console.log("Loc List > > >", this.filterContractClientProLocList)
        // // this.getProDesEstData();
        // // this.getProDesActData();

        this.contractClientDesActForm.form.get("project_location_id").setValue(this.contractClientProLocList[0].id)
      },
      error => {
        // console.log(error);
      }
    );

  }

  getContractClientProLocView() {
    if ($("#projectDataList").hasClass("d-none") && $("#showProjectDataList").hasClass("fa-th-list")) {
      $('#projectDataList').removeClass('d-none')
      $('#showProjectDataList').removeClass('fa-th-list')
      $('#showProjectDataList').addClass('fa-compress')
    } else {
      $('#projectDataList').addClass('d-none')
      $('#showProjectDataList').addClass('fa-th-list')
      $('#showProjectDataList').removeClass('fa-compress')
    }
  }

  getLocdataToSee(e: any) {
    // console.log("Lets see location data", e)

    this.checkLocAvailableInBudgeting();
  }

  getEmployeeList(e: any) {
    this.empDesignation = e;
    // console.log("Emp designation", this.empDesignation)
    this.getEmpListByDes(this.empDesignation)
  }

  getEmpListByDes(desID: number) {
    // console.log("Designation ID: ", desID)
    this.contractstream$ = this.contractclientService.getEmpListByDes(desID).subscribe(
      data => {
        // console.log("Respons from Des ID : Employee List >>> ", data)
        this.contractClientEmpList = [];
        this.contractClientEmpList = data.projectActualArray;

      },
      error => {
        // console.log(error);
      }
    );
  }
  // $('#checkboxForEmployeeNameAndID').checked
  checkForCheckboc() {
    if ($('#checkboxForEmployeeNameAndID').is(':checked')) {
      this.serachEmployeeBy = 'ID'
    } else {
      this.serachEmployeeBy = 'name'
    }
    // console.log("ID Name >>", this.serachEmployeeBy)
  }
  // makeDesEst(clientProject: any)

  getProDesEstData() {
    this.contractstream$ = this.contractclientService.getContractClientProDesEstData(this.clientProId).subscribe(
      data => {
        // console.log("Get Project Designation Est >> >> >>", data)
        // this.empDesList = data;
        this.contractClientProDesEstDataList = [];
        this.contractClientProDesEstDataList = data.projectdesignest;

        this.unFilteredLocation = [];
        this.filteredLocation = [];
        this.filteredBillingRate = [];
        $('#projectAlloDataList').removeClass('d-none');

        // console.log("unFillterd locations", this.unFilteredLocation)
        // console.log("Fillterd locations", this.filteredLocation)

        for (var i = 0; i < this.contractClientProDesEstDataList.length; i++) {
          this.unFilteredLocation.push(this.contractClientProDesEstDataList[i])
        }

        this.filteredLocation = this.unFilteredLocation.filter(
          (obj, index) =>
            this.unFilteredLocation.findIndex((item) => item.project_location_id === obj.project_location_id) === index
        );

        this.filteredBillingRate = this.unFilteredLocation.filter(
          (obj, index) =>
            this.unFilteredLocation.findIndex((item) => item.billing_rate === obj.billing_rate) === index
        );

        // this.contractClientDesActForm.form.get("project_location_id").setValue(this.filteredLocation[0].project_location_id)
        // console.log("Value to initialize", this.filteredLocation[0].project_location_id)
        // console.log("unFillterd locations", this.unFilteredLocation)
        // console.log("Fillterd locations", this.filteredLocation)

      },
      error => {
        // console.log(error);
      }
    );
  }

  showBudgetingTable() {

    if ($('#showTable').hasClass('py-3')) {
      $('#showTable').removeClass('py-3');
      $('#showBudgetingDataList').removeClass('fa-th-list')
      $('#showBudgetingDataList').addClass('fa-compress')
    } else {
      $('#showTable').addClass('py-3');
      $('#showBudgetingDataList').addClass('fa-th-list')
      $('#showBudgetingDataList').removeClass('fa-compress')
    }

    if (this.showBudgetingList) {
      this.showBudgetingList = false;
    } else {
      this.showBudgetingList = true;
    }

  }

  populateInputActual(data: ContractclientDocument) {
    // console.log("Data to be populated", data)
    data.designationID = data.designation_id;
    // data.designationid = data.designation_id;

    this.isdisable = true;
    this.submitted = false;
    this.alloNumberOnly = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.alloOnly100 = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    // console.log("Data to be populated : Two", data)

    this.contractClientProDesActData = { ...data };
    // this.getProDesActData()
  }

  getProDesActData() {
    this.contractstream$ = this.contractclientService.getContractClientProDesActData(this.clientProId).subscribe(
      data => {
        // console.log("Get Project Designation Act data >> >> >>", data)
        this.contractClientProDesActDataList = data.projectdesignactual;
        this.checkfreezedate();
      },
      error => {
        // console.log(error);
      }
    );
  }

  showBudgetedBillingRate() {
    $('#showBudgetedBillingRate').modal('toggle');
  }

  populateBudgetedBillingRate(data: any) {
    // console.log("Data to populate", data)

    this.contractClientDesActForm.form.get("billing_rate").setValue(data.billing_rate);
    $('#showBudgetedBillingRate').modal('toggle');
    // this.contractClientDesActForm.form.get("billing_rate").setValue(data)

  }

  saveContractClientProDesActData(valid) {
    this.submitted = true;
    this.alloNumberOnly = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.alloOnly100 = false;
    this.startDateSmall = false;
    this.endDateGreat = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    // console.log("Final details details", this.contractClientProDesActData)
    // console.log("Location details", this.contractClientProDesActData.project_location_id)

    if (!valid) {
      // console.log("Not valid")
      return
    }
    if (!(/^[0-9]+$/.test(this.contractClientProDesActData.allocation_percentage))
      && !(/^[0-9]+$/.test(this.contractClientProDesActData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = true;
      this.alloNumberOnly = true;
      this.alloOnly100 = false;
    } else if (!(/^[0-9]+$/.test(this.contractClientProDesActData.allocation_percentage))
      && (/^[0-9]+$/.test(this.contractClientProDesActData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = false;
      this.alloNumberOnly = true;
      this.alloOnly100 = false;
    } else if ((/^[0-9]+$/.test(this.contractClientProDesActData.allocation_percentage))
      && !(/^[0-9]+$/.test(this.contractClientProDesActData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = true;
      this.alloNumberOnly = false;
      this.alloOnly100 = false;
    } else if (100 < parseFloat(this.contractClientProDesActData.allocation_percentage)) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = false;
      this.alloNumberOnly = false;
      this.alloOnly100 = true;
    }

    if (!this.checkFromAndToDate()) {
      // console.log("Error in date")
      return
    }

    if (valid && this.submitted) {

      this.contractClientProDesActData.contract_project_location_ID = this.contractClientProDesActData.project_location_id;
      // console.log("Actuall data to be saved : data ", this.contractClientProDesActData)

      this.contractstream$ = this.contractclientService.saveContractClientProDesActData(this.contractClientProDesActData).subscribe({
        next: (event: any) => {
          // console.log("Uploaded the file successfully:", event);

          if (event?.successMessages) {
            this.clearContractClientProDesActData()
            this.getProDesActData();
            this._snackBar.open("Successfully Added!", 'Close', {
              duration: 2000,
            });
          }
        },
        error: (error: any) => {
          if (error.error?.employeeAllocationExceeds) {
            this._snackBar.open("Employee allocation exceeds 100%", 'Close', {
              duration: 2000,
            });
          } else {
            // console.log("Could not upload the file:");
          }
        }
      });
    } else {
      // console.log("Not validddd")
      return;
    }
  }

  contractclientDesActEdit(data: any) {
    // console.log(data)

    this.clickedEdit = true;
    let projectStartDateUser = new Date(data.start_date)
    let projectFreezeDateNow = new Date(this.projectFreezeDate)
    this.clickedEditEmpName = data.name;

    if (this.projectFreezeDate) {
      if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        // console.log("Indise matrix")
        $('#showAlertCantUpdateFreeze').modal('toggle');
        return;
      }
    }

    // console.log("yes i escaped matrix")
    // console.log("Selected employee list >>", this.contractClientEmpList)

    this.isdisable = false;
    this.getEmpListByDes(data.designationID);
    this.contractClientProDesActData = { ...data };
    this.getReportingManager(data.designationID)

    document.getElementById('update-btn').style.display = "inline-block";
    document.getElementById('save-btn').style.display = "none";
  }

  contractclientDesActDeleteDataStore(data: any) {
    this.deleteProActInfo = data;
    $('#showAlertWarning').modal('toggle');
  }

  contractclientDesActDeleteData() {
    let projectStartDateUser = new Date(this.deleteProActInfo.start_date)
    let projectFreezeDateNow = new Date(this.projectFreezeDate)

    if (this.projectFreezeDate) {
      if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        // console.log("From matrix")
        $('#showAlertCantDeleteFreeze').modal('toggle');
        return;
      }
    }

    // console.log(this.deleteProActInfo)
    this.contractstream$ = this.contractclientService.deleteContractClientProDesActData(this.deleteProActInfo.id).subscribe(
      data => {
        // console.log("Respons from contract client Project Doc Delete >>> ", data)
        this.getProDesActData()
        this._snackBar.open('Successfully delete!', 'Close', {
          duration: 2000,
        });
      },
      error => {
        // console.log(error);
      }
    );
  }

  updateContractClientProDesActData(valid) {
    this.submitted = true;
    this.alloNumberOnly = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.alloOnly100 = false;
    this.startDateSmall = false;
    this.endDateGreat = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    if (!valid) {
      // console.log("Not valid")
      return
    }
    if (!(/^[0-9]+$/.test(this.contractClientProDesActData.allocation_percentage))
      && !(/^[0-9]+$/.test(this.contractClientProDesActData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = true;
      this.alloNumberOnly = true;
      this.alloOnly100 = false;
    } else if (!(/^[0-9]+$/.test(this.contractClientProDesActData.allocation_percentage))
      && (/^[0-9]+$/.test(this.contractClientProDesActData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = false;
      this.alloNumberOnly = true;
      this.alloOnly100 = false;
    } else if ((/^[0-9]+$/.test(this.contractClientProDesActData.allocation_percentage))
      && !(/^[0-9]+$/.test(this.contractClientProDesActData.billing_rate))) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = true;
      this.alloNumberOnly = false;
      this.alloOnly100 = false;
    } else if (100 < parseFloat(this.contractClientProDesActData.allocation_percentage)) {
      // console.log("Enter only number")
      this.submitted = false;
      this.billingRateNumberOnly = false;
      this.alloNumberOnly = false;
      this.alloOnly100 = true;
    }

    if (!this.checkFromAndToDate()) {
      // console.log("Error in date")
      return
    }

    if (valid && this.submitted) {

      // console.log("Actuall data to be updated : data ", this.contractClientProDesActData)

      this.contractstream$ = this.contractclientService.updateContractClientProDesActData(this.contractClientProDesActData).subscribe({
        next: (event: any) => {
          // console.log("Uploaded the file successfully:", event);

          if (event?.errorMessages) {
            this._snackBar.open("Employee allocation exceeds 100%", 'Close', {
              duration: 2000,
            });
          } else if (event?.successMessages) {

            this.getProDesActData();
            this.clearContractClientProDesActData()

            this._snackBar.open("Successfully Added!", 'Close', {
              duration: 2000,
            });
          }
        },
        error: (err: any) => {
          // console.log("Could not upload the file:");
        }
      });
    } else {
      // console.log("Not valid")
      return;
    }
  }

  clearContractClientProDesActData() {
    this.contractClientProDesActData.empid=undefined;   // on clear name will stay issue
    this.contractClientRPTManList=[];   // on clear manger name will stay issue
    this.isdisable = true;
    this.submitted = false;
    this.alloNumberOnly = false;
    this.billingRateNumberOnly = false;
    this.startDate = false;
    this.endDate = false;
    this.alloOnly100 = false;
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;
    this.contractClientDesActForm.reset();
    document.getElementById('update-btn').style.display = "none";
    document.getElementById('save-btn').style.display = "inline-block";
  }

  // Validation

  displayDataEmp(data: number): string {
    // console.log("Data from templete", data)
    // console.log("check data one", this.serachEmployeeBy)
    // if ($('#checkboxForEmployeeNameAndID').is(':checked')) {
    //   this.serachEmployeeBy = 'ID'
    // } else {
    //   this.serachEmployeeBy = 'name'
    // }
    // const selectedEmployee = this.contractClientEmpList.find((employee) => employee.uniqueemployeeid === data);
    // console.log("Check value ofemp list in >> displayDataEmp >>", selectedEmployee)
    // this.toggleDisable(data);

    if (this.clickedEdit) {
      $("#checkboxForEmployeeNameAndID").prop("checked", false);
      this.serachEmployeeBy = 'name';
      this.clickedEdit = false;
      return this.clickedEditEmpName;
    }

    this.empforRPT = this.contractClientEmpList.filter((dName) => {
      return dName.uniqueemployeeid === data;
    });

    // console.log("Selected employee list >>", this.contractClientEmpList)
    // console.log("Selected employee >>", this.empforRPT)  

    if (this.serachEmployeeBy === 'name') {

      // console.log("Data is name", data)
      // return "name";
      return data ? this.empforRPT[0].name : undefined;

    } else {

      // console.log("Data is employee ID", data)
      // return "ID";
      return data ? this.empforRPT[0].employeeID : undefined;

    }
  }

  toggleDisable(event: any) {
    // if (event.target.value === '') {
    //   this.filterdEmpList = this.myControl.valueChanges.pipe(
    //     startWith(''),
    //     map(value => {
    //       console.log("Pipe Called");
    //       return this.contractClientEmpList.filter(option =>
    //         option.name.toLowerCase().includes(value.toLowerCase())
    //       );
    //     })
    //   );
    //   this.filterConst = true;
    // }

    // console.log("Data to add final", this.contractClientProDesActData.empid)

    // const selectedFruitName = event.target.value;
    // const selectedFruitId = event.target.value.getAttribute('data-value');

    // console.log("ID Value >>", event)

    // console.log("ID Value >> >> >>", selectedFruitId)

    // console.log("Emp list >>", this.contractClientEmpList)

    // console.log("Check value ofemp list in >> toggleDisable >>", this.contractClientEmpList)

    // console.log("Selected employee >>", this.empforRPT)


    if (event) {
      this.isdisable = false
    } else {
      this.isdisable = true;
    }
    // console.log("Designation value ", this.empforRPT[0].designation)
    this.getReportingManager(this.empforRPT[0].designation);
  }

  getReportingManager(designationId: any) {
    this.contractstream$ = this.contractclientService.getReportingManager(designationId).subscribe(
      data => {
        // console.log("Respons from Des ID : Reporting manager List >>> ", data)
        this.contractClientRPTManList = [];
        this.contractClientRPTManList = data.projectActualArray;

      },
      error => {
        // console.log(error);
      }
    );
  }

  // toggleDisableDate(obj) {
  //   if (obj) {
  //     this.isdisableDate = false
  //   } else {
  //     this.isdisableDate = true;
  //   }
  // }

  checkLocAvailableInBudgeting() {
    this.notInBudgeting = false;
    const isElementPresent = this.filteredLocation.some((o) => o.project_location_id === this.contractClientDesActForm.form.get("project_location_id").value);
    if (isElementPresent) {
      this.notInBudgeting = false;
    } else {
      this.notInBudgeting = true;
    }
  }


  checkfreezedate() {
    this.freezeDateCheckStart = false;
    this.freezeDateCheckEnd = false;

    // console.log("Reached checkfreezedate() >>")

    // let projectStartDate = new Date(this.projectStartDate)
    // let projectEndDate = new Date(this.projectEndDate)

    // let projectStartDateUser = new Date(this.contractClientDesEstForm.form.get("start_date").value)
    // let projectEndDateUser = new Date(this.contractClientDesEstForm.form.get("end_date").value)

    let projectStartDateUser = new Date(this.contractClientProDesActData.start_date)
    let projectEndDateUser = new Date(this.contractClientProDesActData.end_date)

    let projectFreezeDateNow = new Date(this.projectFreezeDate)

    let falseOrTrue = true;

    // console.log("Freeze date", projectFreezeDateNow)
    // console.log("start date", projectStartDateUser)
    // console.log("end date", projectEndDateUser)

    if (this.projectFreezeDate) {
      if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        // console.log("Reached inside If inside If two two")
        this.submitted = false;
        this.freezeDateCheckStart = true;
        this.freezeDateCheckEnd = false;
        falseOrTrue = false;
      } else if (projectFreezeDateNow.valueOf() >= projectStartDateUser.valueOf()) {
        // console.log("Reached inside If inside If one")
        this.submitted = false;
        this.freezeDateCheckStart = true;
        this.freezeDateCheckEnd = false;
        falseOrTrue = false;
      }

      if (!falseOrTrue) {
        // console.log("Reached falseee")
        return false;
      } else {
        return true;
      }

      // if (projectFreezeDateNow.valueOf >= projectEndDateUser.valueOf) {
      //   console.log("Reached inside If inside If two")
      //   this.submitted = false;
      //   this.freezeDateCheckStart = false;
      //   this.freezeDateCheckEnd = true;
      // }
    }

  }

  checkFromAndToDate() {
    this.startDate = false;
    this.endDate = false;
    this.endDateGreat = false;
    this.startDateSmall = false;

    if (!this.contractClientDesActForm.form.get("start_date").value) {
      return;
    }
    if (!this.contractClientDesActForm.form.get("end_date").value) {
      return;
    }

    let projectStartDateUser = new Date(this.contractClientDesActForm.form.get("start_date").value)
    let projectEndDateUser = new Date(this.contractClientDesActForm.form.get("end_date").value)

    let projectStartDate = new Date(this.projectStartDate)
    let projectEndDate = new Date(this.projectEndDate)

    if (this.projectFreezeDate) {
      if (!this.checkfreezedate()) {
        return;
      }
    }
    
    // console.log("Now i can validate ate")
    if (projectEndDateUser.valueOf() > projectEndDate.valueOf()
      && projectStartDateUser.valueOf() >= projectStartDate.valueOf()) {
      this.endDate = false;
      this.submitted = false;
      this.startDateSmall = false;
      this.endDateGreat = true;

    } else if (projectStartDateUser.valueOf() < projectStartDate.valueOf()
      && projectEndDateUser.valueOf() <= projectEndDate.valueOf()) {
      this.endDate = false;
      this.submitted = false;
      this.endDateGreat = false;
      this.startDateSmall = true;

    } else if (projectStartDateUser.valueOf() >= projectEndDateUser.valueOf()) {
      // console.log("It's  not right")
      this.submitted = false;
      this.startDateSmall = false;
      this.endDateGreat = false;
      this.endDate = true;

    } else {
      // console.log("It's okay")
      this.startDate = false;
      this.endDate = false;
      this.startDateSmall = false;
      this.endDateGreat = false;
      return true;
    }
  }

  ngOnDestroy(): void {
    this.contractstream$.unsubscribe();
  }
}
