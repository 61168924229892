import { Component, OnInit,HostListener } from '@angular/core';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { DocumentInfoService } from 'src/app/services/documentinfo.service';


@Component({
  selector: 'app-form-t-report',
  templateUrl: './form-t-report.component.html',
  styleUrls: ['./form-t-report.component.css']
})
export class FormTReportComponent implements OnInit {
  employeeMasterLucene: EmployeeMaster[];
  uniqueemployeeid: String;
  employeeMaster: EmployeeMaster;
  employeeMasterList: EmployeeMaster[];
  branchInfo: any;
  nowDetailedReport: boolean = false;
  branch: any = "all";
  limitPara: any;
	offsetPara: any;
  fromDate: string;
	toDate: string;
  query: any;
  //employeeMaster: EmployeeMaster[];
  allowBlankSearch: boolean = false;
  existingquery:string;
  statusValue: string = 'All';

  daysArray: any[] = [];
  dayOfWeekArray: any[] = [];
  salaryDetailNameList:any[];
  salaryDetailNameLists:any[];
  salaryDetailAdditionAmtList:any[];
  reviewPaySlipsGridList:any[];
  salaryDetailsInfo:any[];
  employee: any;
  
  
  
  
  
  EmployeeList = [];
  AttendanceList = [];
  SalaryList = [];
  FormTList = [
		{ name: "", leaveName: "" }
	]
  

  
  
  



  constructor(private employeeService: EmployeeAttendenceProxyService,private empLeaveService: EmployeeLeaveService,
    private employeeMasterservice: EmployeeMasterservice,private documentInfoService: DocumentInfoService) { 
    const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth() + 1;
		this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
		const lastDay = new Date(year, month, 0).getDate();
		this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
  }

  generateDaysArray(): void {
    const startDate = new Date(this.fromDate);
    const endDate = new Date(this.toDate);
    const daysArray: any[] = [];
    const dayOfWeekArray: any[] = []; // Array for day of the week characters

    // Create a copy of the start date to avoid modifying it
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      // Get the day of the month as a string and push it to the array
      const dayOfMonth = currentDate.getDate().toString().padStart(2, '0');
      daysArray.push(dayOfMonth);

      // Get the day of the week as a number (0 = Sunday, 1 = Monday, etc.)
      const dayOfWeek = currentDate.getDay();

      // Convert the day of the week number to its one-character representation
      const dayOfWeekChar = ['S', 'M', 'T', 'W', 'T', 'F', 'S'][dayOfWeek];
      dayOfWeekArray.push(dayOfWeekChar);

      // Increment the current date by one day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.daysArray = daysArray;
    this.dayOfWeekArray = dayOfWeekArray; // Store day of the week characters
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
      }
    );
  }

  employeeMap: Map<string, any> = new Map<string, EmployeeMaster>();
  employeeArray: Object[] = [];
  employeesummary(employeeMasterList, masterRoleBeanList, reviewPaySlipsGridList,attendanceDetailsList) {
    for (const employee of employeeMasterList) {
      // Check if the employee is not already in the map
      if (!this.employeeMap.has(employee.uniqueemployeeid)) {
        let obj = {
          uniqueemployeeid: employee.uniqueemployeeid,
          name: employee.name,
          fathersname: employee.fathersname,
          husbandsname: employee.husbandsname,
          gender: employee.gender,
          designationName: employee.designationName,
          departmentname: employee.departmentname,
          joiningdate: employee.joiningdate,
          esino: employee.esino,
          pfno: employee.pfno,
          bankaccount: employee.bankaccount,
          joiningDate: null, // Initialize properties for masterRoleBean
          salaryAmount:null,
          addSalaryAmount:null,
          deductSalaryAmount :null,
          weeklyHolidays : null,
          daysPresent : null,
          daysAbsent : null,
          otHours : null,
          daysInMonth :null,
          payableDays :null,
          leaveTaken :null,
          attendanceList: [],
          salaryDetailsInfo:[]
        };
  
        // Find and update masterRoleBean properties
        const matchingRole = masterRoleBeanList.find(role => role.uniqueemployeeid === employee.uniqueemployeeid);
        if (matchingRole) {
          obj.joiningDate = matchingRole.joiningDate;
          obj.attendanceList = matchingRole.attendanceList;
        }
  
        // Find and update salaryHeadMaterList properties
        const matchingSalary = reviewPaySlipsGridList.find(salary => salary.uniqueEmployeeId  === employee.uniqueemployeeid);
        if (matchingSalary ) {
          obj.salaryAmount=matchingSalary.salaryAmount;
          obj.addSalaryAmount=matchingSalary.addSalaryAmount;
          obj.deductSalaryAmount=matchingSalary.deductSalaryAmount;
          obj.salaryDetailsInfo = matchingSalary.salaryDetailsInfo;
          
        }

        const matchingAttendance = attendanceDetailsList.find(attendance => attendance.empId  === employee.uniqueemployeeid);
        if (matchingAttendance) {
          obj.weeklyHolidays=matchingAttendance.weeklyHolidays;
          obj.daysPresent=matchingAttendance.daysPresent;
          obj.daysAbsent=matchingAttendance.daysAbsent;
          obj.otHours=matchingAttendance.otHours;
          obj.daysInMonth=matchingAttendance.daysInMonth;
          obj.payableDays=matchingAttendance.payableDays;
          obj.leaveTaken=matchingAttendance.leaveTaken;

          
          
          
        }

        // Set the employee in the map
        this.employeeMap.set(employee.uniqueemployeeid, obj);
      }
    }
    this.employeeArray = Array.from(this.employeeMap.values());
  
    // console.log(this.employeeMap, "employeeMasterList", "masterRoleBeanList", "salaryHeadMaterList");
    console.log("Employee Array",this.employeeArray);


  }

  getsalarydetailname(){

  this.employeeMasterservice.getsalarydetailnameList().subscribe(
      data => {
        this.salaryDetailNameList = data;
        console.log("List of Addition Salary Details available:", data)
      }, error => {
        console.log(error);
      }
    );
  }

 
  

    getdecuctsalarydetailname(){

      this.employeeMasterservice.getdeductsalarydetailnameList().subscribe(
          data => {
            this.salaryDetailNameLists = data;
            console.log("List of Deduction Salary Details available:", data)
          }, error => {
            console.log(error);
          }
        );
      }

       map: Map<string, any> = new Map<string, any>();

      getSalaryAmount(uniqueEmployeeId: string, salaryDetailName: string) {
        let empObj = this.employeeMap.get(uniqueEmployeeId);
        let amount = 0;
      
        if (empObj && empObj.salaryDetailsInfo) {
          const matchingSalaryDetail = empObj.salaryDetailsInfo.find(
            salaryDetail =>
              salaryDetail.salaryDetailName.toLowerCase() === salaryDetailName.toLowerCase()
          );
      
          if (matchingSalaryDetail) {
            amount = matchingSalaryDetail.salaryComponentAmount;
        
          }
        }
      
        console.log('Unique Employee ID:', uniqueEmployeeId);
        console.log(`Salary Amount for ${salaryDetailName}:`, amount, empObj, this.employeeMap, uniqueEmployeeId);
        console.warn(empObj, 'empobj');
        return amount;
      } 
      
      sumOfAdditionAmounts(uniqueEmployeeId: string): number {
        let totalAmount = 0;
        
        // Iterate through the salaryDetailNameList to sum the amounts
        for (let salaryDetail of this.salaryDetailNameList) {
            totalAmount += this.getSalaryAmount(uniqueEmployeeId, salaryDetail.salaryDetailName);
        }
        
        return totalAmount;
    } 
    sumOfDeductAmounts(uniqueEmployeeId: string): number {
      let totalAmount = 0;
      
      // Iterate through the salaryDetailNameLists to sum the amounts
      for (let salaryDetail of this.salaryDetailNameLists) {
          totalAmount += this.getSalaryAmount(uniqueEmployeeId, salaryDetail.salaryDetailName);
      }
      
      return totalAmount;
  } 
       
searchEmployeeformT() {
    this.employeeMap.clear(); 
    this.generateDaysArray();
    console.log("fromDate: ", this.fromDate, "toDate: ", this.toDate, "page limit para :", this.limitPara, "page limit offset :", this.offsetPara);
    console.log(this.statusValue);
  
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
    }
  
    // Reset offset to 0 when performing a new search
    this.offsetPara = 0;
    this.employeeMasterservice.getEmployeesformTReport(employeename, this.fromDate, this.toDate, this.limitPara, this.offsetPara, this.branch).subscribe(
      data => {
        console.log("Received data:", data);
        const employeeMasterList = data.employeeConsolidate.employeeMasterList || [];
        const masterRoleBeanList = data.employeeConsolidate.masterRoleBeanList || [];
        const reviewPaySlipsGridList = data.employeeConsolidate.reviewPaySlipsGridList || [];
        const attendanceDetailsList = data.employeeConsolidate.attendanceDetailsList || [];
  
        this.employeesummary(employeeMasterList, masterRoleBeanList, reviewPaySlipsGridList,attendanceDetailsList);
  
        // Filter EmployeeList based on the search query (employeename)
        this.EmployeeList = employeeMasterList
          .filter(employee => employee.name?.toLowerCase()?.includes(employeename?.toLowerCase()))
          .map(employee => {
            const matchingRole = masterRoleBeanList.find(role => role.uniqueemployeeid === employee.uniqueemployeeid);
            const matchingSalary = reviewPaySlipsGridList.find(salary => salary.uniqueEmployeeId  === employee.uniqueemployeeid);
            const matchingAttendance = attendanceDetailsList.find(attendance => attendance.empId   === employee.uniqueemployeeid);
  
            return {
              ...employee,
              joiningDate: matchingRole ? matchingRole.joiningDate : null,
              salaryAmount: matchingSalary ? matchingSalary.salaryAmount : null,
              addSalaryAmount: matchingSalary ? matchingSalary.addSalaryAmount : null,
              deductSalaryAmount: matchingSalary ? matchingSalary.deductSalaryAmount : null,
              weeklyHolidays: matchingAttendance ? matchingAttendance.weeklyHolidays : null,
              daysPresent: matchingAttendance ? matchingAttendance.daysPresent : null,
              daysAbsent: matchingAttendance ? matchingAttendance.daysAbsent : null,
              otHours: matchingAttendance ? matchingAttendance.otHours : null,
              daysInMonth: matchingAttendance ? matchingAttendance.daysInMonth : null,
              payableDays: matchingAttendance ? matchingAttendance.payableDays : null,
              leaveTaken: matchingAttendance ? matchingAttendance.leaveTaken : null,

              attendanceCount: matchingRole ? matchingRole.attendanceList.length : null,
              salaryCount: matchingSalary ? matchingSalary.salaryDetailsInfo.length : null,
              
             
            };
          });
  
        console.log('Filtered Employee List:', this.EmployeeList);
      },
      error => {
        console.log("Error ", error);
        (<HTMLInputElement>document.getElementById('employeename')).value = "";
      }
    );
  }
  


  

  

  scrollLength() {
		console.log("dynamic height on scroll ");
		console.log("");
		const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
		const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
		const computedHeight = height.match(regex);
		console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
		if (computedHeight && Number(computedHeight[1]) < 450) {
			let value: any = Number(computedHeight[1]);
			console.log("default value = ", value)
			value = value + (2 * 55);
			value = value + "px";
			console.log("final vl:", value)
			console.log("transition")
			document.getElementById("scrolllength").style.maxHeight = value
		}
		console.log("");
	}



  onScroll() {
    console.log(this.limitPara,"limit");
    
		this.limitPara = this.limitPara + 13;
		console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
		this.searchEmployeeformT();

	}

  limitpara() {
		this.offsetPara = 0;
		console.log(" on clikc limit para ", this.limitPara)
		console.log("this offset", this.offsetPara)
	}

  @HostListener('scroll', ['$event'])
	scrolled() {

		console.log("Scrolled >>> ")
		this.onScroll();
	}

  getEmployeeByNameOrId() {
    console.log("Len ", this.query);
    // if (!isNaN(Number(this.query))) {
    if (this.query?.length <= 2) {
      console.log("number")
      // this.employeeMasterLucene = []
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
          this.employeeMasterLucene = data.employeeMasterData

        },
        error => {
          console.log("Error ", error);
        })
    }
    // }
    else {
      if (this.query?.length >= 3) {
        this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
          data => {
            console.log("Emp Data ", data);
            this.employeeMasterLucene = data.employeeMasterData

            // if(this.employeeMaster.length > 0){
            // 	const autoCompleteId = document.getElementById("employeeMaster");
            // 	autoCompleteId.classList.remove("auto-complete-active");
            // }
          },
          error => {
            console.log("Error ", error);

          }
        )
      }
    }
  }
  formtReportDownload(){
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    this.documentInfoService.formtReportDownload(employeename, this.fromDate, this.toDate, this.limitPara, this.offsetPara, this.branch).subscribe(
      data => {
        var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "Form_T_Report.xls";
      anchor.href = fileURL;
      anchor.click();
      //window.open(fileURL);
    },
      error => {
        console.log("Error Occured ", error);
        // console.log("Error Code ",error.status);
      })

  }


  ngOnInit(): void {
    this.getAllBranches();
    this.offsetPara = 0;
    this.getsalarydetailname();
    this.getdecuctsalarydetailname();
    this.limitPara = 13;
    
   
  }

}