import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { branches } from '../models/branches';
import { NgForm } from '@angular/forms';
declare var $:any
@Component({
  selector: 'app-branch-master',
  templateUrl: './branch-master.component.html',
  styleUrls: ['./branch-master.component.css']
})
export class BranchMasterComponent implements OnInit {

  branchName: String;
  branchId: number;
  branchList: any[] = [];
  addOrUpdate: boolean = true;
  discontinue: boolean;
  // isSaveEnabled: boolean = false;

  branchListUpdate: branches;


  constructor(private employeeMasterservice: EmployeeMasterservice) { }
  @ViewChild('branchform', { static: false }) branch: NgForm;

  ngOnInit(): void {
    this.getBranch();
    this.getleaveListwithcolorcode();
  }

  getBranch() {
    this.employeeMasterservice.getBranchList().subscribe(
      data => {
        this.branchList = data;
        console.log("List of all the branch available :", this.branchList)
      }, error => {
        console.log(error);
      }
    );

  }
  getleaveListwithcolorcode() {

    this.employeeMasterservice.getLeaveList().subscribe(
      data => {

        console.log("List of all the Leave List with colorcode available :", data);
      }, error => {
        console.log(error);
      }
    );
  }

  clear() {
    this.branchName = undefined;
    this.addOrUpdate = true;
    this.branch.reset();
  }

  addBranch() {
    this.branch.form.markAllAsTouched()
    if (this.branch.invalid) {
      return;
    }
    console.log("check first ",this.branchName)
    if(this.branchName===undefined || this.branchName.trim().length===0){
      this.alertmessages("Please enter a Branch Name ", "danger")
    } else{ 
    this.branchName = this.branchName.trim();
    console.log("Add Branch: ", this.branchName, " :  length : ", this.branchName.length)
    this.employeeMasterservice.insert_branch(this.branchName).subscribe(
      data => {
        console.log("added branch ", data)
        this.alertmessages("Successfully Saved ", "success")
        this.clear();
        this.getBranch();
      }, error => {
        console.log(error);
        this.alertmessages("Please try again later", "danger")
      }
    );
  }
  }
  // addBranch() {
  //   this.branch.form.markAllAsTouched();
  //   if (this.branch.invalid) {
  //     return;
  //   }
  //   this.branchName = this.branchName.trim();
  //   console.log("Ready to Save Branch: ", this.branchName);
  //   this.isSaveEnabled = true; 
  // }

  // saveBranch() {  // New function to handle final save
  //   if (!this.branchName || this.branchName.trim().length === 0) {
  //     this.alertmessages("Please enter a Branch Name", "danger");
  //     return;
  //   }

  //   this.employeeMasterservice.insert_branch(this.branchName).subscribe(
  //     data => {
  //       console.log("Branch saved: ", data);
  //       this.alertmessages("Successfully Saved", "success");
  //       this.clear();
  //       this.getBranch();
  //     },
  //     error => {
  //       console.log(error);
  //       this.alertmessages("Please try again later", "danger");
  //     }
  //   );
  // }

  inactiveEdit(id, name, discontinue) {
    console.log("inactive edit ");

    discontinue = !discontinue;

    this.branchName = name;
    this.branchId = id;
    this.discontinue = discontinue;

    if(this.discontinue){
      $('#inactiveModal').modal('show')
    }
    else {
     $('#activeModal').modal('show')
    }
  }
0

  editBranch(id, info, discontinue) {
    console.log("edit branch : ", info)
    this.branchName = info;
    this.branchId = id;
    this.discontinue = discontinue;
    this.addOrUpdate = false;
  }

  deleteBranch(id, name) {
    console.log("delete branch : ", id, " ", name)

    this.employeeMasterservice.deleteBranch(id).subscribe(
      data => {
        console.log("delete  branch ", data)
        this.alertmessages("Successfully deleted ", "success")
        this.clear();
        this.getBranch();
      }, error => {
        this.alertmessages("Branch is in Use ", "danger")
        console.log(error);
      }
    );

    this.clear();
  }

  updateBranch(branchId, branchName, discontinue) {

    branchName = this.branchName.trim();
    console.log("After trim Branch: ", this.branchName, " :  length : ", this.branchName.length);

    if (this.branchName != undefined && this.branchName.length > 0) {
      const updatedField = {
        branchId: branchId,
        name: branchName,
        discontinue: discontinue
      };

      this.employeeMasterservice.editUpdate_branch(updatedField).subscribe(
        data => {
          console.log("added branch ", data)
          this.alertmessages("Successfully Updated ", "success")
          this.clear();
          this.getBranch();
        }, error => {
          this.alertmessages("Please try again later", "danger")
          console.log(error);
        }
      );

      this.clear();
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

}
