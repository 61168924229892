import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PfReportService } from '../services/pf-report.service';

@Component({
  selector: 'app-pf-statement-report',
  templateUrl: './pf-statement-report.component.html',
  styleUrls: ['./pf-statement-report.component.css']
})
export class PfStatementReportComponent implements OnInit {

  constructor(private pfStatementService: PfReportService) { }

  ngOnInit(): void {

    this.limitPara = 5;
    this.offset = 0;
    const currentMonthValue = new Date().getMonth() + 1; // getMonth() returns 0 for January, so +1
    this.selectedMonth = this.months.find(month => month.value === currentMonthValue);

    const currentYear = new Date().getFullYear();
    // this.years.push(''); // Add empty option
    for (let year = currentYear; year >= 2018; year--) {
      this.years.push(year.toString());
    }
    this.year = currentYear.toString();
    // this.getEmployeeList();
  }
  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  selectedMonth: { name: string, value: number } | undefined;
  years: string[] = [];
  directoryPath: string = '';
  year: any;
  selecteduniqueeName: any;
  selecteduniqueeEmpId: any;
  employeeList: any[] = [];
  query: any;
  queryName: any;
  limitPara: any;
  offset: any;
  reportData: any[] = [];


  executeReport() {
    this.reportData = [];
    console.log('year', this.year);
    console.log('month', this.selectedMonth);
    console.log('month', this.query);

    if (!this.year) {
        console.log('year', this.year);
        return;
    }

    if (!this.selectedMonth || !this.selectedMonth.name || !this.selectedMonth.value) {
        console.log('month', this.selectedMonth);
        return;
    }

    this.pfStatementService.getPfstatementReport(this.year, this.selectedMonth.value.toString(), this.selectedMonth.name.toString(), this.limitPara, this.offset)
        .subscribe(
            data => {
                if (data) {
                   
                        if (this.limitPara === 0) {
                            console.log('Report data received:', data.statutoryReportBean);
                            this.reportData = data.statutoryReportBean;
                        } else {
                            this.reportData = this.reportData.concat(data.statutoryReportBean); 
                        }
                    
                        // If there are no records, we assume an error message was sent in the response
                        this.alertmessages(data.errorMessages[0] || 'No data available for the selected year and month.', 'danger');
                    
                } else {
                    this.alertmessages('No data available for the selected year and month.', 'danger');
                }
            },
            (error) => {
                if (error.status === 404) {
                    this.alertmessages('No data available for the selected year and month.', 'danger');
                } else {
                    this.alertmessages('Error occurred while displaying the report.', 'danger');
                }
                console.log("Error Occurred ", error);
            }
        );
}


  onScroll() {
    this.offset = this.offset + this.limitPara;
    this.executeReport();
  }
  @HostListener('scroll', ['$event'])
  scrolled() {
    this.onScroll();
  }
  limitpara() {
    this.limitPara = 0;
  }

  

 
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
