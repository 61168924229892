import { Injectable } from '@angular/core';
//import { Observable } from 'rxjs';
import { Observable } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CommonService } from './common.service';


@Injectable({
    providedIn: 'root'
})
export class DocumentInfoService {
    protected basePath = '/api';
    public defaultHeaders: Headers = new Headers();
    public url;

    constructor(private formBuilder: FormBuilder, private httpclient: HttpClient, private commonService: CommonService) { }

    public getDocument(doctypeName: any): Observable<any> {
        this.url = '/v1/documents';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('doctypeName', doctypeName);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    }

    public getDocumentX(): Observable<any> {
        this.url = '/v1/documentsX';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        // queryParameters = queryParameters.set('doctypeName', doctypeName);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    }

    public saveDocumentAuthority(info: any): Observable<any> {
        console.log("save document authority............ ", info)
        this.url = '/v1/saveDocumentAuthority';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        return this.commonService.postWithHttpInfo(info, queryParameters, path);
      }


    public saveDocument(documentInfo: any): Observable<any> {
        this.url = '/v1/document';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        console.log("Saved Document is ", documentInfo);
        return this.commonService.postWithHttpInfo(documentInfo, queryParameters, path);

    }

    public deleteDocument(docId: any): Observable<any> {
        this.url = '/v1/document';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        console.log("Document Id is ", docId);
        queryParameters = queryParameters.set('docId', docId);
        return this.commonService.deleteQueryParamWithHttpInfo(queryParameters, path);
    }

    public updateDocument(documentInfo: any): Observable<any> {
        this.url = '/v1/document';
        const path = this.basePath + this.url;

        let queryParameters = new HttpParams();

        console.log("Update Document is ", documentInfo);
        return this.commonService.putWithHttpInfo(documentInfo, queryParameters, path);


    }




    public listMyDocument(): Observable<any> {

        this.url = '/v1/employee/documents';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();

        //return of(data);

        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);

    }


    public deleteMyDocument(docId: any): Observable<any> {
        this.url = '/v1/employee/document';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        console.log("Document Id is ", docId);
        queryParameters = queryParameters.set('docId', docId);
        return this.commonService.deleteQueryParamWithHttpInfo(queryParameters, path);
    }

    public uploadDocument(myDocuments: any, docToUpload: any): Observable<any> {
        this.url = '/v1/employee/document';
        const path = this.basePath + this.url;



        const formData: FormData = new FormData();
        formData.append('myDoc', JSON.stringify(myDocuments));
        formData.append('file', docToUpload);

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        //headers.append( 'Accept', 'multipart/form-data' );

        const httpOptions = { headers: headers };
        return this.httpclient.post(path, formData, httpOptions);

    }

    public downloadMyDocument(docId: any): Observable<any> {
        //this.url = '/v1/mydocuments';
        this.url = '/v1/employee/documents';
        const path = this.basePath + this.url;

        let queryParameters = new HttpParams();
        console.log(" Document Id is", docId);

        queryParameters = queryParameters.set('docId', docId);
        return this.httpclient.get(this.url + '/GetFile?docFileId=' + docId, { responseType: 'blob' });

    }



    public downloadFile(filePath: string): Observable<any> {
        //this.url = 'https://localhost:4200/';
        this.url = '/v1/downloadEmpDoc';
        const path = this.basePath + this.url;
        console.log("File Path is", filePath);
        const param = new HttpParams().set('filePath', filePath);
        const options = {
            params: param
        };
        //Freturn this.httpclient.get(this.url,{ ...options, responseType: 'blob'});
        return this.httpclient.get(path, { ...options, responseType: 'blob' });
    }



    public uploadRulesAndPolicy(documentName: any, docToUpload: any): Observable<any> {
        this.url = '/v1/employer/uploadRulesPolicy';
        const path = this.basePath + this.url;
        const formData: FormData = new FormData();
        formData.append('docName', JSON.stringify(documentName));
        formData.append('file', docToUpload);

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');

        const httpOptions = { headers: headers };
        return this.httpclient.post(path, formData, httpOptions);

    }


    public downloadRulesAndPolicy(filePath: string): Observable<any> {
        this.url = '/v1/employee/downloadRulesPolicy';
        const path = this.basePath + this.url;
        const param = new HttpParams().set('filePath', filePath);
        const options = {
            params: param
        };
        return this.httpclient.get(path, { ...options, responseType: 'blob' });
    }

    /*
    public getRulesAndPolicy(fileType :any): Observable<any> {
        this.url = '/v1/employee/getRulesPolicy';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('fileType', fileType);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    }
    */

    public getRulesAndPolicy(): Observable<any> {
        this.url = '/v1/employee/getRulesPolicy';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        var fileType = '';
        queryParameters = queryParameters.set('fileType', fileType);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    }

    public deleteRulesAndPolicy(policyId: any): Observable<any> {
        this.url = '/v1/employee/deleterulesPolicy';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        console.log("Policy Id is ", policyId);
        queryParameters = queryParameters.set('policyId', policyId);
        return this.commonService.deleteQueryParamWithHttpInfo(queryParameters, path);
    }


    /*
          
        public downloadFile(fileName:any): Observable<Blob> {
       
            this.url = '/v1/downloadEmpDoc';
            
            let queryParameters = new URLSearchParams();
            queryParameters.set('fileName', fileName);
              
              let headers = new Headers( this.defaultHeaders.toJSON() );
              //let headers = new Headers();
              //headers.append('Content-Type', 'application/json');
              //headers.set( 'Content-Type', 'application/json' );
              //headers.set( 'Accept', 'application/json' );
              let requestOptions: RequestOptionsArgs = new RequestOptions({
                  responseType: ResponseContentType.Blob,
                  method: RequestMethod.Get,
                  headers: headers,
                  search: queryParameters
              });
    
              //return this.httpclient.get(this.basePath+this.url , {...requestOptions, responseType: 'blob'});
              //return this.httpclient.get(this.url,{ ...options, responseType: 'blob'});
              
              return this.httpclient.request( this.basePath+this.url, requestOptions )
           .map(res => res.blob());
    
           
           
         }
         */

    public uploadMultipleDocument(docToUpload: any): Observable<any> {
        this.url = '/v1/muliDocument';
        const path = this.basePath + this.url;
        var formData: any = new FormData();
        if (docToUpload) {
            let f: File | null = null;
            for (let i = 0; i < docToUpload.length; i++) {
                f = docToUpload[i];
                formData.append('file', f);
            }
        }

        //const formData: FormData = new FormData();

        //formData.append('file', docToUpload);

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        //headers.append( 'Accept', 'multipart/form-data' );

        const httpOptions = { headers: headers };
        return this.httpclient.post(path, formData, httpOptions);

    }


    public uploadExcelDocument(excelDocToUpload: any): Observable<any> {
        this.url = '/v1/fileupload';
        const path = this.basePath + this.url;
        var formData: any = new FormData();


        //const formData: FormData = new FormData();

        formData.append('uploadedFile', excelDocToUpload);

        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        //headers.append( 'Accept', 'multipart/form-data' );

        const httpOptions = { headers: headers };
        return this.httpclient.post(path, formData, httpOptions);

    }

    public downloadEmployeeMatserCSVFile(employeeid: any, employeeName: any, limitPara: any, offsetPara: any): Observable<any> {
        this.url = '/v1/downloadEmployeeMatser';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        if (employeeid !== null) {
            queryParameters = queryParameters.set('employeeId', employeeid);
        }
        if (employeeName !== null) {
            queryParameters = queryParameters.set('employeeName', employeeName);
        }
        queryParameters = queryParameters.set('limitPara', limitPara);
        queryParameters = queryParameters.set('offsetPara', offsetPara);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
    }

    public downloadProjectReportCSVFile(): Observable<any> {
        this.url = '/v1/downloadProjectReport';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
    }

    public downloadAttendanceReportCSVFile(reportType: string, uempid: string, fromdate: string, todate: string, currentDate: string, activeExitFilters: string, branch: string, listingFilters: string): Observable<any> {
        this.url = '/v1/downloadAttendanceReport';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('reportType', reportType);
        queryParameters = queryParameters.set('uempid', uempid);
        queryParameters = queryParameters.set('fromdate', fromdate);
        queryParameters = queryParameters.set('todate', todate);
        queryParameters = queryParameters.set('currentDate', currentDate);
        queryParameters = queryParameters.set('activeExitFilters', activeExitFilters);
        queryParameters = queryParameters.set('branch', branch);
        queryParameters = queryParameters.set('listingFilters', listingFilters);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
    }
    public formtReportDownload(employeeName: any, fromDate: any, toDate: any, limitPara: any, offsetPara: any, branch: number): Observable<any> {
        this.url = '/v1/downloadformtReport';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        // queryParameters = queryParameters.set('limitPara', offsetPara);
        // queryParameters = queryParameters.set('offsetPara', limitPara);
        queryParameters = queryParameters.set('employeeName', employeeName);
        queryParameters = queryParameters.set('fromDate', fromDate);
        queryParameters = queryParameters.set('toDate', toDate);
        queryParameters = queryParameters.set('branch', branch);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
    }

}


