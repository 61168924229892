import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { Groupinfo } from '../models/Groupinfo';

@Component({
  selector: 'app-group-master',
  templateUrl: './group-master.component.html',
  styleUrls: ['./group-master.component.css']
})
export class GroupMasterComponent implements OnInit {
  groupName: String;
  groupId: number;
  groupList: any[] = [];
  addOrUpdate: boolean = true;
  isSaveEnabled: boolean = false;
  groupListUpdate: Groupinfo;
  pendingGroups: any;
  constructor(private employeeMasterservice: EmployeeMasterservice) { }
  @ViewChild('groupform', { static: false }) groups: NgForm;

  ngOnInit(): void {
    this.getgroup();
  }

  getgroup() {
    this.employeeMasterservice.getgroupList().subscribe(
      data => {
        this.groupList = data;
        console.log("List of all the group available :", this.groupList)
      }, error => {
        console.log(error);
      }
    );

  }

  addGroup() {
    this.groups.form.markAllAsTouched()
    if (this.groups.invalid) {
      // Handle invalid form submission
      return;
    }
    this.groupName = this.groupName.trim();
    console.log("Add Group: ", this.groupName);
    this.isSaveEnabled = true; // Enable the Save button
  }

  saveGroups() {
    if (this.pendingGroups.length === 0) {
      this.alertmessages("No groups to save", "warning");
      return;
    }

    for (const group of this.pendingGroups) {
      this.employeeMasterservice.insert_group(group.name).subscribe(
        data => {
          console.log("Saved group:", data);
          this.alertmessages("Successfully Saved", "success");
          this.getgroup(); // Refresh the group list
        },
        error => {
          console.log(error);
          this.alertmessages("Please try again later", "danger");
        }
      );
    }

  }

  editGroup(id, info) {
    console.log("edit group : ", info)
    this.groupName = info;
    this.groupId = id;

    this.addOrUpdate = false;
  }

  deleteGroup(id, name) {
    console.log("delete group : ", id, " ", name)

    this.employeeMasterservice.deleteGroup(id).subscribe(
      data => {
        console.log("delete group ", data)
        this.alertmessages("Successfully deleted ", "success")
        this.clear();
        this.getgroup();
      }, error => {
        this.alertmessages("Detete not allowed, group is in use", "danger")
        console.log(error);
      }
    );

    this.clear();
  }

  updateGroup() {
    this.groupName = this.groupName.trim();
    console.log("After trim Group: ", this.groupName, " :  length : ", this.groupName.length)
    if (this.groupName != undefined && this.groupName.length > 0) {

      const updatedField = {
        id: this.groupId,
        name: this.groupName
      };

      this.employeeMasterservice.editUpdate_group(updatedField).subscribe(
        data => {
          console.log("added group ", data)
          this.alertmessages("Successfully Updated ", "success")
          this.clear();
          this.getgroup();

        }, error => {
          this.alertmessages("Update not allowed, group is in use", "danger")

          console.log(error);
        }
      );

      this.clear();
    }

  }


  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
  clear() {
    this.groupName = undefined;
    this.addOrUpdate = true;
    this.groups.reset();
  }

}
