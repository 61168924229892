<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-titleMod">Extra Time Out Report</div>
            </div>
        </div>
      
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate" (change)="validateDates()">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate" (change)="validateDates()">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control"
                                                    (change)="onStatusChange($event.target.value)"
                                                    name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Extra Time Out Approve Pending">Extra Time Out Approve Pending</option>
                                                    <option value="Extra Time Out Cancel Pending">Extra Time Out Cancel Pending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12 mt-3" style="padding: 0px 0px">
                                    <form class="form-horizontal" id="fcForm"
                                        (keyup.enter)="limitpara(); getFilteredETOSummary()">
                                        <div class="form-group row">
                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                <div class="form-group label-floating">
                                                    <label for="employeename" class="form-label">Employee Name / ID
                                                    </label>
                                                    <div class="employeeSearch col-xs-12 col-md-12 px-0">
                                                        <div>
                                                            <input type="search" class="form-control"
                                                                name="employeename" id="employeename"
                                                                [(ngModel)]="query" autocomplete="off"
                                                                (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                                autofocus="autofocus"
                                                                placeholder="Search Employee Name / ID">
                                                            <datalist id="employeeMaster">
                                                                <option [value]="emp.name + ' / ' + emp.employeeid"
                                                                    *ngFor="let emp of employeeMaster">
                                                                    <!-- {{emp.employeeid}} uniqueemployeeid-->
                                                                </option>
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Order By -->
                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                <div class="form-group label-floating">
                                                    <label class="form-label">Order By</label>
                                                    <div>
                                                        <select class="form-control" [(ngModel)]="orderBy"
                                                            (change)="sortTableData()">
                                                            <option value="employeeId">Employee ID</option>
                                                            <option value="employeeName">Employee Name</option>
                                                            <option value="date">Applied Date</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Search Button -->
                                            <div class="col-md-4 col-sm-4 col-xs-4 mt-5">
                                                <button type="button" id="navigateclient" class="btn btn-primary w-100"
                                                    (click)="limitpara(); getFilteredETOSummary()">
                                                    <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" style="height: 10px;" id="add-update-client"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">{{title}}</h4>
                        <button type="button" class="btn btn-primary ms-auto"
                    (click)="extraTimeOutReportCSVDownload(employeesETOSummary)">
                    <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                </button>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <div *ngIf="employeesETOSummary !== null ">
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">
                                                <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                                <th class="border-bottom-0 w-5 secondcolumn">Name</th>
                                                <th class="border-bottom-0">Applied date</th>
                                                <th class="border-bottom-0">From Time</th>
                                                <th class="border-bottom-0">To Time</th>
                                                <th class="border-bottom-0">Total Time</th>
                                                <th class="border-bottom-0">Reason</th>
                                                <!-- <th class="border-bottom-0">Applied on</th> -->
                                                <th class="border-bottom-0">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let eto of employeesETOSummary">
                                                <td class="fixedcolumn backgroundFW">{{eto.employeeId}}</td>
                                                <td class="secondcolumn backgroundSW">
                                                    <div class="d-flex">
                                                        <div class="me-3 mt-0 mt-sm-2 d-block">
                                                            <h6 class="mb-1 fs-14">{{eto.employeename}}</h6>
                                                        </div>
                                                    </div>
                                                </td>

                                                <td>{{eto.date | date}}</td>
                                                <td>{{convertTime(eto.fromTime) }}</td>
                                                <td>{{convertTime(eto.toTime) }}</td>
                                                <td>{{eto.totalMinutes.slice(0,5) }}</td>
                                                <td>{{eto.reason}}</td>
                                                <!-- <td>{{eto.createdDate | date}}</td> -->
                                                <td>
                                                    <span style="font-size: 13px;font-weight: 500;"
                                                        [ngClass]="eto.status === 'Approved' ? 'badge-success' : eto.status === 'Rejected' ? 'badge-danger' : eto.status === 'Extra Time Out Cancel Rejected' ? 'badge-danger':eto.status === 'Extra Time Out Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                        class="badge">{{eto.status}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>