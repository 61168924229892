import { Injectable } from "@angular/core";
import { CommonService } from "./common.service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { configSetting } from "../models/configSetting";
import { LeaveMaster } from "../models/leaveMaster";
import { mandatory } from "../models/mandatory";
import { Configkey } from "../models/Configkey";
import { AdminConfig } from "../models/AdminConfig";
import { ExpenseSubmission } from "../models/ExpenseSubmission";
import { savingsmaster } from "../models/savingsmaster";

@Injectable({
  providedIn: 'root'
})
export class ExpensemanagerService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  public saveExpenseSubmission(file: File, expenseSubmission: ExpenseSubmission): Observable<any> {
    console.log("value sent ExpenseSubmission", expenseSubmission);
    this.url = '/v1/addExpense';
    const path = this.basePath + this.url;
    const formData: FormData = new FormData();
    
    formData.append('file', file);
  
    // Convert the JSON data to a Blob and append it to the FormData
    const jsonBlob = new Blob([JSON.stringify(expenseSubmission)], { type: 'application/json' });
    formData.append('expenseSubmission', jsonBlob, 'expenseSubmission.json');
  
    // Use HttpHeaders correctly
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
  
    // Use set instead of append for HttpHeaders
    const httpOptions = { headers: headers };
  
    // Use proper type for the return value (Observable<HttpResponse<any>>)
    return this.httpclient.post<any>(path, formData, httpOptions);
  }

  public getExpenseList(user_id: string): Observable<any> {
    this.url = '/v1/expenseSubmissionDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('user_id', user_id)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getApproveList(uid: any): Observable<any> {
    this.url = '/v1/expenseApproval';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueEmpid', uid)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public deleteExpenseSub(id: any): Observable<any> {
    console.log("delete id  : ", id);
    this.url = '/v1/deleteExpenseSubmssion';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id', id);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  // public viewdocument(filePath: string): Observable<any> {
  //   this.url = '/v1/viewRequesteddocuments';
  //   const path = this.basePath + this.url;
  //   console.log("File Path is", filePath);
  //   const param = new HttpParams().set('filePath', filePath);
  //   const options = {
  //       params: param
  //   };
  //   return this.httpclient.get(path, { ...options, responseType: 'blob' });
  // }

  public downloadFile(filePath: string): Observable<any> {
    this.url = '/v1/downloadExpenseSubmssion';
    const path = this.basePath + this.url;
    console.log("File Path is", filePath);
    const param = new HttpParams().set('filePath', filePath);
    const options = {
        params: param
    };
    return this.httpclient.get(path, { ...options, responseType: 'blob' });
  }

  public getExpenseTypeList(): Observable<any> {
    this.url = '/v1/expenseTypeDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }
  
  public viewdocument(filepath:string): Observable<any> {
    this.url = '/v1/viewRequesteddocuments';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('filepath', filepath);
    console.log(queryParameters);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path, {responseType: 'arraybuffer'});
    // console.log("Here you can see the rsponse path: " + path)
  }

  public insert_expenseType(info: String): Observable<any> {
    console.log("value sent ", info);
    this.url = '/v1/addExpenseType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }
  
  public delete_expenseType(info: any): Observable<any> {
    console.log("delete id  : ", info);
    this.url = '/v1/delete_expenseType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }

  public editUpdate_expenseType(info: any): Observable<any> {
    console.log("value sent edit update expense type is : ", info);
    this.url = '/v1/editUpdate_ExpenseType';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(info, queryParameters, path);
  }

  public approveList(expenseApproval: any,uid: any): Observable<any> {
    console.log("value sent ", expenseApproval);
    this.url = '/v1/expenseApprovalList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueEmpid', uid);
    return this.commonService.postWithHttpInfo(expenseApproval, queryParameters, path);
  }

  public rejectList(expenseApproval: any): Observable<any> {
    console.log("value sent ", expenseApproval);
    this.url = '/v1/expenseRejectList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(expenseApproval, queryParameters, path);
  }

  public getApprovedDetails(expid:any): Observable<any> {
    this.url = '/v1/expenseApprovalDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('expid', expid);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getExpenseReport(fromDate:any,toDate:any,designation:any,
    department:any,id: number): Observable<any> {
      this.url = '/v1/searchExpenseReport';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      queryParameters = queryParameters.set('fromDate', fromDate);
      queryParameters = queryParameters.set('toDate', toDate);
      queryParameters = queryParameters.set('branch', designation);
      queryParameters = queryParameters.set('department', department);
      queryParameters = queryParameters.set('uempid', id);
      return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
    }

    public saveSavingMaster(savingsMaster: savingsmaster): Observable<any> {
      this.url = '/v1/save_savingsmaster';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      console.log("save savingsMaster on submit ")
      return this.commonService.postWithHttpInfo(savingsMaster, queryParameters, path);
    }
    public getSavingsmasterDetails(): Observable<any> {
      this.url = '/v1/getSavingsmasterDetails';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
    }
    
    public edit_savingsmaster(info: any): Observable<any> {
      console.log("value sent edit update expense type is : ", info);
      this.url = '/v1/edit_savingsmaster';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      return this.commonService.postWithHttpInfo(info, queryParameters, path);
    }

    public delete_savingsmaster(info: any): Observable<any> {
      console.log("delete id  : ", info);
      this.url = '/v1/delete_savingsmaster';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      return this.commonService.postWithHttpInfo(info, queryParameters, path);
    }
}
