import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class PfDownloadService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }



  public downloadPFReport(year:any,monthValue:any,monthName: any,fileType:any): Observable<any> {
    this.url = '/v1/downloadpfReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('year', year);
        queryParameters = queryParameters.set('monthValue', monthValue);
        queryParameters = queryParameters.set('monthName', monthName);
        queryParameters = queryParameters.set('fileType', fileType);
       // queryParameters = queryParameters.set('directoryPath', directoryPath);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

}
