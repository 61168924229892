import { Component, OnInit } from '@angular/core';
import { ExtraTimeOutWorkflow } from '../models/extraTimeOutWorkflow';
import { Extratimeoutservices } from '../services/extratimeoutservices';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { TaskDetailsForExtraTimeOut } from '../models/task-deatais-for-extraTimeOut';
import { ApproveLeavePipe } from '../pipes/approve-leave.pipe';
import { ApproveExtraTimeOutPipe } from '../pipes/approve-extra-time-out.pipe';
declare var $: any;
@Component({
  selector: 'app-approve-extra-timeout',
  templateUrl: './approve-extra-timeout.component.html',
  styleUrls: ['./approve-extra-timeout.component.css']
})
export class ApproveExtraTimeoutComponent implements OnInit {
  private APPROVED: string = "APPROVED";
  private REJECTED: string = "REJECTED";
  private LEAVE_APPROVE_PENDING: string = "Leave Approve Pending"
  private LEAVE_CANCEL_PENDING: string = "Leave Cancel Pending"
  private LEAVE_CANCEL_APPROVED: string = "Leave Cancel Approved"
  private LEAVE_CANCEL_REJECTED: string = "Leave Cancel Rejected"

  selectedList: any[] = [];
  selectAll: boolean = false;

  taskDetailsForLeave: TaskDetailsForExtraTimeOut[] = [];
  approvalLeaves: TaskDetailsForExtraTimeOut[] = [];
 
  searchleave: any;
  //extraTimeOutFlow: ExtraTimeOutWorkflow[] = []
  //tempextraTimeOutFlow: ExtraTimeOutWorkflow[]
  //leavesApproval: ExtraTimeOutWorkflow[] = []
  tempextraTimeOutDetailFlow: ExtraTimeOutWorkflow[] = []
  extraTimeOutApproval: ExtraTimeOutWorkflow[] = [];
  uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
  extraTimeOutDetailFlow: ExtraTimeOutWorkflow[] = []
  statusFilter: any = 'all';
  constructor(private extraTimeOutService: Extratimeoutservices,private approve: ApproveExtraTimeOutPipe,private titleCase: TitleCasePipe,private datePipe: DatePipe) { }

  ngOnInit(): void {
    //this.getExtraTimeOut();
    if (localStorage.getItem('navigateDashboard') == 'true') {
      this.search();
      localStorage.setItem('navigateDashboard', 'false');
    }
    
  }
  search() {
    this.getExtraTimeOut();
  }
  showTable = false
  getExtraTimeOut() {
    
    this.extraTimeOutDetailFlow = [];
    this.tempextraTimeOutDetailFlow = [];
    this.extraTimeOutService.getExtraTimeOutlist().subscribe(
      data => {
        console.log("get Leave for Approval ");
        if (this.statusFilter == 'all') {
          console.log("this.statusFilter ", this.statusFilter);
          this.extraTimeOutDetailFlow = data.extraTimeOutApplied;
          console.log("extra time out",data);
          this.extraTimeOutDetailFlow.forEach(item => {
            item.status = this.titleCase.transform(item.status);
          })
          this.tempextraTimeOutDetailFlow = this.extraTimeOutDetailFlow;
          console.log("extra time out bean",this.extraTimeOutDetailFlow);
        } else {
          console.log("this.statusFilter ", this.statusFilter);
          this.extraTimeOutDetailFlow = data.extraTimeOutApplied;
          this.extraTimeOutDetailFlow.forEach(item => {
            item.status = this.titleCase.transform(item.status);
          })
          this.extraTimeOutDetailFlow = this.extraTimeOutDetailFlow.filter(
            ele => ele.status == this.statusFilter
          );
          this.extraTimeOutDetailFlow = this.extraTimeOutDetailFlow;
        }
        if (this.extraTimeOutDetailFlow.length > 0) {
          this.showTable = true
        }
        console.log("Extra Time Out response ", this.extraTimeOutDetailFlow);
        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },
      error => {
        console.error("Error while getting Leave For Approval ", error);
      }
    )
  }


  checkAllSelected(e: any) { 
    console.log("checkAllSelected ");

    const checkboxes = document.querySelectorAll('input');
    if (e.target.checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = true;
        }
      }
      this.extraTimeOutApproval.splice(0, this.extraTimeOutApproval.length)
      console.log(" select all ", this.extraTimeOutApproval);

      if (this.searchleave) {
        this.searchLeaves.forEach(task => {
          this.extraTimeOutApproval.push()
        });
      } else {
        this.extraTimeOutDetailFlow.forEach(task => {
          this.extraTimeOutApproval.push(task)
        });
      }
      console.log(" check  all ", this.extraTimeOutApproval.length);
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
      this.extraTimeOutApproval.splice(0, this.extraTimeOutApproval.length);
      console.log(" end  ", this.extraTimeOutApproval.length);
    }
    


  }

  checkSelected(extra: any, e: any) {
    console.log(" extra time out check selected ");

    if (e.target.checked) {
      console.log(" before ", this.extraTimeOutApproval.length);
      console.log(this.extraTimeOutApproval);
      this.extraTimeOutApproval.push(extra);
      console.log("after ", this.extraTimeOutApproval.length);
      console.log("extra time out check selected after",this.extraTimeOutApproval);
    } else {
      console.log("else part before ", this.extraTimeOutApproval.length);
      console.log(this.extraTimeOutApproval);
      this.extraTimeOutApproval = this.extraTimeOutApproval.filter(item => item.id!== extra.id);
      console.log("else part after ", this.extraTimeOutApproval.length);
      console.log(this.extraTimeOutApproval);
    }
    console.log("check selected done ");
   
  }
  onApproveHandler() {
    console.log(" ApproveExtraTimeOut");
    let approve_reject = true;
    let flag = true
    if (this.extraTimeOutApproval.length === 0) {
      this.alertmessages("Please select item for Approval.", "danger")
      flag = false
      return;
    }

    if (flag) {
      console.log("Approve extra time out 1");
      this.extraTimeOutService.extraTimeOutForApproval(this.extraTimeOutApproval, approve_reject).subscribe(
        data => {
          console.log("Approve extra time out",this.extraTimeOutApproval);
          this.alertmessages("Successfully Approved.", "success")
          this.extraTimeOutApproval.splice(0, this.extraTimeOutApproval.length)
          this.searchleave = ""
          this.resetAllCheckBoxes()
          this.getExtraTimeOut()
        },
        error => {
          this.searchleave = ""
          console.error("Error ", error);
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
              this.alertmessages("A requests have already been processed.", "danger")
            } else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
              this.alertmessages("One or more requests have already been processed.", "danger")
            } else {
              this.alertmessages("Not Approved.", "danger")
            }
          } else {
            this.alertmessages("Not Approved.", "danger")
          }
          this.resetAllCheckBoxes()
          this.extraTimeOutApproval.splice(0, this.extraTimeOutApproval.length)
          this.getExtraTimeOut()
        }
      )
    }

  }

  onRejectHandler() {
    console.log("Reject extra time out 1");
    let approve_reject = false;
    let flag = true
    if (this.extraTimeOutApproval.length === 0) {

      console.log("Reject extra time out 1",this.extraTimeOutApproval.length);
      this.alertmessages("Please select item for Rejection.", "danger")

      flag = false
      return;
    }

    let isCommentAbsent = false;
    this.extraTimeOutApproval.forEach(rejectLeave => {
//
      if (rejectLeave.remark === undefined) {
        isCommentAbsent = true;
        flag = false
        return;
      }
    })

    if (isCommentAbsent) {
      console.log("Reject extra time out ");
      this.alertmessages("Comments are mandatory for Rejection.", "danger")
      return;
    }

    if (flag) {
      this.extraTimeOutService.extraTimeOutForApproval(this.extraTimeOutApproval, approve_reject).subscribe(
        data => {
          this.alertmessages("Successfully Rejected.", "success")
          this.extraTimeOutApproval.splice(0, this.extraTimeOutApproval.length)
          this.searchleave = ""
          this.resetAllCheckBoxes()
          this.getExtraTimeOut()
        },
        error => {
          this.searchleave = ""
          console.error("Error ", error);
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
              this.alertmessages("A requests have already been processed.", "danger")
            } else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
              this.alertmessages("One or more requests have already been processed.", "danger")
            } else {
              this.alertmessages("Not Rejected.", "danger")
            }
          } else {
            this.alertmessages("Not Rejected.", "danger")
          }

          this.extraTimeOutApproval.splice(0, this.extraTimeOutApproval.length)
          this.resetAllCheckBoxes()
          this.getExtraTimeOut()
        }
      )

    }
    
    }

  searchLeaves: ExtraTimeOutWorkflow[] = [];
  chkInput(e: any) {
      console.log("chkinput ", this.searchleave);
      if (this.searchleave.length > 3) {
        console.log("call filter function ");
        this.extraTimeOutDetailFlow = this.extraTimeOutDetailFlow.filter(item =>
          item.employeename.toLowerCase().includes(this.searchleave.toLowerCase())
        );
        console.log(this.extraTimeOutDetailFlow);
      }
      else {
        this.extraTimeOutDetailFlow = this.tempextraTimeOutDetailFlow;
      }
    
   
  }

  convertTime(timeString: string): string {
    if (timeString) {
     
      const timeParts = timeString.split(':');
      const date = new Date();
      date.setHours(+timeParts[0], +timeParts[1], +timeParts[2]);
      
      // Format the date using DatePipe
      return this.datePipe.transform(date, 'hh:mm a') || '';
    }
    return '';
  }

  resetAllCheckBoxes() {

    const checkboxes = document.querySelectorAll('input');
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        checkboxes[i].checked = false;
      }
    }
    this.searchleave = ""
    this.extraTimeOutApproval.splice(0, this.extraTimeOutApproval.length)
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }

}
