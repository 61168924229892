import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IncometaxcalculatorServiceService {
  protected basePath = '/api';
	public defaultHeaders: Headers = new Headers();
	public url;

	constructor(private httpclient: HttpClient,private service: CommonService) {}
  public getEmployeeList(empId: any,branch:any,year:any,limitPara: any, offsetPara: any): Observable<any> {
    this.url = '/v1/getEmployee';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empId', empId);
    queryParameters = queryParameters.set('branch', branch);
    queryParameters = queryParameters.set('year', year);
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.service.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getSavingMaster(empId: any,branch:any,year:any): Observable<any> {
    this.url = '/v1/getSavingMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empId', empId);
    queryParameters = queryParameters.set('branch', branch);
    queryParameters = queryParameters.set('year', year);
    return this.service.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getSavingsMaster(empId: any,branch:any,year:any): Observable<any> {
    this.url = '/v1/getSavingMasterForTabName';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('empId', empId);
    queryParameters = queryParameters.set('branch', branch);
    queryParameters = queryParameters.set('year', year);
    return this.service.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public uploadEmpExcelDocument(excelDocToUpload: File): Observable<any> {
    this.url = '/v1/incomeTaxUpload';
    const path = this.basePath + this.url;
    var formData: any = new FormData();
    debugger
    
    //const formData: FormData = new FormData();
    console.log("File data from service file", excelDocToUpload)
    console.log("URL to send file", path)
    formData.append('file', excelDocToUpload);

    // let queryParameters = new HttpParams();
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    
    //headers.append( 'Accept', 'multipart/form-data' );
    const httpOptions = { headers: headers };
    
    // return this.commonService.postWithHttpInfo(formData, queryParameters,path)
    return this.httpclient.post(path, formData, httpOptions);

  }

}
