import { Component, OnInit } from '@angular/core';
import { PeopleInfo } from '../../models/peopleInfo';
import { DocumentInfo } from '../../models/documentInfo';
import { MyDocuments } from '../../models/mydocuents';
import { CommonService } from '../../services/common.service';
import { AlertService } from '../../services';
import { DocumentInfoService } from '../../services/documentinfo.service';
import { FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import  * as fileSaver from 'file-saver';

@Component({
  selector: 'app-mydocuments',
  templateUrl: './mydocuments.component.html',
  styleUrls: ['./mydocuments.component.css']
})
export class MyDocComponent implements OnInit {
  protected basePath = '/api';
  public url;
  peopleInfo: PeopleInfo;
  documentInfo: DocumentInfo;
  submitted: boolean;

  username: any;
  myDoc: any = {};
  myDocuments: MyDocuments;

  docGrp: string;
  docTyp: string;

  documentInfoDetails = [];
  sortDocInfoDetails = [];

  myDocuList = [];
  myDocInfoDetails = [];

  docGrpGrp = [];
  docGrpType = [];
  docTypeValidity = [];
  docTypArray = [];
  docValidityArray = [];

  docId: any;
  index: any;
  empId: any;
  f_Name: any;
  fileSavedPath: any;
  type: any;
  isValidImageFile: boolean = true; 
  errExpDate: boolean;
  disabledExpDate: boolean;
  showDocList: boolean;

  uploadForm: FormGroup;

  fileToUpload: File | null = null;
  disabledSaveButton: boolean;

  constructor(private httpclient: HttpClient, private commonService: CommonService, private alertService: AlertService, private docmentInfoService: DocumentInfoService) { }

  ngOnInit() {
    this.username = sessionStorage.getItem('username');
    this.empId = sessionStorage.getItem('empId');
    this.peopleInfo = new PeopleInfo();
    this.documentInfo = new DocumentInfo();
    this.myDocuments = new MyDocuments();
    this.peopleInfo.name = this.username;
    this.peopleInfo.empId = this.empId;
    this.submitted = false;
    this.errExpDate = false;
    this.disabledExpDate = true;
    this.showDocList = false;
    this.disabledSaveButton = false;
    this.getGroupType();
    this.listMyDoc();
  }


  save(valid) {
    this.submitted = true;

    // console.log("Valid ", valid)
    // console.log("Doc Group", this.myDocuments.docGroup)
    // console.log("Doc type", this.myDocuments.docType)

    if (!valid) {
      return;
    }

    if (!this.disabledExpDate) {

      if (this.myDocuments.expiryDate != null) {
        var entExpDate = new Date(this.myDocuments.expiryDate).toISOString();
        var todayDate = new Date().toISOString();
        if (entExpDate < todayDate) {
          //console.log(" The Entered Expiry Date is", entExpDate);
          //console.log(" The todays Date is", todayDate);
          this.errExpDate = true;
          return;
        } else {
          this.errExpDate = false;
        }
      } else {
        this.errExpDate = true;
        return;
      }
    }

    this.url = '/v1/employee/document';
    const path = this.basePath + this.url;
    const date = new Date(this.fileToUpload.lastModified);
    this.myDocuments.uploadedOn = date;
    this.myDocuments.name = this.fileToUpload.name;
    //this.myDocuments.empId = Number(sessionStorage.getItem('empId'));
    this.myDocuments.empId = sessionStorage.getItem('empId');
    console.log("The Eployee Id is", this.myDocuments.empId);
    console.log("The Employee Id Comming from Session is ", sessionStorage.getItem('empId'));
    var formData: any = new FormData();

    formData.append('document', JSON.stringify(this.myDocuments));
    formData.append('file', this.fileToUpload);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    //headers.append( 'Accept', 'multipart/form-data' );

    const httpOptions = { headers: headers };

    this.httpclient.post(path, formData, httpOptions).subscribe(

      data => {
        const response = data;
        console.log("The Return response is", data);
        if (data != null && data != 0) {    
          this.alertService.success("The Data and File Uploaded Successfully");
          this.alertmessages("Successfully Uploaded" , "success");
          //this.alertService.success(data.successMessages[0]);
          this.ngOnInit();
          this.submitted = false;
          this.myDoc.file = "";
        }
      },
      error => {
        const response = error.error;
        console.log("Error is ", error);
        this.ngOnInit();
        this.myDoc.file = "";
        this.alertmessages("The Data and File is not Saved" , "danger");
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertService.error(response.errorMessages[0]['key']);
        }else{
          this.alertService.error("The Data and File is not Saved, Please Contact Admin");
        }
      }

    );
  }

  getGroupType() {
    this.documentInfoDetails = [];
    this.docmentInfoService.getDocument('').subscribe(
      data => {
        this.documentInfoDetails = data.masterDocuments;
        this.listAllGroupType(this.documentInfoDetails);

      },
      error => {
        console.log("The Error is ", error);
      });
  }

  listAllGroupType(documentInfoDetails: any) {
    this.docGrpGrp = [];
    this.docTypeValidity = [];
    this.docGrpType = [];
    this.docTypArray = [];
    this.docValidityArray = [];
    this.sortDocInfoDetails = this.documentInfoDetails.sort(this.GetSortOrder("docGroup"));

    if (this.documentInfoDetails.length != 0) {
      for (var i = 0; i < this.documentInfoDetails.length; i++) {
        if (i == 0) {
          this.docGrpGrp.push(this.documentInfoDetails[0].docGroup);
          this.docTypArray.push(this.documentInfoDetails[0].docType);
          this.docValidityArray.push(this.documentInfoDetails[0].validityPeriod);
        } else {
          if (this.documentInfoDetails[i - 1].docGroup != this.documentInfoDetails[i].docGroup) {

            this.docGrpType.push(this.docTypArray);
            this.docTypeValidity.push(this.docValidityArray);
            this.docTypArray = [];
            this.docValidityArray = [];
            this.docGrpGrp.push(this.documentInfoDetails[i].docGroup);
            this.docTypArray.push(this.documentInfoDetails[i].docType);
            this.docValidityArray.push(this.documentInfoDetails[i].validityPeriod);
          } else {

            this.docTypArray.push(this.documentInfoDetails[i].docType);
            this.docValidityArray.push(this.documentInfoDetails[i].validityPeriod);
          }
        }
      }
      if (i == this.documentInfoDetails.length) {
        this.docGrpType.push(this.docTypArray);
        this.docTypeValidity.push(this.docValidityArray);
        this.docTypArray = [];
        this.docValidityArray = [];
      }
    }

  }

  docGroupChange() {
    const dGrpKey = (<HTMLInputElement>document.getElementById('dGroup')).value;

    const grpIndex = this.docGrpGrp.indexOf(dGrpKey);
    this.docTypArray = [];
    this.docValidityArray = [];
    this.docTypArray = this.docGrpType[grpIndex];
    this.myDocuments.docType = "";
    // console.log("Array One",this.docGrpType[grpIndex]);
    // console.log("Array Two",this.docTypArray);

    // const firstValue = this.docTypArray[0];
    // console.log(firstValue); // Output: 'one'

    // console.log("Array Three",this.docTypArray[0]);
    this.docValidityArray = this.docTypeValidity[grpIndex];
    this.myDocuments.docType = this.docTypArray[0];
    // console.log("The DocType Arry is", this.docTypArray);
    // console.log("The Validity Array is", this.docValidityArray);
    // console.log("Final doc type", this.myDocuments.docType);
  }

  // Comparer Function    
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  listMyDoc() {
    if (this.empId === 'undefined' || this.empId === "") {
      const modal = document.getElementById('showNoPermission');
      modal.style.display = 'block';
    } else {
      console.log("Listing all Documents", sessionStorage.getItem('empId'));
      this.myDocInfoDetails = [];

      this.docmentInfoService.listMyDocument().subscribe(
        data => {
          console.log("List my doc :", data)
          this.myDocInfoDetails = data.empDocuments;
          this.listAllMyDoc(this.myDocInfoDetails);
        },
      );
    }
  }

  confirmNoPermission() {
    this.closeAlertMRQModal();
    this.disabledSaveButton = true;

  }

  closeAlertMRQModal() {
    const modal = document.getElementById('showNoPermission');
    modal.style.display = 'none';
  }

  listAllMyDoc(myDocInfoDetails: any) {
    this.myDocuList = [];
    if (this.myDocInfoDetails.length != 0) {
      for (var i = 0; i < this.myDocInfoDetails.length; i++) {
        this.myDocuList.push(this.myDocInfoDetails[i]);
      }
    }
    if (this.myDocuList.length != 0) {
      this.showDocList = true;
    } else {
      this.showDocList = false;
    }
  }

  myDocumentDownload(filName: any, path: any) {
    this.fileSavedPath = path;
    console.log("The File Saved Path is", this.fileSavedPath);
    //this.fileSavedPath = '../../../assets/uploads/';
    this.type = filName.split('.').pop();
    this.f_Name = filName;

    //this.downLoadFile(this.fileSavedPath, this.type, this.f_Name);
    this.downloadImage();
  }


  downLoadFile(f_path: any, type: string, f_name: any) {
    var link = document.createElement("a");
    //var blob = new Blob([f_path], { type: type.toString() });
    var blob = new Blob([f_path], { type: "image/png" });
    //var url = window.URL.createObjectURL(blob);
    var url = URL.createObjectURL(blob);
    link.download = f_name;
    link.href = url;
    link.click();
    console.log("The Url is", url);
    //window.open(url);
    /*
    var link = document.createElement("a");
    var url = f_path;
    console.log("The URL is", url);
    link.download = f_name;
    link.href = url;
    link.click();
    */
  }

  downloadImage() {
    console.log("Download Image is called");
    this.docmentInfoService.downloadFile(this.fileSavedPath).subscribe(
      data => {
        var downloadUrl = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.download = this.f_Name;
        link.href = downloadUrl;
        link.click();
        link.remove();
        console.log("The Url is", downloadUrl);
      },
      error => {
        console.log('Error occured in download file');
      }
    );
  }

  myDocumentDelete(j: any, id: any) {
    this.index = j;
    this.docId = id;
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay');
    overlay.style.display = 'block';
    modal.style.display = 'block';
  }

  closeMRQModal() {
    const modal = document.getElementById('showAlert');
    const overlay = document.getElementById('overlay');
    overlay.style.display = 'none';
    modal.style.display = 'none';
  }

  confirmMyDocDelete() {
    this.closeMRQModal();
    this.docmentInfoService.deleteMyDocument(this.docId).subscribe(
      data => {
        this.alertService.success(data.successMessages[0]['key']);
        this.listMyDoc();
      },
      error => {
        const response = error.error;
        console.log("Error is ", error);
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertService.error(response.errorMessages[0]['key']);
        }
      }
    );
  }

  handleFileInput(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileToUpload = file;
      if (!file.type.startsWith('image/')) {
        this.isValidImageFile = false;  // Invalid file type
      } else {
        this.isValidImageFile = true;  // Valid image file
      }
    }
  }

  clearSelection() {
    this.myDoc.file = "";
    this.fileToUpload = null;
    //console.log("Clear Selection is invoked");
    //console.log("The file uploade is ", this.fileToUpload);
  }

  docTypeChange() {
    const selDocType = (<HTMLInputElement>document.getElementById('dType')).value;
    const typIndex = this.docTypArray.indexOf(selDocType);
    const typValidity = this.docValidityArray[typIndex];
    if (typValidity == 'Y') {
      this.disabledExpDate = false;
    } else {
      this.disabledExpDate = true;
      this.myDocuments.expiryDate = null;
      this.errExpDate = false;
    }
  }

  onfocusExpDate() {
    //remove error message
    this.errExpDate = false;
  }

  reFresh() {
    //this.ngOnInit();
    //this.submitted = false;
    this.documentInfo = new DocumentInfo();
    this.myDocuments = new MyDocuments();
    this.myDoc.file = "";
    this.errExpDate = false;
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
