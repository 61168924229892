import { Component, OnInit } from '@angular/core';
import { FormTreportService } from '../services/form-treport.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-form-t-download-report',
  templateUrl: './form-t-download-report.component.html',
  styleUrls: ['./form-t-download-report.component.css']
})
export class FormTDownloadReportComponent implements OnInit {

  years: string[] = [];
  year: any;

  filetypes: string[] = ['.txt','.xls']

  selectedFileType: string = '';

  excelDoc: any = {};
  fileToUpload: File | null = null;
  submitted: boolean;
  public files: any[] = [];
  fileslength: boolean;
  multipleFilePath = [];
  uploadedDocDetails = [];
  loading: boolean = false; 
  requestDepartmentObj: any[] = [];
  uploadError: boolean = false;
  excelErrors: string[] = [];
  
  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  
  selectedMonth: { name: string, value: number } | undefined;

  constructor(private formtdownloadservice:FormTreportService,private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    const currentMonthValue = new Date().getMonth() + 1; // getMonth() returns 0 for January, so +1
    this.selectedMonth = this.months.find(month => month.value === currentMonthValue);

    const currentYear = new Date().getFullYear();
    // this.years.push(''); // Add empty option
    for (let year = currentYear; year >= 2018; year--) {
      this.years.push(year.toString());
    }
    this.year = currentYear.toString();
  }
  handleFileInput(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileToUpload = file;
    }
  }

  selectedDirectoryPath: string = '';  // To store the selected directory name
 // Function to trigger file input when the button is clicked
 openDirectory(): void {
  const fileInput = document.getElementById('directoryInput') as HTMLInputElement;
  fileInput.click();  // Programmatically open the file input dialog
}

// Handle the directory selection
handleDirectoryChange(event: any): void {
  const files = event.target.files;

  if (files.length > 0) {
    // Extract the relative path of the first file
    const firstFilePath = files[0].webkitRelativePath;

    // Get the full directory path by removing the file part
    const directoryPath = firstFilePath.substring(0, firstFilePath.lastIndexOf('/'));

    // Set the full directory path as the value to display
    this.selectedDirectoryPath = directoryPath;
  } else {
    this.selectedDirectoryPath = '';  // Reset if no directory is selected
  }
}
  

download(){
  var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
  this.formtdownloadservice.downloadformtReport(this.year,this.selectedMonth.value.toString(),this.selectedMonth.name.toString()).subscribe(data => {
   
    var file = new Blob([data], { type: 'application/vnd.ms-excel' });
    var fileURL = URL.createObjectURL(file);
    var anchor = document.createElement("a");
    anchor.download = "Form T Report.xls";
    anchor.href = fileURL;
    anchor.click();
  },
    error => {
      console.log("Error Occured ", error);
    })
}
 
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  alertmessagesWithLink(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    if(this.uploadError){
      alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} 
      <button id="viewErrorsBtn" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#excelErrors">View Errors</button>
      </div>`
    }else{
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    }

    let alertcomp = document.querySelector('.alertcompExcelUpload');
    alertcomp.append(alertDiv);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }
}
