import {  TitleCasePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from '../constants/GlobalConstants';
import { EmployeeAttendence } from '../models/EmployeeAttendenceInfo';
import { Employeeswipeintermediateinfo } from '../models/EmployeeswipeIntermediateinfo';
import { SessionService } from '../services';
import { EmployeeattendenceserviceService } from '../services/employeeattendenceservice.service';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service'

declare var $: any;
@Component({
  selector: 'app-employee-attendence',
  templateUrl: './employee-attendence.component.html',
  styleUrls: ['./employee-attendence.component.css']
})
export class EmployeeAttendenceComponent implements OnInit {

  public lat;
  public lng;

  initialSwipestate: number = 1;

  emp_attendence_list: EmployeeAttendence[];
  //currentdate = this.datepipe.transform(new Date(), 'MMM d');
  // globalconstants:GlobalConstants = new GlobalConstants();
  swipeintype = GlobalConstants.SWIPE_IN;
  swipeouttype = GlobalConstants.SWIPE_OUT;

  emp_attendance_intermediatelist: Employeeswipeintermediateinfo[] = [];


  //different break in between 
  break: any[] = []

  //server time
  serverTime: Date;

  constructor(private empAttendenceService: EmployeeattendenceserviceService, private titleCase: TitleCasePipe,
    private sessionService: SessionService, private employeeAttendenceProxyService: EmployeeAttendenceProxyService) { }

  ngOnInit(): void {
    $(function (e) {
      'use strict';

      /* Data Table */
      // $('#emp-attendance').DataTable({
      //   "order": [[ 0, "desc" ]],
      //   language: {
      //     searchPlaceholder: 'Search...',
      //     sSearch: '',

      //   }
      // });
      /* End Data Table */

      //________ Datepicker
      $(".fc-datepicker").datepicker({
        dateFormat: "dd M yy",
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"]
      });

      //________ Countdonwtimer
      // $("#clocktimer").countdowntimer({
      //   currentTime : true,
      //   size : "md",
      //   borderColor : "transparent",
      //   backgroundColor : "transparent",
      //   fontColor : "#313e6a",
      //   // timeZone : "+1"
      // });

      //________ Countdonwtimer
      // $("#clocktimer2").countdowntimer({
      //   currentTime: undefined,
      //   size: "md",
      //   borderColor: "transparent",
      //   backgroundColor: "transparent",
      //   fontColor: "#313e6a",
      //   startDate:undefined,
      //   dateAndTime:undefined,
      //   hours:10,
      //   minutes:10,
      //   seconds:60
      //   // timeZone : "+1"
      // });

      //________ Datepicker
      $('.fc-datepicker').datepicker('setDate', 'today');

      /* Select2 */
      // $('.select2').select2({
      //   minimumResultsForSearch: Infinity,
      //   width:'100%'
      // });

    });
    this.get_Attendence_info()
    this.getempregularizedSummary();
    this.getempbreaks()

    this.lat = 0;
    this.lng = 0;
    this.getCurrentLocCoords();
    this.getServerTime()
    let navigation = navigator.geolocation.getCurrentPosition((position) => { }, (error) => { })
  }

  getempbreaks() {
    this.empAttendenceService.getemployeebreaksinfo().subscribe({
      next: (data) => {
        this.break = data.employeebreaks
        console.log(data)
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  getServerTime() {
    this.employeeAttendenceProxyService.getServerTime().subscribe(
      (response: any) => {
        console.log();
        response
        // Assuming the server time is provided in a field called 'serverTime'
        this.serverTime = new Date(response.serverTime);
        $("#clocktimer2").countdowntimer({
          currentTime: true,
          size: "md",
          borderColor: "transparent",
          backgroundColor: "transparent",
          fontColor: "#313e6a",
          startDate: this.serverTime,
          dateAndTime: undefined,
          // hours:this.serverTime.getHours(),
          // minutes:this.serverTime.getMinutes(),
          // seconds:this.serverTime.getSeconds()
          // timeZone : "+1"
        });
      },
      (error) => {
        console.error('Error fetching server time:', error);
      }
    );
  }

  // here (click)=save_employee_attendences($event,'IN')

  get_Attendence_info() {
    console.log(this.sessionService.getempid())
    this.empAttendenceService.get_Attendence_info(this.sessionService.getempid()
    ).subscribe(
      data => {
        console.log(data, "data ")
        this.emp_attendence_list = data.currentattendencelistbasedonDate;
        console.log(this.emp_attendence_list, "emp swipe info list for a given day")
        this.displayclockIn_and_clockOut(this.emp_attendence_list)

      }
    );
  }

  displayclockIn_and_clockOut(empswipelist: EmployeeAttendence[]) {
    if (empswipelist.length !== 0) {
      const lastswipeType: number = empswipelist[empswipelist.length - 1].swipetype;
      const clock_In = document.getElementById('clockIN');
      const clock_Out = document.getElementById('clockOut');

      const element_has_displaynone_class = document.querySelector('.displayNone');
      element_has_displaynone_class.classList.remove('displayNone')

      console.log(lastswipeType, "lastswipe type")
      if (lastswipeType === GlobalConstants.SWIPE_IN) {
        // display static clock
        if (clock_In.classList.contains('displayNone')) {
          clock_In.classList.remove('displayNone');

        }
        else {
          clock_In.classList.add('displayNone')
        }
      } else if (lastswipeType === GlobalConstants.SWIPE_OUT) {
        // display rotating clock indication that employee has clocked in 
        if (clock_Out.classList.contains('displayNone')) {
          clock_Out.classList.remove('displayNone');

        } else {
          clock_Out.classList.add('displayNone');
        }
        // else{
        //   clock_In.classList.add('displayNone')
        // }
      }

    }
  }

  attendenceInandOut(): EmployeeAttendence {
    let emp_attendence_info = new EmployeeAttendence();
    // emp_attendence_info.empid= 'TH0001';
    emp_attendence_info.manual = true;
    emp_attendence_info.processed = false;
    emp_attendence_info.shifted = false;
    emp_attendence_info.userid = this.sessionService.getLoggedUserId();
    emp_attendence_info.swipetime = new Date();

    emp_attendence_info.uniqueemployeeid = parseInt(this.sessionService.getempid())

    // swipe type 1 means swipe in , 2 mean swipe out

    emp_attendence_info.timestamp = new Date();
    // emp_attendence_info.timestamp =new Date().toUTCString();

    return emp_attendence_info;
  }

  save_employee_attendences(e, swiptetype: string) {
    console.log(" save employee attendance clock in out  break.........")
    console.log(" Logged in /Employee id----------........." + this.sessionService.getempid())
    const empattendenceinfo = this.attendenceInandOut();
    if (swiptetype === "IN") {
      empattendenceinfo.swipetype = 1
      empattendenceinfo.latitude = this.lat;
      empattendenceinfo.longitude = this.lng;
    } else {
      empattendenceinfo.swipetype = 2
      empattendenceinfo.latitude = this.lat;
      empattendenceinfo.longitude = this.lng;
    }

    // get attendance detail of swipe below


    this.empAttendenceService.saveEmpAttendence(empattendenceinfo)
      .subscribe(data => {
        console.log(data)
        if (data.successMessages && data.successMessages[0].key) {
          this.alertmessages(data.successMessages[0].key, "success");
          this.get_Attendence_info()
        }
      },
        error => {
          console.log(error)
          //this.alertmessages(error.errorMessages[0].key, "danger");
        })



    // get  geolocation 
    //  navigator.geolocation.getCurrentPosition((position)=>{
    //   empattendenceinfo.lat_coord = position.coords.latitude.toString()
    //   empattendenceinfo.long_coord = position.coords.longitude.toString()
    // },(error)=>{
    //   this.alertmessages("Current location coordinates were not captured" , "danger")
    // })




    // change here -- facerecognition  below


    // console.log(empattendenceinfo, e.breakid)
    // empattendenceinfo.breakid = Number(e.target.breakid);
    // this.empAttendenceService.saveEmpAttendenceCaptureface(empattendenceinfo).subscribe({
    //   next: (data) => {
    //     console.log(data)
    //     console.log("Response result-------")
    //     console.log("Response resultnew-------"+data.successMessages[0].key)
    //     // this.alertmessages(data.successMessages[0].key,"success")
    //     //console.log(data.main_apiii.comparison_results)
    //     if (data.successMessages && data.successMessages[0].key) {
    //       //  if (data.successMessages.length > 0 && data.successMessages[0].key == "success") {
    //         console.log("Response result-sucess------")
    //         this.alertmessages(data.successMessages[0].key, "");
    //       // }else if (data.successMessages.length > 0 && data.successMessages[0].key == "failure"){
    //       //   console.log("Response result--failure-----")
    //       //   this.alertmessages("Not a Valid employee...try again", "danger");
    //       // }
    // this.get_Attendence_info()
    //       // this.alertmessages("Employee validated ", "success");
    //     } 
    //  },
    //   error: (e) => {
    //     this.alertmessages("Please try again ", "danger");
    //   },
    //   complete() {
    //   },
    // })


  }

  showtypes_ofbreak() {
    $('#staticBackdrop').modal('toggle');
  }

  save_employee_attendence(e) {


    const empattendenceinfo = this.attendenceInandOut();
    if (this.emp_attendence_list && this.emp_attendence_list.length !== 0 && this.emp_attendence_list[this.emp_attendence_list.length - 1].swipetype === GlobalConstants.SWIPE_IN) {
      empattendenceinfo.swipetype = 2;
    } else if (this.emp_attendence_list && this.emp_attendence_list.length !== 0 && this.emp_attendence_list[this.emp_attendence_list.length - 1].swipetype === GlobalConstants.SWIPE_OUT) {
      empattendenceinfo.swipetype = 1;
    } else {
      empattendenceinfo.swipetype = 1; // default is swipe in
    }
    console.log(empattendenceinfo)
    this.empAttendenceService.saveEmpAttendence(empattendenceinfo)
      .subscribe(data => {
        console.log(data)

        if (data.successMessages && data.successMessages[0].key) {
          this.alertmessages(data.successMessages[0].key, "success");

          this.get_Attendence_info()
        }


      },
        error => {
          console.log(error)
        })
  }

  // display of data in the table 
  getempregularizedSummary() {
    let uniqueempid: number = parseInt(this.sessionService.getempid());
    this.empAttendenceService.get_regularized_emp_Summary(uniqueempid).subscribe(
      response => {
        this.emp_attendance_intermediatelist = response.attendanceSummaryIntermediate;

        this.emp_attendance_intermediatelist.forEach(item => {
          item.status = this.titleCase.transform(item.status);
        });

        console.log(this.emp_attendance_intermediatelist, "emp_regularized_Summary")
      }
    )

  }

  getCurrentLocCoords() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
          }
        },
        (error) => {
          console.log(error);
          this.handleGeolocationError(error);
        }
      );
    } else {
      alert("Geolocation is not supported by this Browser.");
    }
  }


  // button to enable browserpermission promt but we cant ..... security if u have got any feel free to add 
  // EnableLocation1() {
  //   navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
  //     if (permissionStatus.state === 'denied' || permissionStatus.state === 'prompt') {
  //       const enableLocationButton = document.createElement('button');
  //       enableLocationButton.textContent = 'Enable Location Access';
  //       enableLocationButton.addEventListener('click', () => {
  //         navigator.geolocation.getCurrentPosition(
  //           (position) => {
  //             console.log('Latitude:', position.coords.latitude);
  //             console.log('Longitude:', position.coords.longitude);
  //           },
  //           (error) => {
  //             console.error('Error getting location:', error);
  //           }
  //         );
  //       });
  //     }
  //   });
  // }

  // handel error in getcurrentlocation 
  handleGeolocationError(error: any) {
    if (error.code === 1) {
      alert("Permission denied for Geolocation. Please enable it in your Browser Settings.");
    } else if (error.code === 2) {
      alert("Geolocation is Temporarily Unavailable. Please try again later.");
    } else if (error.code === 3) {
      alert("Timeout while retrieving Geolocation. Please try again.");
    } else {
      alert("An error occurred while enabling Geolocation. Please check your settings and try again.");
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }
  validateprofilepic(): void {
    let id = 1;
    console.log("File in validating...")
    this.empAttendenceService.validateprofilepic().
      subscribe({
        next: (data) => {
          // console.log("resonse 1 :",data)
          // console.log("resonse 2",data.compare_images)
          console.log("resonse 3", data.capture_and_save_image.imagepath
          )


          //// this.imagePath = data.capture_and_save_image.imagepath;

          // this.LeaveTypeListValue = data;
          // console.log("Image path details :  ", this.getProfilePath)
          // let obj: any
          // obj= this.imagePath
          // this.faceregisterdata.push(obj)
          // console.log("check faceregisterdata",this.faceregisterdata)
          console.log("Image validating end")
        },
        error: (e) => {
          console.log(e)
        }

      })



  }

}
