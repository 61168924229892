import { employeeDocuments } from "./employeeDocuments";

export class taskInfo{
    taskName: String;
    taskId: String;
    employeeName : String;
    departmentname : String;
    documents: employeeDocuments;
}
export class DocumentTaskDetail{
    name: String;
    uniqueemployeeid: number;
    userid: string;
    empid: string;
    status: string;
    employeeName: string;
    remark: string;
    employeeId: string;
    departmentname:string;
    documenttype:string;
    filePath:string;
    comments:string;
}