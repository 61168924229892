import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { EmployeeGroup } from '../models/Employeegroup';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeShiftService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url!: string;
  constructor(private common:CommonService,private commonService: CommonService) { }

  public getgroup():Observable<any>{
    this.url = '/v1/groups';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.common.getByQueryParamWithHttpInfo(queryParameters  , path);
  }

  public getempbasedonDepartment(id:number,limit:number ,offset:number):Observable<any>{
    this.url = '/v1/empdepartment';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id',id);
    queryParameters = queryParameters.set('limit',limit);
    queryParameters = queryParameters.set('offset',offset);
    return this.common.getByQueryParamWithHttpInfo(queryParameters  , path);
  }

  public saveEmpGroup(body:EmployeeGroup[] , attribute:string):Observable<any>{
    this.url = '/v1/emp-group';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('attribute',attribute);
    return this.common.postWithHttpInfo(body,queryParameters, path);
  }
  public getempbasedonDepartmentandgroup(depid:number , groupid:number , limit:number ,offset:number):Observable<any>{
    this.url = '/v1/emp-groups';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id',depid);
    queryParameters = queryParameters.set('groupid',groupid);
    queryParameters = queryParameters.set('limit',limit);
    queryParameters = queryParameters.set('offset',offset);

    return this.common.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public savegroupToShiftdata(groupid:number , shiftid:number ,fromdate:string , todate:string){
    this.url = '/v1/groupshiftmapping';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('groupid',groupid);
    queryParameters = queryParameters.set('shiftid',shiftid);
    queryParameters = queryParameters.set('fromdate',fromdate);
    queryParameters = queryParameters.set('todate',todate);
    return this.common.postWithHttpInfo("",queryParameters, path);

  }

  public deleteShiftMaster(deleteId:any) : Observable<any> {
    console.log("delete id  : ", deleteId);
    this.url = '/v1/deleteShiftMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('deleteId', deleteId);
    return this.commonService.postWithHttpInfo(deleteId, queryParameters, path);
  
}

  public getEditShiftMaster(editId:any) : Observable<any> {
    console.log("editIdService",editId);
    this.url = '/v1/getEditShiftMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('editId', editId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public updateShiftMaster(insertShiftMasterDetails: any) : Observable<any> {
    console.log("Update id  : ", insertShiftMasterDetails);
    this.url = '/v1/updateShiftMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // queryParameters = queryParameters.set('updateId', insertITSlabDetails);
    return this.commonService.postWithHttpInfo(insertShiftMasterDetails,queryParameters, path);
  
}

  public getshifts():Observable<any>{
    this.url = '/v1/getshiftMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.common.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getallocatedShifts(searchby:string):Observable<any>{
    this.url = '/v1/allocatedShifts';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('seachby', searchby);
    return this.common.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public updategroupToShiftdata(groupid:number , shiftid:number ,fromdate:string , todate:string ,
    minid:number , maxid:number){
    this.url = '/v1/updateallocatedShifts';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('groupid',groupid);
    queryParameters = queryParameters.set('shiftid',shiftid);
    queryParameters = queryParameters.set('fromdate',fromdate);
    queryParameters = queryParameters.set('todate',todate);
    queryParameters = queryParameters.set('minid',minid);
    queryParameters = queryParameters.set('maxid',maxid);

    return this.common.postWithHttpInfo("",queryParameters, path);

  }


  public saveShiftMaster(shiftmaster: any): Observable<any> {
    console.log("value sent ", shiftmaster)
    this.url = '/v1/savingShiftMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(shiftmaster, queryParameters, path);
  }

  public get_emp_allocatedShifts(fromdate:string , todate:string , limitPara?:number , offset?:number):Observable<any>{
    this.url = '/v1/empShifts';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromdate',fromdate);
    queryParameters = queryParameters.set('todate',todate);
    queryParameters = queryParameters.set('limit',limitPara);
    queryParameters = queryParameters.set('offset',offset);
    return this.common.getByQueryParamWithHttpInfo(queryParameters, path);
  }
  public getempbasedonGroup(id:number,limit:number ,offset:number):Observable<any>{
    this.url = '/v1/empgroup';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('id',id);
    queryParameters = queryParameters.set('limit',limit);
    queryParameters = queryParameters.set('offset',offset);
    return this.common.getByQueryParamWithHttpInfo(queryParameters  , path);
  }
}
