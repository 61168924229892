<div class="app-content main-content">
  <div class="side-app main-container">

    <div class="alertcomp">

    </div>

    <div class="page-header d-block">
      <div class="page-leftheader" style="margin-bottom: 0.75rem">
        <div class="page-title">View/Approve Document</div>
      </div>
      <div class="row">
        <div class="col-xl-12 col-md-12 col-lg-12">
          <div class="card">

            <div class="card-body">
              <form class="form-horizontal" id="fcForm" (keyup.enter)="limitpara(); searchEmployeeDoc()">



                <div class="form-group row">
                  <label for="inputName" class="w-100  w-md-20 fontSize-15 form-label mt-auto mb-2 p-0">Employee Name /
                    ID</label>
                  <div class="col-md-12 px-0">
                    <input type="search" class="form-control" name="Employee Name" id="employeename"
                      placeholder="Enter Employee Name / ID " [(ngModel)]="query" autocomplete="off"
                      (input)="getEmployeeByNameOrId()" list="employeeMasterLucene" autofocus="autofocus">
                    <datalist id="employeeMasterLucene">
                      <option data-value={{emp.name}} *ngFor="let emp of employeeMasterLucene">
                        {{emp.employeeid}}
                      </option>
                    </datalist>
                  </div>
                </div>


                <div class="row">

                  <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="form-group label-floating">
                      <label class="form-label">Department</label>
                      <div>
                        <div>
                          <select id="departmentDropDown" [(ngModel)]="departmentDropDown" class="form-control"
                            name="dateFilter">
                            <option value="All">All</option>
                            <option value="{{data.departmentId}}"
                              *ngFor="let data of filteredDepartmentNames;let i = index">
                              {{data.departmentname}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="form-group label-floating">
                      <label class="form-label">Status</label>
                      <div>
                        <div>
                          <select id="statusDropDown" [(ngModel)]="statusDropDown" class="form-control"
                            name="dateFilter">
                            <option value="All" selected>All</option>
                            <option value="Submitted">Submitted</option>
                            <option value="Verified & Approved">Verified & Approved</option>
                            <option value="Rejected">Rejected</option>
                            <option value="NotSubmitted">Not Submitted</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-4">
                    <div class="form-group label-floating">
                      <label class="form-label">Document Type</label>
                      <div>
                        <div>
                          <select id="documentDropDown" [(ngModel)]="documentDropDown" class="form-control"
                            name="dateFilter">
                            <option value="All">All</option>
                            <option value={{data.docType}} *ngFor="let data of filteredDocType">
                              {{data.docType}}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" class="btn btn-primary  w-100  w-md-20 mt-3 mt-md-0 p-2 p-md-1"
                  (click)="limitpara(); searchEmployeeDoc()" id="navigatetarget">
                  <i class="fa fa-search" aria-hidden="true"></i> Search
                </button>

              </form>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12 col-xs-12 mobile">
        <div class="card">
          <div class="card-body">
            <div class="Clearfix"></div>

            <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
            <div class="col-xs-12 mobile">
              <div *ngIf="filterTaskList !== null ">
                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                  [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                  (scrolled)="onScroll()" id="scrolllength">
                  <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                    <thead class="tablerow">
                      <tr class="rowcolors stickyatTop">
                        <th class="border-bottom-0 w-5 fs-7">Employee Name</th>
                        <th class="border-bottom-0 w-5 fs-7">Department</th>
                        <th class="border-bottom-0 w-5 fs-7">Document Type</th>
                        <th class="border-bottom-0 w-5 fs-7">Status</th>
                        <th class="border-bottom-0 w-5 fs-7">Action</th>
                      </tr>
                    </thead>
                    <tbody infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="3000"
                      [scrollWindow]="false" (scrolled)="onScroll()" id="navigatetarget">

                      <tr *ngFor="let j=index; let taskInfo of filterTaskList">
                        <td data-th="Employee Name">
                          {{taskInfo.employeeName}}
                        </td>

                        <td data-th="Employee Name">
                          {{taskInfo.departmentname}}
                        </td>

                        <td data-th="Document Type">
                          {{taskInfo.documenttype}}
                        </td>

                        <td data-th="Document Type">
                          {{taskInfo.status}}
                        </td>

                        <td data-th="Action">
                          <button class="btn btn-primary btn-icon btn-sm me-2"
                            (click)="myDocumentDownload(taskInfo.name, taskInfo.filePath)" style="width: 32px;">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                          </button>

                          <button class="btn btn-primary btn-icon btn-sm me-2"
                            (click)="viewdocument(taskInfo.name, taskInfo.filePath)" style="width: 32px;">
                            <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                          </button>

                          <button *ngIf="taskInfo?.status === 'Submitted'" class="btn btn-light btn-icon btn-sm me-2"
                            type="button" rel="tooltip" title="Comment" (click)="confirmOnlycomment()"
                            style="width: 32px;">
                            <i class="zmdi zmdi-comments fa-2x" data-toggle="tooltip"></i>
                          </button>


                          <button *ngIf="taskInfo?.status === 'Submitted'" class="btn btn-success btn-icon btn-sm me-2"
                            type="button" rel="tooltip" data-method="Approve" style="width: 32px;" title="Approve"
                            (click)="confirmapprove(taskInfo,true)">
                            <i class="zmdi zmdi-check-square fa-2x" data-toggle="tooltip"></i>
                          </button>

                          <button *ngIf="taskInfo?.status=== 'Submitted'" class="btn btn-danger btn-icon btn-sm me-2"
                            type="button" rel="tooltip" data-method="Reject" style="width: 32px;" title="Reject"
                            (click)="confirmrejection(taskInfo,false)">
                            <i class="zmdi zmdi-close fa-2x" data-toggle="tooltip" style="width: 12px;"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="Comment" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">

          <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeCommetModal()">×</button>
            <h4 class="modal-title"><b>Reject</b></h4>
          </div>

          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <span>Comments for Rejection</span>
          </div>

          <div class="modal-footer form-group ">
            <span *ngIf="employeeDocs.comments === undefined || employeeDocs.comments?.length===0"
              style="color: red;">Comments are mandatory</span>
            <textarea class="form-control" #comments="ngModel" [(ngModel)]="employeeDocs.comments" rows="5"
              autofocus="true" required="required" autocomplete="off"></textarea>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger medium" (click)="approveDocument()"
              [disabled]="!employeeDocs.comments || employeeDocs.comments.length === 0">Reject</button>
            <button type="button" class="btn btn-primary" (click)="closeCommetModal()">Cancel</button>
          </div>

        </div>
      </div>
    </div>


    <div id="OnlyComment" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">

          <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeOnlyCommetModal()">×</button>
            <h4 class="modal-title"><b>Comments</b></h4>
          </div>

          <div class="modal-body">
            <textarea class="form-control" [(ngModel)]="employeeDocs.comments" #comments="ngModel" rows="5"></textarea>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="closeOnlyCommetModal()">Ok</button>
          </div>
        </div>
      </div>
    </div>

    <div id="Approve" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">

          <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeApproveModal()">×</button>
            <h4 class="modal-title"><b>Approve</b></h4>
          </div>

          <!-- <div class="modal-body">
            <span>Document will be Approved. Are you sure you want to continue ?</span>
          </div> -->
          <!-- <div *ngIf="employeeDocs.comments === undefined || employeeDocs.comments?.length===0" style="color: red;">
            Comments are mandatory</div> -->

          <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <span>Document will be Approved. Are you sure you want to continue ?</span>
            <div style="color: red;">
              Comments is Optional</div>
            <textarea class="form-control" [(ngModel)]="employeeDocs.comments" #comments="ngModel" rows="5"></textarea>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="approveDocument()">Approve</button>
            <button type="button" class="btn btn-danger medium" (click)="closeApproveModal()">Cancel</button>

          </div>
        </div>
      </div>
    </div>

    <div id="viewUploadFile" class="modal" style="top: 40px;">
      <div class="modal-dialog">
        <div class="modal-content animate">
          <div class="modal-header">
            <button type="button" class="btn-close" (click)="closeViewModal()">×</button>
            <h4 class="modal-title">Image Uploaded</h4>
          </div>

          <div id="fileViewer" class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
            <!-- auto scroll -->
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="closeViewModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>