import { Component, OnInit } from '@angular/core';
import { ExtraTimeOut } from '../models/extra-time-out';
import { CommonService } from '../services/common.service';
import { Extratimeoutservices } from '../services/extratimeoutservices';
import { DatePipe } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-apply-extra-timeout',
  templateUrl: './apply-extra-timeout.component.html',
  styleUrls: ['./apply-extra-timeout.component.css']
})
export class ApplyExtraTimeoutComponent implements OnInit {
  date: any;
  totalMinutes: any;
  fromTime: any;
  toTime: any;
  reason: any;
  balanceExtraTimeOut: any;
  extraTimeOutList: any[] = [];
  extraTimeOut = new ExtraTimeOut(-1,-1," ", " "," "," ", " ", " ", " ", " ");
  deleteExtraTimeOut: ExtraTimeOut;
  uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
  ExtraTimeOutAppliedId:number;
  constructor(private commonService: CommonService,private extraTimeOutService: Extratimeoutservices,private datePipe: DatePipe) { 
  }

  ngOnInit(): void {
   
 -  this.getExtraTimeOutApplied();
  }

  validate(): boolean {
    if (this.date != undefined && this.reason != undefined  &&  this.fromTime != undefined && this.toTime != undefined && this.totalMinutes>0) {
      return true;
    } else if(this.totalMinutes<=0){
      this.alertmessages("Can't apply extra time out, total time out must be greater than zero", "danger")
      return false;
    } else {
      this.alertmessages("Can't apply extra time out, please check all the required fields.", "danger")
      return false;
    }
  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }
 
  submit(){
    debugger;
    if (this.validate()) {
      console.log("submit ");
      this.extraTimeOut.uniqueEmployeeId = this.uniqueEmployeeId;
      this.extraTimeOut.date = this.date;
      this.extraTimeOut.totalMinutes = this.convertMinutesToTimeFormat(this.totalMinutes);
      this.extraTimeOut.fromTime = this.fromTime;
      this.extraTimeOut.toTime = this.toTime;
      this.extraTimeOut.reason = this.reason.trim();
  
      console.log("this.uniqueEmployeeId ",this.uniqueEmployeeId);
      console.log("extraTimeOut ", this.extraTimeOut);

      console.log("this.extraTimeOut.totalMinutes ",this.extraTimeOut.totalMinutes);

      this.extraTimeOutService.saveExtraTimeOut(this.extraTimeOut).subscribe(
        data => {
          console.log("data ",data);
          console.log("data Message",data.successMessages[0]['key']);
          if(data.successMessages[0]['key'] == 'Applied Extra Time Out Successfully Submitted'){
            this.alertmessages("Successfully Submitted", "success")
          }else{
            this.alertmessages("Could Not Apply, No Sufficient Extra Time Out Balance", "danger")
          }
          // this.getBalanceExtraTimeOut(this.date);
        
          this.clear();
          this.getExtraTimeOutApplied();
        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "AlreadyExistsException".toLocaleLowerCase()) {
              this.alertmessages("A extra time out request has already been made for the selected date.", "danger")
            }
          }
          this.clear()
        }
      )
    }

  }
  clear() {
    this.extraTimeOut = new ExtraTimeOut(-1,-1," ", " "," "," ", " ", " ", " ", " ");
    this.date = undefined;
    this.totalMinutes = undefined;
    this.fromTime = undefined;
    this.toTime = undefined;
    this.reason = undefined;
    this.balanceExtraTimeOut = undefined;
  }

  getExtraTimeOutApplied() {
    this.extraTimeOutService.getExtraTimeOutApplied(this.uniqueEmployeeId).subscribe(data => {
      console.log("extra time out",data);
      this.extraTimeOutList = data.extraTimeOutApplied;
    });
  }
  getBalanceExtraTimeOut(date: any) {
    this.extraTimeOutService.getBalanceExtraTimeOut(this.uniqueEmployeeId,date).subscribe(data => {
      console.log("extra time out Balance",data);
      this.balanceExtraTimeOut = data.balanceETO;
      console.log("this.balanceExtraTimeOut",this.balanceExtraTimeOut);
    });
  }
  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.deleteExtraTimeOut = info;
    this.deleteExtraTimeOut.uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
    $('#showAlertVer').modal('toggle');
  }
  confirmDelete(){
    if (this.deleteExtraTimeOut.ExtraTimeOutAppliedId == -1) {
      this.alertmessages("Try again", "danger")
      return;
    }
    this.extraTimeOutService.deleteExtraTimeOutApplied(this.deleteExtraTimeOut).subscribe({
      next: (data) => {
        this.alertmessages(data.successMessages[0]['key'], "success")
        this.getExtraTimeOutApplied();
        this.getBalanceExtraTimeOut(this.date);
      },
      error: (error) => {
        console.log("Error ", error);
        this.alertmessages("Not Deleted", "danger")
      },
      complete: () => {
        this.closeMRQModal();
      }
    })
  }
  closeMRQModal() {
    $('#showAlertVer').modal('toggle');
  }

  
  calculateTotalMinutes(fromTime: string, toTime: string) {
    if (this.fromTime != undefined && this.toTime != undefined) {
    // Parse the time strings into Date objects
    const from = new Date(`1970-01-01T${fromTime}`);
    const to = new Date(`1970-01-01T${toTime}`);
  
    // Calculate the difference in milliseconds
    let diff = to.getTime() - from.getTime();
  
    // If the difference is negative, it means toTime is on the next day
    if (diff < 0) {
      this.alertmessages("To Time cannot be less than From Time.", "danger")
      // diff += 24 * 60 * 60 * 1000; // Add 24 hours
      diff = 0;
    }
  
    // Convert milliseconds to minutes
    const totalMinutes = Math.floor(diff / (1000 * 60));
  
    this.totalMinutes=totalMinutes;
  }
}

convertMinutesToTimeFormat(totalMinutes: string): string {
  const minutes = parseInt(totalMinutes, 10);
  
  // Calculate hours and remaining minutes
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  // Format as HH:mm, adding leading zeros if necessary
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
}

convertTime(timeString: string): string {
  if (timeString) {
   
    const timeParts = timeString.split(':');
    const date = new Date();
    date.setHours(+timeParts[0], +timeParts[1], +timeParts[2]);
    
    // Format the date using DatePipe
    return this.datePipe.transform(date, 'hh:mm a') || '';
  }
  return '';
}
}
