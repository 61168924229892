import { Component, OnInit, Renderer2 } from '@angular/core';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { ActivatedRoute } from '@angular/router';
import { DepartmentInfo } from '../models/departmentInfo';
import { DatePipe, Time} from '@angular/common';
import { CommonService } from '../services/common.service';
import { OvertimeReportService } from '../services/otreport.service';
import { OvertimeReportInfo } from '../models/overtimeReport-info.model';
import { EmployeeLeaveService } from '../services/employee-leave.service';

declare var $: any;
@Component({
  selector: 'app-overtimereport',
  templateUrl: './overtime-report.component.html',
  styleUrls: ['./overtime-report.component.css']
})

export class OverTimeReportComponent implements OnInit {
    currentDate: string;
    startDate: boolean; endDate: boolean;
    submitted: boolean; fromDate: string;
    toDate: string; department: number = 0;
    limitPara: any; offsetPara: any;
    uempid: string; employeename: string;
    queryName: any;  query: any;
    permissionList: any[] = [];
    departments: DepartmentInfo[];
    otreportinfo: OvertimeReportInfo[];
    employeeMasterLucene: EmployeeMaster[];
    min: string;

    constructor(private common: CommonService,private overtimereportService: OvertimeReportService,
     private empLeaveService: EmployeeLeaveService,private route: ActivatedRoute,private render: Renderer2,
     private datepipe: DatePipe) {

      const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
     }

        ngOnInit() {
          this.getdepartment();
           
          this.limitPara = 5;
          this.offsetPara = 0;

            (<HTMLInputElement>document.getElementById('employeename')).focus();

            const queryParams = this.route.snapshot.queryParams;
        
            console.log("queryParams : ", queryParams)
            console.log("queryParams type : ", typeof queryParams)
        
            const maxString = sessionStorage.getItem('permission').replace('[', '');
            const maxStringBox = maxString.replace(']', '');
            const maxStringAgain = maxStringBox.replace(/"/g, '');
            this.permissionList = maxStringAgain.split(',');
        
        
            this.render.listen(document.getElementById('navigatetarget'), 'click', () => {
              $('html,body').animate({
                scrollTop: $(`#navigatedest`).offset().top - 90
              },
                'slow');
            })
        }

        scrollToBottom() {
          const element = document.getElementById('navigateclient'); // Replace with the actual ID of your search results container
          element.scrollTop = element.scrollHeight;
      }
        scrollLength() {
          console.log("dynamic height on scroll ");
          console.log("");
          const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
          const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
          const computedHeight = height.match(regex);
          console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
          if (computedHeight && Number(computedHeight[1]) < 450) {
            let value: any = Number(computedHeight[1]);
            console.log("default value = ", value)
            value = value + (2 * 55);
            value = value + "px";
            console.log("final vl:", value)
            console.log("transition")
            document.getElementById("scrolllength").style.maxHeight = value
          }
          console.log("");
        }
        onScroll() {
          this.limitPara = this.limitPara + 5;
          this.offsetPara = this.offsetPara + 13;
          this.searchOTReport();
        }
        overtimereportCSVDownload() {
            this.overtimereportService.downloadOTReport( this.fromDate,this.toDate ,this.employeename,this.department).subscribe(data => {
              // this.uniqueemployeeid = null;
              var file = new Blob([data], { type: 'application/vnd.ms-excel' });
              var fileURL = URL.createObjectURL(file);
              var anchor = document.createElement("a");
              anchor.download = "OvertimeReport.xls";
              anchor.href = fileURL;
              anchor.click();
              console.log("anchor.download"+anchor.download);
              console.log("data"+data);
              console.log("fileURL"+fileURL);
            },
              error => {
                console.log("Error Occured ", error);
              })
          }


        limitpara() {
          this.offsetPara = 0;
        }

        searchOTReport(){
          this.overtimereportService.getovertimeRepot(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),this.employeename,this.department).subscribe(//,this.limitPara,this.offsetPara
           ( data) =>{
            // debugger;
            this.otreportinfo = data.overtimeReportInfo;
            this.otreportinfo.forEach(item => {
              const minutes = item.otDifference; // Example: 150 minutes

              const totalMinutes = `${minutes}`;
            
              console.log("minutes", minutes);
              console.log("totalMinutes", totalMinutes);
              // Convert totalMinutes back to a number
              this.min =totalMinutes;
               item.min=this.min;
              console.log("item.otDifference", this.min);
            });
            $('html,body').animate({
              scrollTop: $(`#navigatedest`).offset().top - 90
            },
              'slow');
            },

           (error) =>{
            console.log(error)
           }
          );
        }

        getdepartment() {
          this.common.getSearchdeparment1().subscribe({
            next: (res) => {
              this.departments = res;
            },
            error: (e) => {
              console.log("error occured while getting all the departments")
            }
          })
        }

        modelDepartment(e) {
          console.log(this.department, e)
          this.limitPara = 10;
          this.offsetPara = 0;
        }

        alertmessages(): void {
          let alertDiv = document.createElement('div');
          alertDiv.innerHTML = ` <div class="alert alert- mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
            <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i></div>`
          let alertcomp = document.querySelector('.alertcomp');
          alertcomp.append(alertDiv);
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        
          // setTimeout(function () {
          //   alertcomp.removeChild(alertDiv);
          // }, 5000);
          }

          checkFromAndToDate() {
            this.startDate = false;
            this.endDate = false;
            this.submitted = true;
            const currentDate = new Date();
            console.log("this.fromDate", this.fromDate);
            console.log("this.toDate ", this.toDate);
            if (this.toDate) {
              if (this.fromDate > this.toDate) {
                this.submitted = false
                this.endDate = true;
              } else {
                this.endDate = false;
                return true;
              }
            }
          }

          clear(){
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            this.currentDate = `${year}-${month}-${day}`;
            this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
            const lastDay = new Date(year, month, 0).getDate();
            this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
            this.employeename = null;
            this.uempid=null;
            this.otreportinfo = null;
            this.getdepartment();
          }

          getEmployeeByNameOrId() {

            const [name, id] = this.employeename.split(' / ');
            this.queryName = name;
            console.log("Len ", this.query);
            if (this.queryName.length <= 2) {
              this.employeeMasterLucene = []
            }
            if (this.queryName.length >= 3) {
        
              this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
                data => {
                  console.log("Emp Data ", data);
                  this.employeeMasterLucene = data.employeeMasterData
        
                },
                error => {
                  console.log("Error ", error);
        
                }
              )
            }
          }
}