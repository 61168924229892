import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AnnualHoliday } from '../models/AnnualHolidayInfo';
import { CommonService } from '../services/common.service';
import { EmployeeLeaveService } from '../services/employee-leave.service';

@Component({
  selector: 'app-cust-calender',
  templateUrl: './cust-calender.component.html',
  styleUrls: ['./cust-calender.component.css']
})
export class CustCalenderComponent implements OnInit {
	// @Input() chartColorsMap: Map<string , string>;

	annualhoildaylist:AnnualHoliday[];
	employeeLeaveList: any[];
	employeeLeaveName:string[] = []
	empWeeklyOffList:string[] = []
	daysTag 
	currentDate 
	prevNextIcon 
	weeklyOff:string

	// getting new date, current year and month
	date 
	currYear
	currMonth 

	firstDay
	lastDay

	// storing full name of all months in array
	months 

	colorList:any[]

	chartColorMap = new Map();

	calDays = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"]

	constructor(private commonService:CommonService,private empLeaveService: EmployeeLeaveService, private datePipe:DatePipe) { }

	ngOnInit(): void {
		// this.colorList = ['#33c5ff', '#f7284a','#01c353','#fe7700','#c3c001','#33e0ff','#6574cd','#ef4eb8','#ecb403','#17a2b8'];
		this.colorList = this.empLeaveService.getChartColors();
		// this.getAnnualHoildayList()
		this.getCalendarHolidayList()

		this.daysTag = document.querySelector(".dayss");
		this.currentDate = document.querySelector(".current-date");
		this.prevNextIcon = document.querySelectorAll(".icons span");

		// getting new date, current year and month
		this.date = new Date();
		this.currYear = this.date.getFullYear();
		this.currMonth = this.date.getMonth();

		this.firstDay = this.datePipe.transform( new Date(this.currYear, this.currMonth, 1) , 'yyyy-MM-dd');
		this.lastDay =this.datePipe.transform(  new Date(this.currYear, this.currMonth + 1, 0) ,'yyyy-MM-dd');
		// console.log(`First Day ${this.firstDay} - last Day ${this.lastDay}`);
		
		this.getEmpWeeklyOff(this.firstDay,this.lastDay);

		// storing full name of all months in array
		this.months = ["January", "February", "March", "April", "May", "June", "July",
					"August", "September", "October", "November", "December"];

		this.renderCalendar();


		this.prevNextIcon.forEach(icon => { // getting prev and next icons
			icon.addEventListener("click", () => { // adding click event on both icons
				// if clicked icon is previous icon then decrement current month by 1 else increment it by 1
				
				this.currMonth = icon.id === "prev" ? this.currMonth - 1 : this.currMonth + 1;

				if(this.currMonth < 0 || this.currMonth > 11) { // if current month is less than 0 or greater than 11
					// creating a new date of current year & month and pass it as date value
					this.date = new Date(this.currYear, this.currMonth);
					this.currYear = this.date.getFullYear(); // updating current year with new date year
					this.currMonth = this.date.getMonth(); // updating current month with new date month
				} else {
					this.date = new Date(); // pass the current date as date value
				}
				
				this.firstDay = this.datePipe.transform( new Date(this.currYear, this.currMonth, 1) , 'yyyy-MM-dd');
				this.lastDay =this.datePipe.transform(  new Date(this.currYear, this.currMonth + 1, 0) ,'yyyy-MM-dd');
				// console.log(`Change --> First Day ${this.firstDay} - last Day ${this.lastDay}`);
				this.getEmpWeeklyOff(this.firstDay,this.lastDay);
				this.renderCalendar(); 
				// this.getAnnualHoildayList()
			});
		});
	}

	// ngOnChanges(changes: SimpleChanges) {
	// 	console.log("---------------------NG ONCHANGE");
		
	// 	this.chartColorsMap = changes.chartColorsMap.currentValue;
		
	// 	this.renderHoliday()
		
	// }
	getEmpWeeklyOff(firstDay:any,lastDay:any){
		this.empLeaveService.getEmpWeeklyOffByDate(firstDay,lastDay).subscribe(
			data=>{
				// console.log("Emp Weekly Off ",data);
				this.empWeeklyOffList = data.employeeWeeklyOffList
				if(this.empWeeklyOffList.length > 0){
					this.renderHoliday()
				}
			},
			error =>{
				console.log(error);
			}
		)
	}


	renderCalendar(){
		let firstDayofMonth = new Date(this.currYear, this.currMonth, 1).getDay(), // getting first day of month
		lastDateofMonth = new Date(this.currYear, this.currMonth + 1, 0).getDate(), // getting last date of month
		lastDayofMonth = new Date(this.currYear, this.currMonth, lastDateofMonth).getDay(), // getting last day of month
		lastDateofLastMonth = new Date(this.currYear, this.currMonth, 0).getDate(); // getting last date of previous month
		let liTag = "";
		let dayIdx = 0;
		//Get the 1st Date of current month
		let firstDate = new Date(this.currYear, this.currMonth, 1);
		

		//Check day of 1st date, Returns 0-6 -> [0:Sun, 1:Mon , 2:Tue ....]
		let firstDay = firstDate.getDay()
		dayIdx = firstDay;

		for (let i = firstDayofMonth; i > 0; i--) { 
			// creating li of previous month last days
			liTag += `<li class="inactive">${lastDateofLastMonth - i + 1}</li>`;
		}

		for (let i = 1; i <= lastDateofMonth; i++) { // creating li of all days of current month
			// adding active class to li if the current day, month, and year matched
			let isToday = i === this.date.getDate() && this.currMonth === new Date().getMonth() 
							&& this.currYear === new Date().getFullYear() ? "active" : "";

							
			liTag += `<li class="${isToday}" id="active_date" data-date=${`${this.currYear}-${this.currMonth+1}-${i}`} data-day=${this.calDays[dayIdx++]} id =${i}> <span class="test">${i}</span>  </li>`;
			if(dayIdx === 7){
				dayIdx = 0;
			}
		}

		for (let i = lastDayofMonth; i < 6; i++) { // creating li of next month first days
			liTag += `<li class="inactive">${i - lastDayofMonth + 1}</li>`
		}
		this.currentDate.innerText = `${this.months[this.currMonth]} ${this.currYear}`; // passing current mon and yr as currentDate text
		this.daysTag.innerHTML = liTag;

		
		if( this.annualhoildaylist?.length > 0 || this.employeeLeaveList?.length > 0){
			this.renderHoliday()
		}
	}

	renderHoliday() {

		
		let annualLeaveClassesToAdd = [ 'annualLeave' ];
		let empLeaveClassesToAdd = [ 'empLeave' ];
		let lastDateofMonth = new Date(this.currYear, this.currMonth + 1, 0).getDate(); // getting last date of month

		for (let i = 1; i <= lastDateofMonth; i++) { // creating li of all days of current month
			// adding active class to li if the current day, month, and year matched
			
			let dayelement = document.getElementById(`${i}`)

			if(i === this.date.getDate() && this.currMonth + 1 === new Date().getMonth() + 1
			&& this.currYear === new Date().getFullYear() ){
				let dd = dayelement.children.item(0);
				dd.classList.add("currentdate")
				dd.setAttribute('data-title','Current Date')
				dd.setAttribute("style",`color:#333; font-weight: 600`);
			}
			
			//WeeklyOFf
			// let day = dayelement.dataset.day;
			let date = dayelement.dataset.date;
			
			if(this.isEmpWeekOff(date)){
				let dd = dayelement.children.item(0);
				dd.classList.add("weekOff")
				dd.setAttribute('data-title' , "Week Off");
				dd.setAttribute("style",`color:#3366ff; font-weight: 600`);
			}
			
			// if(day.toLowerCase() === this.weeklyOff.toLowerCase()){
			// 	let dd = dayelement.children.item(0);
			// 	dd.classList.add("weekOff")
			// 	dd.setAttribute('data-title' , "Week Off");
			// 	dd.setAttribute("style",`color:#3366ff; font-weight: 600`);
			// }

			//Check for Annual Holiday
			if( this.isAnnualLeave(Number(dayelement.innerText),this.currMonth + 1,this.currYear)){
				let reason = this.isAnnualLeave(Number(dayelement.innerText),this.currMonth + 1,this.currYear);
				let dd = dayelement.children.item(0);
				
				dd.classList.add(...annualLeaveClassesToAdd)
				dd.setAttribute('data-title' , reason);
				dd.setAttribute("style",`color:#fff;background-color:#3366ff;font-weight: 600`);
			}
			
			//Check for Employee Leave
			if( this.isEmpLeave(Number(dayelement.innerText),this.currMonth + 1,this.currYear)){
				let reason = this.isEmpLeave(Number(dayelement.innerText),this.currMonth + 1,this.currYear);
				
				let dd = dayelement.children.item(0);
				
				dd.classList.add(...empLeaveClassesToAdd)
				dd.setAttribute('data-title' , reason);
				
				dd.setAttribute("style",`color:#fff;font-weight: 600;background-color:${this.chartColorMap?.get(reason)};`);
				
				
				
				
				// style.backgroundColor = this.chartColorsMap.get(reason)
				
				// let styles =window.getComputedStyle(dayelement , "::before" )
				// console.log(styles.backgroundColor)
				// styles.setProperty('backgroundColor' , "yellow")
				// dayelement.style.backgroundColor = this.chartColorsMap.get(reason)
				// dayelement.style.borderRadius="4px"
				// dayelement.style.margin="2px"
				// dayelement.style.color="#9B59B6"
				
			}
		}

	}

	isAnnualLeave(curDay, curMonth, curYear){
		let reason = "";
		for (let i = 0; i <  this.annualhoildaylist.length; i++) {
			let date = new Date(this.annualhoildaylist[i].date)
			
			if(date.getDate() === curDay && date.getMonth() + 1 === curMonth && date.getFullYear() === curYear){
				reason = this.annualhoildaylist[i].reason; 
				break;
			}
		}
		return reason;
	}

	isEmpLeave(curDay, curMonth, curYear){
		let reason = "";
		for (let i = 0; i <  this.employeeLeaveList.length; i++) {
			let date = new Date(this.employeeLeaveList[i].date)
			
			if(date.getDate() === curDay && date.getMonth() + 1 === curMonth && date.getFullYear() === curYear){
				reason = this.employeeLeaveList[i].leaveName; 
				break;
			}
		}
		return reason;
	}

	isEmpWeekOff(curDate):boolean{
		let weekOff:boolean = false

		const cDate = curDate.split('-');
		// Please pay attention to the month (parts[1]); JavaScript counts months from 0:
		// January - 0, February - 1, etc.
		const currDate = new Date(cDate[0], cDate[1] - 1, cDate[2]);
		
		// const currDate = new Date(curDate);
		
		// console.log("Current Date ========================== ",currDate);

		for(let i = 0; i < this.empWeeklyOffList?.length;i++){
			// let date = this.empWeeklyOffList[i];
			const dDate:any = this.empWeeklyOffList[i].split('-');
			// const date = new Date(this.empWeeklyOffList[i]);
			const date = new Date(dDate[0], dDate[1] - 1, dDate[2]);

			// console.log("Each Date ========================== ",date);
			// console.log(`${currDate} - ${date}`);
			
			if(date.toDateString() === currDate.toDateString()){
				weekOff = true;
				break;
			}
		}
		return weekOff;
	}

	getCalendarHolidayList(){
		this.commonService.getCalendarHolidays().subscribe(
			data=>{
				this.annualhoildaylist = data.calendarHolidayList.annualHolidayList
				this.employeeLeaveList = data.calendarHolidayList.employeeLeaveList
				this.employeeLeaveName = data.calendarHolidayList.leaveNames
				this.weeklyOff = data.calendarHolidayList.weeklyOff
				// console.log("Annual ",this.annualhoildaylist);
				// console.log("Emp List ",this.employeeLeaveList);
				// console.log("Leave NAmes ",this.employeeLeaveName);
				
				if( this.annualhoildaylist.length > 0 || this.employeeLeaveList.length > 0 || this.employeeLeaveName){
					this.employeeLeaveName.forEach((leave,i) => {
						
						this.chartColorMap.set(leave,this.colorList[i])
					});
					this.renderHoliday()
				}
			},
			error =>{
				console.log(error);
			}
		)
	}

	

}

// let annualele =  document.getElementById(`${date}-${month}-${year}`)
//       // console.log(annualele)
//         if(annualele){
//           annualele.style.backgroundColor = "#255BFB"
//           annualele.style.color = "white"
//           // annualele.setAttribute('title', holiday.reason)
//           annualele.setAttribute('class', 'annualholiday')
//           annualele.setAttribute('data-title' , holiday.reason)
//         }

// td.annualholiday{
//     position:relative;
// }
// td.annualholiday:hover::before{
//     content: attr(data-title);
//     position:absolute;
//     background-color: black;
//     width:136%;
//     top: -42px;
//     left:-10px;
//     border-radius: 20%;
// }