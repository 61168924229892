<div class="row">
    <div class="col-xl-12 col-md-12 col-lg-12">

        <div class="card">
            <div class="card-header  border-0">
                <h4 class="card-title">Consolidated Report</h4>
                <button type="button" class="btn btn-primary ms-auto"
                    (click)="attendanceReportCSVDownload('Consolidated Report')">
                    <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                </button>
            </div>

            <!-- - make the count of : leave, absent, odd punches: show the dates and counts in popup. -->

            <div class="card-body">
                <div class="col-xs-12 mobile view">
                    <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                        <table class="table  table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                            <thead class="tablerow stickyatTop">
                                <tr class="rowcolors stickyatTop">
                                    <th class="border-bottom-0 w-5 fs-6 ">Employee ID</th>
                                    <th class="border-bottom-0 w-5 fs-6 fixedcolumn backblur secondcolumn">Name</th>
                                    <th class="border-bottom-0 w-5 fs-6 fixedcolumn backblur secondcolumn">Branch</th>
                                    <th class="border-bottom-0 w-5 fs-6">Total Days</th>
                                    <th class="border-bottom-0 w-5 fs-6">Present</th>
                                    <th class="border-bottom-0 w-5 fs-6">Approved Leave</th>
                                    <th class="border-bottom-0 w-5 fs-6">Absent</th>
                                    <th class="border-bottom-0 w-5 fs-6">Odd Punch</th>
                                    <th class="border-bottom-0 w-5 fs-6">Weekly Holidays</th>
                                    <th class="border-bottom-0 w-5 fs-6">Annual Holidays</th>
                                    <th class="border-bottom-0 w-5 fs-6">Extra day</th>
                                    <th class="border-bottom-0 w-5 fs-6">Leave Balance</th>
                                    <th class="border-bottom-0 w-5 fs-6">OT Hours</th>
                                    <th class="border-bottom-0 w-5 fs-6">OT Pieces</th>
                                    <th class="border-bottom-0 w-5 fs-6">Penalty</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let report of consolidatedReport;let i = index">
                                    <td data-th="contractclientName">
                                        {{report.empID}}
                                    </td>
                                    <td class="fixedcolumn secondcolumn backgroundFW backgroundSW"
                                        data-th="contractclientName">
                                        {{report.name}}
                                    </td>
                                    <td data-th="contractclientName">
                                        {{report.branchName ? report.branchName : '--'}}
                                    </td>
                                    <td data-th="contractclientName">{{report.daysInMonth}}
                                    </td>
                                    <td data-th="contractclientName">{{report.daysPresent}}
                                    </td>

                                    <td class="w-100" data-th="contractclientName">
                                        <button (click)="consolidate_to_deatiled(report.empId,report.name, i)"
                                            class="consolidatebtn consolidatebtn1 btn w-100" data-bs-toggle="modal"
                                            data-bs-target="#container_1">
                                            {{ report.leaveTaken }}
                                        </button>
                                    </td>

                                    <td class="w-100" data-th="contractclientName">
                                        <button (click)="consolidate_to_deatiled(report.empId, report.name,i)"
                                            class="consolidatebtn consolidatebtn2 btn w-100" data-bs-toggle="modal"
                                            data-bs-target="#container_2">
                                            {{report.daysAbsent}}

                                        </button>
                                    </td>

                                    <td class="w-100" data-th="contractclientName">
                                        <button (click)="consolidate_to_deatiled(report.empId, report.name,i)"
                                            class="consolidatebtn consolidatebtn3 btn w-100" data-bs-toggle="modal"
                                            data-bs-target="#container_3">
                                            {{report.oddPunch}}
                                        </button>
                                    </td>

                                    <td data-th="contractclientName">{{report.weeklyHolidays}}
                                    </td>
                                    <td data-th="contractclientName">{{report.annualHoliday}}
                                    </td>
                                    <td data-th="contractclientName">{{report.extraDays}}
                                    </td>
                                    <td data-th="contractclientName">{{report.leaveBalance}}
                                    </td>
                                    <td data-th="contractclientName">{{report.otHours}}
                                    </td>
                                    <td data-th="contractclientName">{{report.otPieces}}
                                    </td>
                                    <td class="w-100" data-th="contractclientName">
                                        <button
                                            (click)="consolidate_to_deatiled(report.empId, report.name,i,report.remarks)"
                                            class="btn btn-primary btn-icon btn-sm me-2" style="width: 32px;"
                                            data-bs-toggle="modal" data-bs-target="#container_4">
                                            <i class="fa fa-plus-square" data-bs-toggle="tooltip"
                                                data-original-title="penaltyremark"></i>
                                        </button>


                                    </td>

                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>






            <!-- <div class="card-body">
                <div class="col-xs-12 mobile view">
                    <div class="scrollsearchresults" id="scrolllength" infiniteScroll
                        [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                        [infiniteScrollThrottle]="100" [scrollWindow]="false"
                        id="scrolllength">
                        <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                            id="hr-table">
                            <thead class="tablerow stickyatTop">
                                <tr class="rowcolors">
                                    <th class="border-bottom-0 fs-7">Employee ID</th>
                                    <th class="border-bottom-0 fs-7">Name</th>
                                    <th class="border-bottom-0 fs-7">Approved Leave</th>
                                    <th class="border-bottom-0 fs-7">Absent</th>
                                    <th class="border-bottom-0 fs-7">Odd Punch</th>
                                    <th class="border-bottom-0 fs-7">Total Days</th>
                                    <th class="border-bottom-0 fs-7">Present</th>
                                    <th class="border-bottom-0 fs-7">Extra day</th>
                                    <th class="border-bottom-0 fs-7">Weekly Holidays</th>
                                    <th class="border-bottom-0 fs-7">Annual Holidays</th>
                                    <th class="border-bottom-0 fs-7">Leave Balance</th>
                                    <th class="border-bottom-0 fs-7">OT Hours</th>
                                    <th class="border-bottom-0 fs-7">OT Pieces</th>
                                </tr>
                            </thead>

                            <tbody infiniteScroll [infiniteScrollDistance]="1.5" [infiniteScrollThrottle]="3000"
                                [scrollWindow]="false" id="navigatetarget">
                                <tr *ngFor="let report of consolidatedReport;let i = index" class="tr_table">
                                    <td data-th="ID" class="td_employeeid">

                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            {{report.empID}}<br />
                                        </div>

                                    </td>
                                    <td data-th="Name" class="td_employeeName">

                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            {{report.name}}</div>

                                    </td>
                                    <td data-th="session One" class="td_session_one td_session">
                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            <button (click)="consolidate_to_deatiled(report.empId,i)"
                                            class="consolidatebtn consolidatebtn1 btn w-100" data-bs-toggle="modal"
                                            data-bs-target="#container_1">
                                            {{ report.leaveTaken }}
                                        </button>
                                        </div>
                                    </td>
                                    <td data-th="session Two" class="td_session">
                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            <button (click)="consolidate_to_deatiled(report.empId,i)"
                                            class="consolidatebtn consolidatebtn2 btn w-100" data-bs-toggle="modal"
                                            data-bs-target="#container_2">
                                            {{report.daysAbsent}}
                                        </button>
                                        </div>
                                    </td>
                                    <td data-th="session Three" class="td_session">
                                         <div class="col-md-8 col-sm-12 col-xs-12">
                                            <button (click)="consolidate_to_deatiled(report.empId,i)"
                                            class="consolidatebtn consolidatebtn3 btn w-100" data-bs-toggle="modal"
                                            data-bs-target="#container_3">
                                            {{report.oddPunch}}
                                        </button>    
                                        </div>
                                    </td>
                                    <td data-th="session One" class="td_session_one td_session">
                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            {{report.daysInMonth}}
                                        </div>
                                    </td>
                                    <td data-th="session Two" class="td_session">
                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            {{report.daysPresent}}
                                        </div>
                                    </td>
                                    <td data-th="session Three" class="td_session">
                                         <div class="col-md-8 col-sm-12 col-xs-12">
                                            {{report.extraDays}}   
                                        </div>
                                    </td>
                                    <td data-th="session One" class="td_session_one td_session">
                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            {{report.weeklyHolidays}}
                                        </div>
                                    </td>
                                    <td data-th="session Two" class="td_session">
                                        <div class="col-md-8 col-sm-12 col-xs-12">
                                            {{report.annualHoliday}}
                                        </div>
                                    </td>
                                    <td data-th="session Three" class="td_session">
                                         <div class="col-md-8 col-sm-12 col-xs-12">
                                            {{report.leaveBalance}}
                                        </div>
                                    </td>
                                    <td data-th="OT" class="td_ot">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            {{report.otHours}}
                                        </div>
                                    </td>
                                    <td data-th="OT Type" class="td_ottype">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            {{report.otPieces}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> -->










        </div>
    </div>
</div>


<!-- pop up  -->
<div class="modal fade" id="container_1" tabindex="-1" aria-labelledby="additionalModalLabel1" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Approved Leave for {{popname}}
                </h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="mobile">
                        <div class="table-responsive"
                            style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                            <table class="table table-vcenter text-nowrap table-bordered border-bottom"
                                id="emp-attendance">
                                <thead class="tablerow stickyatTop">
                                    <tr class="rowcolors stickyatTop">
                                        <th class="border-bottom-0 w-5 fs-6 secondcolumn backgroundSW">Sl.No</th>
                                        <th class="border-bottom-0 w-5 fs-6">Approved Leave on</th>
                                        <th class="border-bottom-0 w-5 fs-6">Count</th>
                                        <th class="border-bottom-0 w-5 fs-6">Leave Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let attendance of detailedReport; let j = index">
                                        <ng-container
                                            *ngIf="attendance.leaveTaken === 0.5 || attendance.leaveTaken === 1">
                                            <tr>
                                                <td>{{j+1}}</td>
                                                <td>{{ attendance.date | date: 'dd-MM-yyyy' }}</td>
                                                <td data-th="contractclientName">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ attendance.leaveTaken }}
                                                </td>
                                                <td data-th="contractclientName">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ attendance.leaveType }}
                                                </td>

                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="container_2" tabindex="-1" aria-labelledby="additionalModalLabel2" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Days of Absence for {{popname}}
                </h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="mobile">
                        <div class="table-responsive"
                            style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                id="emp-attendance">
                                <thead class="tablerow stickyatTop">
                                    <tr class="rowcolors stickyatTop">
                                        <th class="border-bottom-0 w-5 fs-6 secondcolumn backgroundSW">Sl.No
                                        </th>
                                        <th class="border-bottom-0 w-5 fs-6">Date</th>
                                        <th class="border-bottom-0 w-5 fs-6">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let attendance of detailedReport;let j = index">
                                        <td>{{ j + 1 }}</td>
                                        <td>{{attendance.date | date : 'dd-MM-yyyy'}}</td>
                                        <td>
                                            <!-- will not show absent to future date // making consolidate report .. misss match calculation  -->
                                            <!-- <span style="font-size: 13px; font-weight: 500;"
                                                *ngIf=" attendance.daysPresent === 0 && attendance.afterCurrentDate === 0  && attendance.weeklyHolidays !== 1 &&  attendance.annualHoliday !== 1 &&  attendance.leaveTaken !== 1   && attendance.oddPunch !==1"
                                                class="badge btn-danger">
                                                Absent </span> -->
                                            <span style="font-size: 13px; font-weight: 500;"
                                                *ngIf="attendance.daysPresent === 0.5 || attendance.leaveTaken === 0.5"
                                                class="badge bg-warning">
                                                Half Day
                                            </span>
                                            <span style="font-size: 13px; font-weight: 500;"
                                                *ngIf="  attendance.leaveTaken !== 1   && attendance.daysPresent !==1  && attendance.weeklyHolidays !== 1 &&  attendance.annualHoliday !== 1 && attendance.oddPunch !==1"
                                                class="badge btn-danger">
                                                Absent </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="container_3" tabindex="-1" aria-labelledby="additionalModalLabel3" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Odd Punch for {{popname}}</h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="mobile">
                        <div class="table-responsive"
                            style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                id="emp-attendance">
                                <thead class="tablerow stickyatTop">
                                    <tr class="rowcolors stickyatTop">
                                        <th class="border-bottom-0 w-5 fs-6 secondcolumn backgroundSW">Sl.No
                                        </th>
                                        <th class="border-bottom-0 w-5 fs-6">Date</th>
                                        <th class="border-bottom-0 w-5 fs-6">Odd Punch</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let attendance of detailedReport;let j = index">
                                        <td>{{ j + 1}}</td>
                                        <td>{{attendance.date | date : 'dd-MM-yyyy'}}</td>
                                        <td data-th="contractclientName">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance.oddPunch === 0 ? "--" :
                                            attendance.oddPunch }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="container_4" tabindex="-1" aria-labelledby="additionalModalLabel4" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Penalty</h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="mobile">
                        <div class="table-responsive"
                            style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                id="emp-attendance">
                                <thead class="tablerow stickyatTop">
                                    <tr class="rowcolors stickyatTop">
                                        <th class="border-bottom-0 w-5 fs-6 secondcolumn backgroundSW">Sl.No
                                        </th>
                                        <th class="border-bottom-0 w-5 fs-6">Date</th>
                                        <th class="border-bottom-0 w-5 fs-6">Remark</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let attendance of detailedReport;let j = index">
                                        <td>{{ j + 1}}</td>
                                        <td>{{attendance.date | date : 'dd-MM-yyyy'}}</td>
                                        <td data-th="contractclientName">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{attendance?.remarks ? attendance.remarks :
                                            "--"}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>