import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs'
@Injectable({
    providedIn:'root'
})

export class TDSReportService{
 
    protected basePath = '/api';
    public defaultHeaders: Headers = new Headers();
    public url!: string;
    public colorList = ['#33c5ff', '#f7284a', '#01c353', '#fe7700', '#c3c001', '#33e0ff', '#6574cd', '#ef4eb8', '#ecb403', '#17a2b8'];
    public empLeave: string[] = []
    constructor(private httpclient: HttpClient, private commonService: CommonService) { }

    getTDSRepot(fromDate: any, toDate: any): Observable<any> {
        this.url = '/v1/getTDSReport';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
            queryParameters = queryParameters.set('fromDate', fromDate);
            queryParameters = queryParameters.set('toDate', toDate);
            // queryParameters = queryParameters.set('limitPara', limitPara);
            // queryParameters = queryParameters.set('offsetPara', offsetPara);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
      }

      downloadTDSReport(fromDate: string, toDate: string): Observable<any> {
        this.url = '/v1/downloadtDSReport';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
            queryParameters = queryParameters.set('fromDate', fromDate);
            queryParameters = queryParameters.set('toDate', toDate);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
    }
}
