<div class="col-lg-12 p-0">
  <div class="card overflow-hidden">

    <div class="position-relative">
      <div class="card-header p-0 px-4 d-flex justify-content-between" style="min-height:60px;max-height: 60px;">
        <h4 class="card-title">Annual Holidays</h4>
      </div>
    </div>

    <div class="pt-2 scroll " style="min-height:200px;max-height: 200px;">
      <div class="list-group">
        <div class="list-group-item d-flex p-0 px-3 m-1 border-0"
          *ngFor="let holiday of annualhoildaylist ;let i=index">
          <!-- pb-2 -->
          <div class="me-3 me-xs-0">
            <div class="calendar-icon icons">
              <div class="date_time " [ngClass]="i%2===0 ? 'bg-pink-transparent' : 'bg-success-transparent'"
                style="width: auto;">
                <span class="date" style="display: inline-block; margin-right: 5px;">{{holiday.date | date:'dd'}}
                  {{holiday.date | date:'MMM'}} {{holiday.date | date:'ccc'}}
                </span>
                <!-- <span class="month" style="display: inline-block; margin-right: 5px;">{{holiday.date | date:'MMM'}}
                </span>
                <span class="day" style="display: inline-block;">{{holiday.date | date:'cccc'}}</span> -->
              </div>
            </div>
          </div>
          <div class="ms-1 d-flex justify-content-center align-items-center">
            <div class="h5 fs-14 mb-1">{{holiday.reason}}</div>
            <!-- <small class="text-muted">attend the  company mangers...</small> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</div>