import { Component, OnInit } from '@angular/core';
import { Extratimeoutservices } from '../services/extratimeoutservices';
import { TaskDetailsForExtraTimeOut } from '../models/task-deatais-for-extraTimeOut';
import { OTWorkflow } from '../models/OTWorkflow';
import { ApproveExtraTimeOutPipe } from '../pipes/approve-extra-time-out.pipe';
import { TitleCasePipe } from '@angular/common';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
declare var $: any;
@Component({
  selector: 'app-approve-employee-overtime',
  templateUrl: './approve-employee-overtime.component.html',
  styleUrls: ['./approve-employee-overtime.component.css']
})
export class ApproveEmployeeOvertimeComponent implements OnInit {
  private APPROVED: string = "APPROVED";
  private REJECTED: string = "REJECTED";
  private OVERTIME_APPROVE_PENDING: string = "Overtime Approve Pending"
  private OVERTIME_CANCEL_PENDING: string = "Overtime Cancel Pending"
  private OVERTIME_CANCEL_APPROVED: string = "Overtime Cancel Approved"
  private OVERTIME_CANCEL_REJECTED: string = "Overtime Cancel Rejected"

  selectedList: any[] = [];
  selectAll: boolean = false;
  statusFilter: any = 'all';
  searchleave: any;
  searchLeaves: OTWorkflow[] = [];
  taskDetailsForovertime: TaskDetailsForExtraTimeOut[] = [];
  approvalovertime: TaskDetailsForExtraTimeOut[] = [];
  tempoverTimeDetailFlow: OTWorkflow[] = []
  overTimeApproval: OTWorkflow[] = [];
  uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
  overTimeOutDetailFlow: OTWorkflow[] = []
  query: any="";
	queryName: any="";
  employeeMaster: EmployeeMaster[];
  constructor(private extraTimeOutService: Extratimeoutservices, private empLeaveService: EmployeeLeaveService, approve: ApproveExtraTimeOutPipe,private titleCase: TitleCasePipe,){}


  ngOnInit(): void {
   
  }
  search() {
    this.getoverTime();

  }
   
  showTable = false
  getoverTime() {
    
    this.overTimeOutDetailFlow = [];
    this.tempoverTimeDetailFlow = [];
    this.extraTimeOutService.getovertime().subscribe(
      data => {
        console.log("get Leave for Approval ");
        if (this.statusFilter == 'all') {
          console.log("this.statusFilter ", this.statusFilter);
          this.overTimeOutDetailFlow = data.otapplied;
          console.log("Ot data",data);
          this.overTimeOutDetailFlow.forEach(item => {
            item.status = this.titleCase.transform(item.status);
          })
          this.tempoverTimeDetailFlow = this.overTimeOutDetailFlow;
          console.log("OT bean",this.overTimeOutDetailFlow);
        } else {
          console.log("this.statusFilter ", this.statusFilter);
          this.overTimeOutDetailFlow = data.otapplied;
          this.overTimeOutDetailFlow.forEach(item => {
            item.status = this.titleCase.transform(item.status);
          })
          this.overTimeOutDetailFlow = this.overTimeOutDetailFlow.filter(
            ele => ele.status == this.statusFilter
          );
          // this.overTimeOutDetailFlow=this.overTimeOutDetailFlow;
          this.tempoverTimeDetailFlow = this.overTimeOutDetailFlow;
        }
        if (this.overTimeOutDetailFlow.length > 0) {
          this.showTable = true
        }
        console.log("OT response ", this.overTimeOutDetailFlow);
        $('html,body').animate({
          scrollTop: $(`#navigatedest`).offset().top - 90
        },
          'slow');
      },
      error => {
        console.error("Error while getting Leave For Approval ", error);
      }
    )
  }


  chkInput(e: any) {
    console.log("chkinput ", this.searchleave);
    console.log("this.overTimeOutDetailFlow",this.overTimeOutDetailFlow);
   
    if (this.searchleave.length > 3) {
      console.log("call filter function ");
      this.overTimeOutDetailFlow = this.overTimeOutDetailFlow.filter(item =>
        item.employeename.toLowerCase().includes(this.searchleave.toLowerCase())
      );
      console.log(this.overTimeOutDetailFlow);
    }
    else {
      this.overTimeOutDetailFlow = this.tempoverTimeDetailFlow;
    
    }
  
 
}

checkAllSelected(e:any) { 
  console.log("checkAllSelected ");

    const checkboxes = document.querySelectorAll('input');
    if (e.target.checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = true;
        }
      }
      this.overTimeApproval.splice(0, this.overTimeApproval.length)
      console.log(" select all ", this.overTimeApproval);

      if (this.searchleave) {
        this.searchLeaves.forEach(task => {
          this.overTimeApproval.push()
        });
      } else {
        this.overTimeOutDetailFlow.forEach(task => {
          this.overTimeApproval.push(task)
        });
      }
      console.log(" check  all ", this.overTimeApproval.length);
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
      this.overTimeApproval.splice(0, this.overTimeApproval.length);
      console.log(" end  ", this.overTimeApproval.length);
    }
  
}

checkSelected(extra: any, e: any) {
  console.log(" extra time out check selected ");

  if (e.target.checked) {
    console.log(" before ", this.overTimeApproval.length);
    console.log(this.overTimeApproval);
    this.overTimeApproval.push(extra);
    console.log("after ", this.overTimeApproval.length);
    console.log("extra time out check selected after",this.overTimeApproval);
  } else {
    console.log("else part before ", this.overTimeApproval.length);
    console.log(this.overTimeApproval);
    this.overTimeApproval = this.overTimeApproval.filter(item => item.id!== extra.id);
    console.log("else part after ", this.overTimeApproval.length);
    console.log(this.overTimeApproval);
  }
  console.log("check selected done ");
 
}

 onApproveHandler() {

  let approve_reject = true;
  let flag = true
  if (this.overTimeApproval.length === 0) {
    this.alertmessages("Please select item for Approval.", "danger")
    flag = false
    return;
  }
  

  if (flag) {
    console.log("Approve extra time out 1");
    
  
    console.log(" this.overTimeApproval", this.overTimeApproval);

    // let isTotalMinEmpty = false;
    // this.overTimeApproval.forEach(onApprove => {
    //   console.log(" onApprove.totalMinutes", onApprove.totalMinutes);
    //   if (onApprove.totalMinutes === undefined || onApprove.totalMinutes === null || onApprove.totalMinutes === "" ) {
    //     isTotalMinEmpty = true;
    //     flag = false
    //     return;
    //   }
    // })
    // if (isTotalMinEmpty) {
    //   console.log("Approve extra time out ");
    //   this.alertmessages("OT Approval Minute is mandatory.", "danger")
    //   return;
    // }
 

      // if(this.OTApprovalMinutes!=undefined || this.OTApprovalMinutes == null){
        this.overTimeApproval.forEach(approval => {
          console.log("approval.OTMinutes",approval.OTMinutes);
          if(approval.OTMinutes !=undefined && approval.OTMinutes!=null && approval.OTMinutes !== ""){
            approval.totalMinutes = approval.OTMinutes;
  
            console.log("OT Applied",  approval.totalMinutes);
            console.log("OT Approved",  approval.OTMinutes);
          }
         
      });
         // }
    this.extraTimeOutService.overTimeForApproval(this.overTimeApproval, approve_reject).subscribe(
      data => {
        console.log("Approve extra time out",this.overTimeApproval);
        this.alertmessages("Successfully Approved.", "success")
        this.overTimeApproval.splice(0, this.overTimeApproval.length)
        this.searchleave = ""
        this.resetAllCheckBoxes()
        this.getoverTime()
      },
      error => {
        this.searchleave = ""
        console.error("Error ", error);
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          let err: string = response.errorMessages[0]['key'];
          if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
            this.alertmessages("A requests have already been processed.", "danger")
          } else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
            this.alertmessages("One or more requests have already been processed.", "danger")
          } else {
            this.alertmessages("Not Approved.", "danger")
          }
        } else {
          this.alertmessages("Not Approved.", "danger")
        }
        this.resetAllCheckBoxes()
        this.overTimeApproval.splice(0, this.overTimeApproval.length)
        this.getoverTime()
      }
    )
  }


    }  
 onRejectHandler() {
  console.log("Reject extra time out 1");
  let approve_reject = false;
  let flag = true
  if (this.overTimeApproval.length === 0) {

    console.log("Reject extra time out 1",this.overTimeApproval.length);
    this.alertmessages("Please select item for Rejection.", "danger")

    flag = false
    return;
  }

  let isCommentAbsent = false;
  this.overTimeApproval.forEach(rejectLeave => {
//
    if (rejectLeave.remark === undefined) {
      isCommentAbsent = true;
      flag = false
      return;
    }
  })

  if (isCommentAbsent) {
    console.log("Reject extra time out ");
    this.alertmessages("Comments are mandatory for Rejection.", "danger")
    return;
  }
  console.log("Reject extra time out ");
  let isTotalMinEmpty = false;
  this.overTimeApproval.forEach(onApprove => {

    if (onApprove.totalMinutes === undefined || onApprove.totalMinutes=="") {
      isTotalMinEmpty = true;
      flag = false
      return;
    }
  })

  if (isTotalMinEmpty) {
    console.log("Approve extra time out ");
    this.alertmessages("OT Approval Minute is mandatory for Rejection.", "danger")
    return;
  }

  if (flag) {
    this.extraTimeOutService.overTimeForApproval(this.overTimeApproval, approve_reject).subscribe(
      data => {
        this.alertmessages("Successfully Rejected.", "success")
        this.overTimeApproval.splice(0, this.overTimeApproval.length)
        this.searchleave = ""
        this.resetAllCheckBoxes()
        this.getoverTime()
      },
      error => {
        this.searchleave = ""
        console.error("Error ", error);
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          let err: string = response.errorMessages[0]['key'];
          if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
            this.alertmessages("A requests have already been processed.", "danger")
          } else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
            this.alertmessages("One or more requests have already been processed.", "danger")
          } else {
            this.alertmessages("Not Rejected.", "danger")
          }
        } else {
          this.alertmessages("Not Rejected.", "danger")
        }

        this.overTimeApproval.splice(0, this.overTimeApproval.length)
        this.resetAllCheckBoxes()
        this.getoverTime()
      }
    )

  }

    }

    resetAllCheckBoxes() {
      const checkboxes = document.querySelectorAll('input');
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
      this.searchleave = ""
      this.overTimeApproval.splice(0, this.overTimeApproval.length)
    }
  
    alertmessages(message: string, sign: string): void {
      let alertDiv = document.createElement('div');
      alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
      let alertcomp = document.querySelector('.alertcomp');
      alertcomp.append(alertDiv);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
  
      setTimeout(function () {
        alertcomp.removeChild(alertDiv);
      }, 5000);
    }

  
  
  }


