export class shiftMaster {
  shiftid: "";
  shiftName: '';
  fromTimings: '';
  toTimings: '';
  lateComing: '';
  earlyLeaving: '';
  shiftAllowance: '';
  otRule: '';
  otStartMins: '';
  otStartTime: '';
  dailyWorkHrs: '';
  sunHalfDayMins: '';
  sunFullDayMins: '';
  monHalfDayMins: '';
  monFullDayMins: '';
  tueHalfDayMins: '';
  tueFullDayMins: '';
  wedHalfDayMins: '';
  wedFullDayMins: '';
  thuHalfDayMins: '';
  thuFullDayMins: '';
  friHalfDayMins: '';
  friFullDayMins: '';
  satHalfDayMins: '';
  satFullDayMins: '';
  flexibleShift: '';
  breakList: breakDetail[] = [];
}

export class breakDetail {
  
  breakName: '';
  fromTimings: '';
  toTimings: '';
  breakMins: '';
  userId: '';
}

