import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { branches } from '../models/branches';
import { Configkey } from '../models/Configkey';
import { DepartmentInfo } from '../models/departmentInfo';
import { EmployeeDaily } from '../models/EmployeeDaily';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeSwipeInfo } from '../models/EmployeeSwipeInfo';
import { AlertService } from '../services';
import { CustomerService } from '../services/customer.service';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service';
import { NgForm } from '@angular/forms';
import { AttendanceReportComponent } from '../attendance-report/attendance-report.component';
declare var $: any;

@Component({
  selector: 'app-employee-attendence-proxy',
  templateUrl: './employee-attendence-proxy.component.html',
  styleUrls: ['./employee-attendence-proxy.component.css']
})
export class EmployeeAttendenceProxyComponent implements OnInit {

  public lat;
  public lng;

  logRole: any;
  logEmpid: any;

  p: Number = 1;
  count: any;
  recordCount: number;
  recordPerPage: number;

  permissionList: any[] = [];
  empInfo: EmployeeDaily[];
  branchInfo: branches[];
  departmentInfo: DepartmentInfo[];
  // unFilteredDepartment = [];
  // filteredDepartment = [];
  departmentid: any = 0;
  departmentidOld: any = 0;
  branchid: any;
  branchidOld: any;
  branchname: any;

 


  curDate: string;
  OTarray = ['Hours', 'Pieces'];
  keyList: any[] = ['AMIN', 'AMOUT', 'PMIN', 'PMOUT', 'NSIN', 'NSOUT'];

  amIn: any;
  amOut; any;

  pmIn: any;
  pmOut: any;

  nsIn: any;
  nsOut: any;

  configkeyValueList: Configkey[];

  showBasedOnPermissionDate: boolean = true;
  showBasedOnPermissionBranch: boolean = false;
  showBasedOnPermissionDepartment: boolean = false;

  //new code
  employeeSwipeInfo: EmployeeSwipeInfo;
  employeeMaster: EmployeeMaster;
  offset: number = 0;
  limit: number = 10;

  activeExitFilters: string = "Active";

  //plus icon
  enablePlusButton: boolean = false;
  penaltyCheckbox: boolean = false;
  remarks: string;
  selectedEmpObjforPenalty: EmployeeDaily = new EmployeeDaily();

  @ViewChild(AttendanceReportComponent) attendanceReportComponent;

  triggerChild() {
    console.log("trigger child employee attendane proxy ");
    this.attendanceReportComponent.changeDates();
  }

  constructor(private render: Renderer2, private router: Router, private employeeService: EmployeeAttendenceProxyService, private alertservice: AlertService,
    private datepipe: DatePipe, private customerService: CustomerService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.count = 20;
    this.recordPerPage = 20;
    this.empInfo = new Array<EmployeeDaily>();
    this.curDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');


    this.getMonthStartAndEnd(this.curDate)
    this.branchid = sessionStorage.getItem('branchId');
    (<HTMLInputElement>document.getElementById('searchDate')).value = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    // (<HTMLInputElement>document.getElementById('butSave')).disabled = true;

    this.lat = 0;
    this.lng = 0;

    // get the permisson from application session 
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');

    this.getCurrentLocCoords(); // get and check location disable or enable  latitude and longitude
    this.getAllBranches(); //get all locations from location table
    this.getAllDepartment(); //get all department from department table

    this.logRole = sessionStorage.getItem('role');
    this.logEmpid = sessionStorage.getItem('empId');

    this.getConfigkeyValueList();

    if (this.logRole == 'Administrator' && this.logEmpid == 0) { }
    else {
      this.getEmpDailyDetails(this.activeExitFilters);
    }

    // check the access for user 
    this.checkAccesForUser();

  }

  matrixPopup() {
    $("#plusModal2").modal("show");
  }

  checkAccesForUser() {
    // check permission list has the permission "access link"

    console.log(this.permissionList);
    this.showBasedOnPermissionDate = this.permissionList.includes('ng.attendance.proxy.show.date');
    this.showBasedOnPermissionBranch = this.permissionList.includes('ng.attendance.proxy.show.branch');
    this.showBasedOnPermissionDepartment = this.permissionList.includes('ng.attendance.proxy.show.department');
  }

  // on load get work branches
  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchsActive().subscribe(
      data => {
        this.branchInfo = data.branches;
      },
      error => {
      }
    );
  }

  // on load get department
  getAllDepartment() {
    this.employeeService.getAllDepartment(sessionStorage.getItem('empId')).subscribe(
      data => {
        this.departmentInfo = data.departmentArrayList;
      },

      error => {
      }
    );
  }

  // Get time list
  getConfigkeyValueList() {
    this.customerService.getConfigDataList(this.keyList).subscribe(
      data => {
        console.log("Get data from config file", data)
        this.amIn = data.configListData[0].configvalue;
        this.amOut = data.configListData[1].configvalue;
        this.pmIn = data.configListData[2].configvalue;
        this.pmOut = data.configListData[3].configvalue;
        this.nsIn = data.configListData[4].configvalue;
        this.nsOut = data.configListData[5].configvalue;
      },

      error => {
      }
    );
  }

  // added activeExitFilter : to check employee in db ' leaveingdate < now () '
  getEmpDailyDetails(activeExitFilters) {
    console.log("limit : ", this.limit, " offset limit : ", this.offset)

    this.employeeService.getEmpDailyData(this.curDate, sessionStorage.getItem('empId'), this.branchid, 1, this.departmentid, this.limit, this.offset, activeExitFilters).subscribe(
      info => {

        if (this.departmentid !== this.departmentidOld) {
          this.empInfo = [];
          this.departmentidOld = this.departmentid;
        }

        if (this.branchid !== this.branchidOld) {
          this.empInfo = [];
          this.branchidOld = this.branchid;
        }

        if (this.offset == 0) {
          this.empInfo = info.employeeAttendanceProxyArrayList;
        } else {
          this.empInfo = this.empInfo.concat(info.employeeAttendanceProxyArrayList);
        }
        // this.empInfo = info.employeeAttendanceProxyArrayList;
        console.log(this.empInfo)
        this.recordCount = this.empInfo.length;
        // console.log(this.recordCount);
        // if (this.recordCount == 0)
        // (<HTMLInputElement>document.getElementById('butSave')).disabled = true;
        // else
        // (<HTMLInputElement>document.getElementById('butSave')).disabled = false;
        // this.onScrollToTop();
        this.enablePenaltyBtnforAllEmployee(this.empInfo)
        this.fillDate();
        // console.log("inside search reset ")
        // this.resetLimit();

        $('html,body').animate({
          scrollTop: $(`#navigatedest1`).offset().top - 90
        },
          'slow');
      },
      error => {
        console.log(error);
      }
    );
  }

  //save emp details
  saveEmpWorkDetails() {
    
    console.log(this.empInfo)

    for (let emp of this.empInfo) {
      // console.log(this.lat , this.lng)
      emp.latitude = this.lat;
      emp.longitude = this.lng;
    }
   

    this.employeeService.saveEmpDailyData(this.empInfo).subscribe(
      data => {
        console.log("response", data)

        this._snackBar.open("Employee attendance saved", 'Okay', {
          duration: 2000,
        });
        // if (data.successMessages != null && data.successMessages.length !== 0) {
        //   this.alertservice.success(data.successMessages[0]['key']);


        //   if (this.logRole == 'Administrator' && this.logEmpid == 0) //admin logged in without emp id
        //     this.getSelectedBranch();
        //   else
        //     this.getEmpDailyDetails((<HTMLInputElement>document.getElementById('searchDate')).value);

        // }
      },
      error => {
        console.log("response", error)
        this._snackBar.open("Employee attendance not saved!", 'Okay', {
          duration: 2000,
        });
        // const response = error.error;
        // if (response.errorMessages != null && response.errorMessages.length !== 0) {
        //   this.alertservice.error(response.errorMessages[0]['key']);
        //   (<HTMLInputElement>document.getElementById('butSave')).disabled = false;
        // }
      });

  }

  // get lat lng
  getCurrentLocCoords() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          if (position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
          }
        },
        (error) => {
          console.log(error);
          this.handleGeolocationError(error);
        }
      );
    } else {
      alert("Geolocation is not supported by this Browser.");
    }
  }

  // handel error in getcurrentlocation 
  handleGeolocationError(error: any) {
    if (error.code === 1) {
      alert("Permission denied for Geolocation. Please enable it in your Browser Settings.");
    } else if (error.code === 2) {
      alert("Geolocation is Temporarily Unavailable. Please try again later.");
    } else if (error.code === 3) {
      alert("Timeout while retrieving Geolocation. Please try again.");
    } else {
      alert("An error occurred while enabling Geolocation. Please check your settings and try again.");
    }
  }

  // enable it when u have got logic to open promt 
  // EnableLocation() {
  //   const chromeSettingsURL = 'chrome://settings/content/location';
  //   window.open(chromeSettingsURL, '_blank');
  // }

  getSelectedDept(event) {
    // (<HTMLInputElement>document.getElementById('locationInput')).value = "";

    // let val = (<HTMLInputElement>document.getElementById('deptInput')).value;
    // let opts = (document.getElementById('deptId')) as HTMLSelectElement;

    console.log("Random one >>", event)
    // console.log("Random Two >>", c)

    console.log("departmentid >>>> ", this.departmentid)
    this.empInfo = [];
    console.log("selet dept reset ")
    this.resetLimit();
    // for (let i = 0; i < opts.options.length; i++) {
    //   if (opts.options[i].value === val) {
    //     const cus1 = opts.options[i].value;
    //     const k: number = this.departmentInfo.findIndex(c1 => c1.name.split(' ').join('') === cus1.split(' ').join(''));

    //     this.getDepartmentWiseEmp(this.departmentInfo[k].departmentid, this.curDate);
    //   }
    // }

  }

  // onScrollToTop() {
  //   console.log("scrolled!!");
  //   // this.render.listen(document.getElementById('scrolllength'), 'scrolled', () => {
  //     $('html,body').animate({
  //       scrollTop: $(`#navigatedest`).offset().top - 90
  //     },
  //       'slow');
  //   // })
  // }

  getDepartmentWiseEmp(deptId: any, searchDate: any) // for administrator departmentwise emp details
  {
    this.employeeService.getDepartmentWiseEmp(deptId, searchDate, sessionStorage.getItem('empId'), sessionStorage.getItem('priority')).subscribe(
      info => {
        this.empInfo = info.emplyoeeDailyInfo;
        this.recordCount = this.empInfo.length;

        // for (var i = 0; i < this.recordCount; i++) {
        //   this.unFilteredDepartment.push(this.recordCount[i])
        // }

        // this.filteredDepartment = this.unFilteredDepartment.filter(
        //   (obj, index) =>
        //     this.unFilteredDepartment.findIndex((item) => item.departmentname === obj.departmentname) === index
        // );

        // if (this.recordCount == 0)
        //   (<HTMLInputElement>document.getElementById('butSave')).disabled = true;
        // else
        //   (<HTMLInputElement>document.getElementById('butSave')).disabled = false;

        this.fillDate();
        console.log("reset here inside dept ")
        this.resetLimit()
      },
      error => {
        console.log(error);
      });

  }

  selectDate() {

    this.fillDate();
    this.empInfo = [];
    this.resetLimit();

    let val = (<HTMLInputElement>document.getElementById('searchDate')).value;
    this.curDate = val;
    if (this.logRole == 'Administrator' && this.logEmpid == 0) //admin logged in without emp id
    {
      this.curDate = (<HTMLInputElement>document.getElementById('searchDate')).value;
      // this.getSelectedBranch();
    }
    else {
      this.empInfo = [];
      // this.resetLimit();
      this.getEmpDailyDetails(this.activeExitFilters);
    }
    console.log("change in selet date reset  ")

    console.log('Selected Date : ', this.curDate);

    const result = this.getMonthStartAndEnd(this.curDate);

    console.log('Start of Month:', result.startOfMonth);  // 01-09-2024
    console.log('End of Month:', result.endOfMonth);      // 30-09-2024

    this.startOfMonth = result.startOfMonth;
    this.endOfMonth = result.endOfMonth;
    this.resetLimit()
  }

  startOfMonth: any = null;
  endOfMonth: any = null;

  // getMonthStartAndEnd(dateString) {
  //   // Split the input date string to get day, month, year
  //   const [day, month, year] = dateString.split('-').map(Number);

  // // Create a new Date object using the input date
  // const date = new Date(year, month - 1, day);  // month is 0-based, so subtract 1

  // // Get the first day of the month
  // const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  // // Get the last day of the month
  // const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  // // Format the dates as dd-mm-yyyy
  // const formatDate = (date) => {
  //   let day = date.getDate().toString().padStart(2, '0');
  //   let month = (date.getMonth() + 1).toString().padStart(2, '0');  // month is 0-based, so add 1
  //   let year = date.getFullYear();
  //   return `${day}-${month}-${year}`;
  // };

  //  
  getMonthStartAndEnd(dateString: string): { startOfMonth: string; endOfMonth: string } {
    // Split the input date string to get year, month, day
    const [year, month, day] = dateString.split('-').map(Number);  // Adjusted for yyyy-mm-dd format

    // Create a new Date object using the input date
    const date = new Date(year, month - 1, day);  // month is 0-based, so subtract 1

    // Get the first day of the month
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    // Get the last day of the month
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    // Format the dates as dd-mm-yyyy
    const formatDate = (date) => {
      let day = date.getDate().toString().padStart(2, '0');
      let month = (date.getMonth() + 1).toString().padStart(2, '0');  // month is 0-based, so add 1
      let year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    return {
      startOfMonth: formatDate(startOfMonth),
      endOfMonth: formatDate(endOfMonth)
    };
  }

  fillDate() {
    var count = Object.keys(this.empInfo).length;
    //console.log("count"+count);
    for (let i = 0; i < count; i++) {
      // formatDate(new Date(), 'yyyy-MM-dd', 'en');
      let date = new Date((<HTMLInputElement>document.getElementById('searchDate')).value);
      this.empInfo[i].filldate = formatDate(date, 'yyyy-MM-dd', 'en');

      //below mentioned swipe info is common for all emp so saved here
      // this.empInfo[i].employeeSwipeInfo = new EmployeeSwipeInfo();

      // this.empInfo[i].employeeSwipeInfo.userid = "SWIPE_BROWSER";
      // this.empInfo[i].employeeSwipeInfo.shifted = 0;
      this.empInfo[i].latitude = this.lat;
      this.empInfo[i].longitude = this.lng;
    }
  }

  //get emp details based on selected emp id
  getSelectedBranch(event) {
    // (<HTMLInputElement>document.getElementById('deptInput')).value = "";

    // let val = (<HTMLInputElement>document.getElementById('locationInput')).value;
    // let opts = (document.getElementById('locationId')) as HTMLSelectElement;

    console.log("Random one >>", event)
    // console.log("Random Two >>", c)

    console.log("branchid  >>>> ", this.branchid)
    console.log("branchName  >>>> ", this.branchname)
    this.empInfo = [];
    console.log("inisde change in branch rest ")
    this.resetLimit()

    // for (let i = 0; i < opts.options.length; i++) {
    //   if (opts.options[i].value === val) {
    //     const cus1 = opts.options[i].value;
    //     const k: number = this.branchInfo.findIndex(c1 => c1.name.split(' ').join('') === cus1.split(' ').join(''));

    //     this.getBranchWiseEmp(this.branchInfo[k].id, this.curDate); // to bind product combo        
    //   }
    // }
  }

  // try this ... event to empty .... search type .
  getEmployeeType(event) {
    console.log("inside change in emloyee type reset ", event)
    this.empInfo = [];
    this.resetLimit()
  }

  // for administrator locationwise emp details
  getBranchWiseEmp(locId: any, searchDate: any) {
    this.employeeService.getBranchWiseEmp(locId, searchDate, sessionStorage.getItem('empId'), sessionStorage.getItem('priority')).subscribe(
      info => {
        this.empInfo = info.emplyoeeDailyInfo;
        this.recordCount = this.empInfo.length;

        // if (this.recordCount == 0)
        //   (<HTMLInputElement>document.getElementById('butSave')).disabled = true;
        // else
        //   (<HTMLInputElement>document.getElementById('butSave')).disabled = false;

        this.fillDate();
      },
      error => {
        console.log(error);
      });
  }

  //refresh page
  redirectTo(uri: string) {
    this.router.navigateByUrl('/temp', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  refresh() {
    console.log("Page want's to refresh")
    this.checkAccesForUser();
  }

  resetLimit() {
    console.log("reset limit ")
    this.offset = 0;
    this.limit = 10;
  }

  // disableBaseOnPermission(): boolean {
  //   const dataelement = (<HTMLInputElement>document.getElementById('searchDate'));
  //   const currentDate = new Date();
  //   const formattedDate = this.datepipe.transform(currentDate, "yyyy-MM-dd")
  //   dataelement.value = formattedDate;
  //   // console.log(formattedDate)
  //   // console.log("date element ",dataelement.value);
  //   if (this.logRole === "Supervisor") {
  //     return this.disableBasedOnPermissionOne = true
  //   } else if (this.logRole === "Manager") {
  //     return this.disableBasedOnPermissionOne = false;
  //   }
  //   return this.disableBasedOnPermissionOne = false;
  // }

  checkThebox(object: EmployeeDaily, index, event, session) {
    console.log(event, object, event)

    if (session === "morning") {
      this.empInfo[index].morning = event.target.checked
    } else if (session === "afternoon") {
      this.empInfo[index].afternoon = event.target.checked
    } else {
      this.empInfo[index].night = event.target.checked
    }

    if (this.empInfo[index].morning === "True" || this.empInfo[index].afternoon === "True" ||
      this.empInfo[index].night === "True") {
      this.empInfo[index].enablepenalty = true;
    } else if (this.empInfo[index].morning || this.empInfo[index].afternoon ||
      this.empInfo[index].night) {
      this.empInfo[index].enablepenalty = true;
    } else {
      this.empInfo[index].enablepenalty = false;
    }


    // this.enablePlusButton = this.empInfo.some(
    //     (item) =>{
    //     console.log(item)

    //      return item.morning === "true" ||
    //       item.afternoon === "true" ||
    //       item.night === "true"}
    //   );

  }

  onScroll() {
    this.offset = this.offset + 10;
    console.log("on scroll here : ", this.limit, " offset : ", this.offset)
    this.getEmpDailyDetails(this.activeExitFilters);
  }

  closemodal(e) {
    //this.savenoticeinfo = new Noticeinfo();
    $("#plusModal1").modal("toggle");
  }

  closemodal1(e) {
    //this.savenoticeinfo = new Noticeinfo();
    $("#plusModal2").modal("toggle");
  }

  enablePenaltyBtnforAllEmployee(empobj: EmployeeDaily[]) {
    this.empInfo.forEach(obj => {
      console.log(obj, "obj emp", obj.enablepenalty);
      console.log(obj.night, obj.morning, obj.afternoon)
      if (obj.morning === "True") {
        obj.morning = true
      } else if (obj.morning === "False") {
        obj.morning = false
      }
      if (obj.night === "True") {
        obj.night = true
      } else if (obj.night === "False") {
        obj.night = false
      }
      if (obj.afternoon === "True") {
        obj.afternoon = true
      } else if (obj.afternoon === "False") {
        obj.afternoon = false
      }
      if (obj.morning || obj.afternoon ||
        obj.night) {
        obj.enablepenalty = true;
      } else {
        obj.enablepenalty = false;
      }
    })
  }

  saveData(form: NgForm) {
    let obj = form.value;
    console.log("save ")
    if (obj.penalty && !obj.remarks) {
      // Show an error message or handle the validation as needed
      console.log('Remarks cannot be empty when penalty is checked.');
    } else {
      // Proceed with saving data
      console.log('Data saved successfully.');
    }

    $("#plusModal1").modal("toggle");

  }

  penaltyPopup(empObj: EmployeeDaily) {
    console.log(empObj)
    $("#plusModal1").modal("toggle");
    this.selectedEmpObjforPenalty = empObj;
    // empObj.penalty = this.penaltyCheckbox;
    // empObj.remarks = this.remarks; 
  }

  toggleRemarksTextarea() {
    // Toggle the disabled  textarea based on the checkbox value
    this.selectedEmpObjforPenalty.remarks = ''; // Clear remarks when disabling
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.class');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    // setTimeout(()=>{
    //   alertcomp.removeChild(alertDiv);
    //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    //     this.router.navigate(['/group-shiftallocation']);
    // });
    // }, 3000);
  }

}

