import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentInfo } from '../models/departmentInfo';
import { AlertService } from '../services';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css']
})
export class DepartmentComponent implements OnInit {
  name: string;
  departmentid: number
  employe: DepartmentInfo[];
  list: DepartmentInfo[];
  limitPara: any;
  offset: any;

  constructor(private commonservice: CommonService, private router: Router, private alertservice: AlertService) { }

  ngOnInit() {

    this.limitPara = 5;
    this.offset = 0;

    (<HTMLInputElement>document.getElementById('textType')).focus()

  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/temp', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  reFresh() {
    this.redirectTo('/departmentetails');
  }



  selectDepartment(e: any) {

    //console.log("NAme ",e);
    //console.log("NAme ",e.target.value);
    this.name = e.target.value;

  }

  saveDepartment() {
    if (!this.name) {
      this.alertmessages("Please enter Department Name", "danger");
      return;
    }
    this.commonservice.getdeparment(this.name.trim()).subscribe(
      data => {
        this.employe = data.departmentsaved;

        console.log("save" + this.employe);
        if (this.employe) {
          this.alertmessages("Successfully Updated", "success");
          return;
        }

      },
      error => {
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      });

  }
  // console.log("save");



  searchDepartment() {

    // if(!this.name){
    //   this.alertmessages("Please enter name" , "danger");
    //   return;
    // }

    if (!this.name) {

      this.commonservice.getSearchdeparment1().subscribe(
        data => {
          // if (this.offset === 0) {
          this.list = data;
          // }else{
          //   this.list=this.list.concat(data);
          // }

        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            this.alertservice.error(response.errorMessages[0]['key']);
          }
        });
    }


    else {
      this.commonservice.getSearchdeparment(this.name).subscribe(
        data => {
          this.list = data;

          console.log("search" + this.list);


        },
        error => {
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            this.alertservice.error(response.errorMessages[0]['key']);
          }
        });
    }

  }

  onScroll() {
    this.offset = this.offset + this.limitPara;
    this.searchDepartment();
  }
  @HostListener('scroll', ['$event'])
  scrolled() {
    this.onScroll();
  }
  limitpara() {
    this.limitPara = 0;
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/departmentetails']);
      });


    }, 3000);
  }

}
